import clsx from "clsx";
import { ImagePlaceholder } from "components/shared/ImagePlaceholder/ImagePlaceholder";
import { getProFilterIcon } from "pages/Media/filters/proFilters.icons";
import { FC } from "react";
import { ImageItem } from "shared/types/ImageItem";
import { SvgIconComponent } from "shared/types/SvgIcon";

import styles from "./ProFilterIcon.module.scss";

interface Props {
  filterKey: string;
  className?: string;
}

const isImage = (
  icon: ReturnType<typeof getProFilterIcon>
): icon is ImageItem => {
  return Boolean((icon as ImageItem).src);
};

export const ProFilterIcon: FC<Props> = ({ filterKey, className, ...rest }) => {
  const Icon = getProFilterIcon(filterKey);
  if (isImage(Icon)) {
    return (
      <ImagePlaceholder
        src={Icon.src}
        fallbackSrc={Icon.fallbackSrc}
        className={clsx(styles.image, styles.lv1)}
      />
    );
  }
  const IconComponent = Icon as SvgIconComponent;
  return (
    <IconComponent
      className={clsx(styles.icon, styles[filterKey], className)}
      {...rest}
    />
  );
};
