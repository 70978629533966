import clsx from "clsx";
import {
  ForwardedRef,
  forwardRef,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import { DTIProps } from "shared/types/DTIProps";

import styles from "./HBImage.module.scss";

interface Props
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src">,
    Partial<DTIProps> {
  webpSrc: string;
  fallback: string;
  wrapperClassName?: string;
}

const HBImageComponent = (
  { webpSrc, fallback, wrapperClassName, alt, onError, ...other }: Props,
  ref: ForwardedRef<HTMLImageElement>
) => {
  const [{ srcSet, type }, setImageSource] = useState({
    srcSet: webpSrc,
    type: "image/webp",
  });

  useEffect(() => {
    setImageSource({
      srcSet: webpSrc,
      type: "image/webp",
    });
  }, [webpSrc]);

  const handleError = useCallback(
    (event) => {
      if (srcSet === fallback) {
        onError?.(event);
      } else {
        setImageSource({ srcSet: fallback, type: undefined });
      }
    },
    [srcSet, fallback, onError]
  );

  return (
    <picture className={clsx(styles.wrapper, wrapperClassName)}>
      <source srcSet={srcSet} type={type} />
      {/*eslint-disable-next-line react/forbid-elements*/}
      <img
        className={styles.image}
        src={srcSet}
        alt={alt}
        ref={ref}
        {...other}
        onError={handleError}
      />
    </picture>
  );
};

export const HBImage = forwardRef(HBImageComponent);
