import { useCallback, useState } from "react";

export const useBoolean = (
  initialState: boolean = false
): [boolean, VoidFunction, VoidFunction] => {
  const [bool, setBool] = useState<boolean>(initialState);

  const setTrue = useCallback(() => {
    setBool(true);
  }, []);
  const setFalse = useCallback(() => {
    setBool(false);
  }, []);

  return [bool, setTrue, setFalse];
};
