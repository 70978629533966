import { assign } from "@xstate/immer";
import {
  OrchestratorEvent,
  OrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";

import {
  GetEducatedBookmarkPayload,
  GetEducatedBookmarkRemoveAllPayload,
  GetEducatedPostProgressPayload,
} from "./GetEducated.models";

export const getEducatedActions = () => {
  return {
    removeAllBookmarks: assign(
      (
        ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GetEducatedBookmarkRemoveAllPayload>
      ) => {
        ctx.state.getEducated.bookmarks =
          ctx.state.getEducated.bookmarks.filter(
            (b) => b.type !== event.data.type
          );
      }
    ),
    addBookmark: assign(
      (
        ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GetEducatedBookmarkPayload>
      ) => {
        if (
          ctx.state.getEducated.bookmarks.find((b) => b.id === event.data.id)
        ) {
          return;
        }
        ctx.state.getEducated.bookmarks.push(event.data);
      }
    ),
    removeBookmark: assign(
      (
        ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GetEducatedBookmarkPayload>
      ) => {
        ctx.state.getEducated.bookmarks =
          ctx.state.getEducated.bookmarks.filter((b) => b.id !== event.data.id);
      }
    ),
    setPostRead: assign(
      (
        ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GetEducatedPostProgressPayload>
      ) => {
        if (!ctx.state.getEducated.progressData[event.data.projectGuideId]) {
          ctx.state.getEducated.progressData[event.data.projectGuideId] = {};
        }
        ctx.state.getEducated.progressData[event.data.projectGuideId][
          event.data.postId
        ] = true;
      }
    ),
    removePostRead: assign(
      (
        ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GetEducatedPostProgressPayload>
      ) => {
        ctx.state.getEducated.progressData[event.data.projectGuideId][
          event.data.postId
        ] = false;
      }
    ),
  };
};
