import { useMediaQuery } from "@mui/material";

import { homebaseTheme } from "./homebase_theme";
import { ScreenSize } from "./utils";

export const useScreenSize = (): ScreenSize => {
  const isXs = useMediaQuery<typeof homebaseTheme>((theme) =>
    theme.breakpoints.up("xs")
  );
  const isSm = useMediaQuery<typeof homebaseTheme>((theme) =>
    theme.breakpoints.up("sm")
  );
  const isMd = useMediaQuery<typeof homebaseTheme>((theme) =>
    theme.breakpoints.up("md")
  );
  const isLg = useMediaQuery<typeof homebaseTheme>((theme) =>
    theme.breakpoints.up("lg")
  );
  return isLg ? "lg" : isMd ? "md" : isSm ? "sm" : isXs ? "xs" : "xl";
};
