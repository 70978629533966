/**
 * @type {import("i18next-parser").UserConfig}
 */
const config = {
  keySeparator: false,
  namespaceSeparator: false,
  pluralSeparator: "=^_^=",
};

module.exports = config;
