import { QueryClient as ReactQueryClient } from "@tanstack/react-query";
import { SECONDS } from "constants/time";
import { parseRetryAfter } from "shared/util/retryAfter";

import { SET_ERROR_IF_RETRY_DELAY_IS_TOO_LONG } from "./constants";

const DEFAULT_RETRY_AFTER_MS = 59 * SECONDS;

const reactQueryDefaultRetryDelay = (failureCount: number) => {
  return Math.min(1 * SECONDS * 2 ** failureCount, 30 * SECONDS);
};

export const reactQueryDefaultRetry = (failureCount: number) => {
  return failureCount < 3;
};

const retryDelay = (failureCount: number, error: Error | Response) => {
  if (error && "status" in error && error.status === 429) {
    return parseRetryAfter(error) ?? DEFAULT_RETRY_AFTER_MS;
  } else {
    return reactQueryDefaultRetryDelay(failureCount);
  }
};

const retry = (failureCount: number, error: Error | Response) => {
  if (error && "status" in error) {
    const timeToWait = parseRetryAfter(error);
    return (
      error.status === 429 && timeToWait <= SET_ERROR_IF_RETRY_DELAY_IS_TOO_LONG
    );
  } else {
    return reactQueryDefaultRetry(failureCount);
  }
};

export const HBQueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      retryDelay,
      retry,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retryDelay,
      retry,
    },
  },
});
