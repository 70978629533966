import {
  GET_EDUCATED,
  NOT_AVAILABLE_MOBILE,
  PRIVACY,
  TERMS,
} from "router/routes";

export const FooterExternalLinks = {
  twitter: "https://twitter.com/BuiltwithNook",
  facebook: "https://www.facebook.com/profile.php?id=61556151516627",
  linkedin: "https://www.linkedin.com/company/builtwithnook/",
  terms: TERMS(),
  privacy: PRIVACY(),
  // TODO: create these pages
  features: NOT_AVAILABLE_MOBILE(),
  advertise: NOT_AVAILABLE_MOBILE(),
  about: NOT_AVAILABLE_MOBILE(),
  team: NOT_AVAILABLE_MOBILE(),
  news: NOT_AVAILABLE_MOBILE(),
  blog: GET_EDUCATED(),
};

export const FOOTER_ID = "footer-element";
