import { useMutation } from "@tanstack/react-query";
import { joinEarlyAccess } from "api/homebase.api";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { SidebarLoading } from "components/shared/SidebarForm/SidebarLoading/SidebarLoading";
import { SidebarSuccess } from "components/shared/SidebarForm/SidebarSuccess/SidebarSuccess";
import { SidebarBaseForm } from "components/SidebarForms/components/SidebarBaseForm/SidebarBaseForm";
import { useEarlyAccessForm } from "components/SidebarForms/hooks/useEarlyAccessForm";
import { useTrackEvent } from "core/telemetry";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SidebarEarlyAccess.module.scss";
import { SidebarEarlyAccessInputs } from "./SidebarEarlyAccessInputs";
import { SidebarEarlyAccessTitle } from "./SidebarEarlyAccessTitle";

interface Props {
  isOpen: boolean;
  type: "client" | "pro";
  title: string;
  emailLabel?: string;
  submitText?: string;
  info: JSX.Element;
  onClose: () => void;
}

export const SidebarEarlyAccess = (props: Props) => {
  const { isOpen, type, title, emailLabel, submitText, info, onClose } = props;
  const trackEvent = useTrackEvent();
  const { mutate, isPending, isSuccess, reset } = useMutation({
    mutationKey: ["earlyAccess"],
    mutationFn: joinEarlyAccess,
    onSuccess: () => {
      trackEvent("earlyaccess:join", { type });
    },
  });
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useEarlyAccessForm(mutate, type === "pro" ? "professional" : "homeowner");

  useEffect(() => {
    if (isOpen) {
      resetForm();
      reset();
      trackEvent("earlyaccess:open", { type });
    }
  }, [isOpen, reset, resetForm, trackEvent, type]);

  return (
    <SidebarBaseForm
      contentClasses={styles.content}
      isOpen={isOpen}
      title={
        <SidebarEarlyAccessTitle
          onClose={onClose}
          title={title}
          hideClose={isPending || isSuccess}
        />
      }
      onSubmit={handleSubmit}
      onClose={onClose}
      hideItems={isPending || isSuccess}
      hideHeader
      inputs={
        <SidebarEarlyAccessInputs
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onBlur={handleBlur}
          onSubmit={handleSubmit}
          submitText={submitText}
          emailLabel={emailLabel}
          info={info}
        />
      }
    >
      {isPending && <SidebarLoading />}
      {isSuccess && (
        <SidebarSuccess
          title={t("Application Sent")}
          description={t(
            "Your application has been successfully sent to Nook. Please be on the look out for any updates in your inbox."
          )}
          onClose={onClose}
          Icon={CheckIcon}
        />
      )}
    </SidebarBaseForm>
  );
};
