import {
  OrchestratorGuideDoneEventData,
  OrchestratorPropertyContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideMachineId } from "pages/Guides/enums";
import { processAddRoomLayoutGuideDone } from "pages/Guides/guides/RoomLayoutGuide/AddRoomLayout/machine/utils";

/**
 * Note(pavel):
 *
 * Process extra data, highly specific to guide.
 * Most guides don't need that, feel free to ask for details.
 *
 * @param oldContext Old context from Immer context. Feel free to modify.
 * @param doneData Guide done event with guide's context.
 */
export const processGuideSpecificData = (
  oldContext: OrchestratorPropertyContext,
  doneData: OrchestratorGuideDoneEventData
): OrchestratorPropertyContext => {
  const guideId = doneData.result._id;

  switch (guideId) {
    case GuideMachineId.ADD_ROOM_LAYOUT:
      return processAddRoomLayoutGuideDone(oldContext, doneData);
  }

  return oldContext;
};
