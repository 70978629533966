import { useActor } from "@xstate/react";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import { ORCHESTRATOR_ACTIONS } from "core/state/global/OrchestratorMachine/constants";
import {
  CallbackTelemetry,
  useSendCallback,
} from "core/state/hooks/useSendCallback";
import { useTrackEvent } from "core/telemetry";
import { useCallback, useEffect, useRef } from "react";

import { OrchestratorEvent } from "./OrchestratorMachine.types";

export const useOrchestratorState = (
  state: string,
  data: unknown = null
): void => {
  const { orchestrator } = useGlobalState();

  const stateRef = useRef<string>(state);
  const dataRef = useRef<unknown>(data);

  const [actorState, send] = useActor(orchestrator);
  const actorStateRef = useRef(actorState);
  const setState = useSendCallback(send, ORCHESTRATOR_ACTIONS.SET_STATE);

  useEffect(() => {
    const sameState = actorStateRef.current.matches(stateRef.current);

    if (sameState) {
      return;
    }

    // @ts-expect-error
    setState({ state: stateRef.current, data: dataRef.current });
  }, [setState]);
};

export const useOrchestratorStaticCallback = <T = undefined>(
  type: string,
  data: T,
  telemetry: CallbackTelemetry = undefined
) => {
  const { orchestrator } = useGlobalState();
  const trackEvent = useTrackEvent();
  const [, send] = useActor(orchestrator);
  const { trackEvent: eventName } = telemetry || {};

  return useCallback(() => {
    const event: OrchestratorEvent<T> = { type, data };
    send(event);
    if (eventName) {
      trackEvent(eventName);
    }
  }, [send, type, data, eventName, trackEvent]);
};

export const useOrchestratorCallback = <T = undefined>(
  type: string,
  telemetry: CallbackTelemetry = undefined
) => {
  const { trackEvent } = telemetry || {};
  const { orchestrator } = useGlobalState();
  const [, send] = useActor(orchestrator);
  return useSendCallback<T>(send, type, { trackEvent });
};

export const useOrchestratorCurriedCallback = <T extends {}, K extends {}>(
  type: string,
  data: T
) => {
  const { orchestrator } = useGlobalState();
  const [, send] = useActor(orchestrator);

  return useCallback(
    (args: K) => {
      const event: OrchestratorEvent<T> = {
        type,
        data: { ...data, ...args },
      };
      send(event);
    },
    [send, type, data]
  );
};
