import { HomeBetaInfo } from "pages/Home/components/HomeBetaInfo/HomeBetaInfo";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { SidebarEarlyAccess } from "./SidebarEarlyAccess";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SidebarEarlyAccessPro: FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <SidebarEarlyAccess
      isOpen={isOpen}
      type="pro"
      title={t(
        "Give your clients a better\nhome improvement\nexperience with Nook"
      )}
      submitText={t("Join As A Pro")}
      emailLabel={t("Business Email")}
      onClose={onClose}
      info={<HomeBetaInfo />}
    />
  );
};
