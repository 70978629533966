import { CircularProgress } from "@mui/material";
import { FC } from "react";

import styles from "./SidebarLoading.module.scss";

export const SidebarLoading: FC = () => {
  return (
    <div className={styles.overlay}>
      <CircularProgress className={styles.loading} />
    </div>
  );
};
