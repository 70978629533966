import { GetPricesResponse } from "api/models/GetPricesResponse";
import { CeilingFanControlTypes } from "constants/guides/Electrical/CeilingFanControls/enums";
import { CeilingFanTypes } from "constants/guides/Electrical/CeilingFans/enums";
import { LightingControlTypes } from "constants/guides/Electrical/LightingControls/enums";
import { LightingFixtureTypes } from "constants/guides/Electrical/LightingFixtures/enums";
import { OutletTypes } from "constants/guides/Electrical/Outlets/enums";
import { OutletSubtypes } from "constants/guides/Electrical/Outlets/OutletSubtypes/enums";
import { SmokeAlarmTypes } from "constants/guides/Electrical/SmokeAlarms/enums";
import { SmokeAlarmSubtypes } from "constants/guides/Electrical/SmokeAlarms/SmokeAlarmSubtypes/enums";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideIntentType } from "pages/Guides/enums";

const updatedAt = new Date().toUTCString();

/**
 * @see {https://docs.google.com/spreadsheets/d/1kHyLkKb_v_5NHWBPFjx4QToEqsB-ni4wPT_XiW4yQB8/edit}
 */
export const pricesData: GetPricesResponse = {
  labor: {
    categories: {
      [GuideItemTypeCategory.LIGHTING_FIXTURE]: {
        [GuideIntentType.REMOVE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.REPLACE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.MOVE]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.ADD]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.LIGHTING_CONTROL]: {
        [GuideIntentType.REMOVE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.REPLACE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.MOVE]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.ADD]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.CEILING_FAN]: {
        [GuideIntentType.REMOVE]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.REPLACE]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.MOVE]: {
          amount: 400,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.ADD]: {
          amount: 400,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.CEILING_FAN_CONTROL]: {
        [GuideIntentType.REMOVE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.REPLACE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.MOVE]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.ADD]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.OUTLET]: {
        [GuideIntentType.REMOVE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.REPLACE]: {
          amount: 80,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.MOVE]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [GuideIntentType.ADD]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
      },
    },
    types: {
      [GuideItemTypeCategory.LIGHTING_FIXTURE]: {
        [LightingFixtureTypes.HANGING_LIGHT]: {
          [GuideIntentType.MOVE]: {
            amount: 250,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.ADD]: {
            amount: 250,
            type: "per_item",
            updatedAt,
          },
        },
        [LightingFixtureTypes.MONORAIL]: {
          [GuideIntentType.MOVE]: {
            amount: 250,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.ADD]: {
            amount: 250,
            type: "per_item",
            updatedAt,
          },
        },
      },
      [GuideItemTypeCategory.CEILING_FAN]: {
        [CeilingFanTypes.STANDARD_FAN]: {
          [GuideIntentType.REPLACE]: {
            amount: 150,
            type: "per_item",
            updatedAt,
          },
        },
      },
      [GuideItemTypeCategory.CEILING_FAN_CONTROL]: {
        [CeilingFanControlTypes.PULL_CHORD]: {
          [GuideIntentType.REMOVE]: {
            amount: 0,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.REPLACE]: {
            amount: 0,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.MOVE]: {
            amount: 0,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.ADD]: {
            amount: 0,
            type: "per_item",
            updatedAt,
          },
        },
      },
      [GuideItemTypeCategory.SMOKE_ALARM]: {
        [SmokeAlarmTypes.BATTERY_OPERATED]: {
          [GuideIntentType.REMOVE]: {
            amount: 25,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.REPLACE]: {
            amount: 25,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.MOVE]: {
            amount: 40,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.ADD]: {
            amount: 25,
            type: "per_item",
            updatedAt,
          },
        },
        [SmokeAlarmTypes.HARDWIRED]: {
          [GuideIntentType.REMOVE]: {
            amount: 80,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.REPLACE]: {
            amount: 80,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.MOVE]: {
            amount: 150,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.ADD]: {
            amount: 150,
            type: "per_item",
            updatedAt,
          },
        },
      },
      [GuideItemTypeCategory.OUTLET]: {
        [OutletTypes.TWO_FORTY_V]: {
          [GuideIntentType.REMOVE]: {
            amount: 150,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.REPLACE]: {
            amount: 150,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.MOVE]: {
            amount: 400,
            type: "per_item",
            updatedAt,
          },
          [GuideIntentType.ADD]: {
            amount: 400,
            type: "per_item",
            updatedAt,
          },
        },
      },
    },
  },
  items: {
    categories: {
      [GuideItemTypeCategory.CEILING_FAN]: {
        amount: 250,
        type: "per_item",
        updatedAt,
      },
    },
    types: {
      [GuideItemTypeCategory.LIGHTING_FIXTURE]: {
        [LightingFixtureTypes.RECESSED_LIGHT]: {
          amount: 65,
          type: "per_item",
          updatedAt,
        },
        [LightingFixtureTypes.HANGING_LIGHT]: {
          amount: 320,
          type: "per_item",
          updatedAt,
        },
        [LightingFixtureTypes.WALL_SCONCES]: {
          amount: 150,
          type: "per_item",
          updatedAt,
        },
        [LightingFixtureTypes.FLUSHMOUNT]: {
          amount: 120,
          type: "per_item",
          updatedAt,
        },
        [LightingFixtureTypes.LED]: {
          amount: 30,
          type: "per_item",
          updatedAt,
        },
        [LightingFixtureTypes.MONORAIL]: {
          amount: 50,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.LIGHTING_CONTROL]: {
        [LightingControlTypes.DIMMER]: {
          amount: 20,
          type: "per_item",
          updatedAt,
        },
        [LightingControlTypes.SWITCH]: {
          amount: 20,
          type: "per_item",
          updatedAt,
        },
        [LightingControlTypes.CONTROL_PANEL]: {
          amount: 100,
          type: "per_item",
          updatedAt,
        },
        [LightingControlTypes.SENSOR]: {
          amount: 30,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.CEILING_FAN_CONTROL]: {
        [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: {
          amount: 20,
          type: "per_item",
          updatedAt,
        },
        [CeilingFanControlTypes.SWITCH]: {
          amount: 20,
          type: "per_item",
          updatedAt,
        },
        [CeilingFanControlTypes.CONTROL_PANEL]: {
          amount: 100,
          type: "per_item",
          updatedAt,
        },
        [CeilingFanControlTypes.PULL_CHORD]: {
          amount: 0,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.SMOKE_ALARM]: {
        [SmokeAlarmTypes.BATTERY_OPERATED]: {
          amount: 20,
          type: "per_item",
          updatedAt,
        },
        [SmokeAlarmTypes.HARDWIRED]: {
          amount: 30,
          type: "per_item",
          updatedAt,
        },
      },
      [GuideItemTypeCategory.OUTLET]: {
        [OutletTypes.SINGLE]: {
          amount: 4,
          type: "per_item",
          updatedAt,
        },
        [OutletTypes.DOUBLE]: {
          amount: 6,
          type: "per_item",
          updatedAt,
        },
        [OutletTypes.TWO_FORTY_V]: {
          amount: 8 + 20, // NOTE(clemens): box + slot cost combined as we model TWO_FORTY_V to not have any subtypes
          type: "per_item",
          updatedAt,
        },
        [OutletTypes.WEATHER_RESISTANT]: {
          amount: 12,
          type: "per_item",
          updatedAt,
        },
      },
    },
    subtypes: {
      [GuideItemTypeCategory.SMOKE_ALARM]: {
        [SmokeAlarmSubtypes.CARBON_MONOXIDE]: {
          _default: {
            amount: 8,
            type: "per_item",
            updatedAt,
          },
        },
        [SmokeAlarmSubtypes.INTERCONNECTED]: {
          _default: {
            amount: 8,
            type: "per_item",
            updatedAt,
          },
        },
        [SmokeAlarmSubtypes.SAFETY_PATH_LIGHT]: {
          _default: {
            amount: 8,
            type: "per_item",
            updatedAt,
          },
        },
        [SmokeAlarmSubtypes.SMART]: {
          [SmokeAlarmTypes.BATTERY_OPERATED]: {
            amount: 25,
            type: "per_item",
            updatedAt,
          },
          [SmokeAlarmTypes.HARDWIRED]: {
            amount: 70,
            type: "per_item",
            updatedAt,
          },
        },
        [SmokeAlarmSubtypes.NO_OTHER_FEATURES]: {
          _default: {
            amount: 0,
            type: "per_item",
            updatedAt,
          },
        },
      },
      [GuideItemTypeCategory.OUTLET]: {
        [OutletSubtypes.USB]: {
          _default: {
            amount: 35,
            type: "per_item",
            updatedAt,
          },
        },
        [OutletSubtypes.STANDARD]: {
          _default: {
            amount: 10,
            type: "per_item",
            updatedAt,
          },
        },
        [OutletSubtypes.TAMPER_RESISTANT]: {
          _default: {
            amount: 10,
            type: "per_item",
            updatedAt,
          },
        },
        [OutletSubtypes.SWITCHED]: {
          _default: {
            amount: 10,
            type: "per_item",
            updatedAt,
          },
        },
        [OutletSubtypes.GFCI]: {
          _default: {
            amount: 20,
            type: "per_item",
            updatedAt,
          },
        },
        [OutletSubtypes.SMART]: {
          _default: {
            amount: 40,
            type: "per_item",
            updatedAt,
          },
        },
      },
    },
  },
};
