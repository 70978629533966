import { Typography } from "@mui/material";
import clsx from "clsx";
import { FooterExternalLinks } from "components/Footer/consts";
import { FC } from "react";
import { Trans } from "react-i18next";

import styles from "./BlogWaitlistInfo.module.scss";
interface Props {
  className?: string;
  textClassName?: string;
  addBreakSpace?: boolean;
}
export const BlogWaitlistInfo: FC<Props> = ({
  className,
  addBreakSpace,
  textClassName,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <Trans
        defaults="<text>By subscribing, you consent to receive emails <newline/>  from Nook and agree to our</text><tlink>Privacy Policy.</tlink>"
        components={{
          text: (
            <Typography
              variant="p"
              component="p"
              className={clsx(styles.subscribe, textClassName)}
            />
          ),
          newline: (
            <br className={addBreakSpace ? styles.breakSpace : undefined} />
          ),
          tlink: (
            <Typography
              component="a"
              variant="p"
              href={FooterExternalLinks.privacy}
              rel="noreferrer noopener nofollow"
              target="_blank"
              className={clsx(
                styles.subscribe,
                styles.subscribe__link,
                textClassName
              )}
            />
          ),
        }}
      />
    </div>
  );
};
