import { PresetGroup } from "api/models/PresetGroup";
import { GuideType } from "constants/guides/GuideType";
import { PresetGroupId } from "constants/presets/PresetGroup.types";
import { PresetGroupItemId } from "constants/presets/PresetGroupItem.types";
import { ROOMS_PIECES } from "constants/rooms/roomPieces";
import { SubCategory } from "constants/subcategories/subcategory.types";
import { GuideIntentType } from "pages/Guides/enums";
import { GuideIntents } from "pages/Guides/types";

const ADD_INTENT: GuideIntents = [GuideIntentType.ADD];

export const presetCategoryData: PresetGroup[] = [
  {
    id: PresetGroupId.KITCHEN_IMPROVEMENTS,
    title: (t) => t("Kitchen Improvements"),
    description: (t) =>
      t(
        "Full layout changes, appliance & fixture upgrades or simple facelifts."
      ),
    items: [
      {
        id: PresetGroupItemId.COMPLETE_KITCHEN,
        title: (t) => t("Complete Kitchen: New Layout"),
        description: (t) =>
          t(
            "Take your kitchen down to the bones, change the layout completely and start again from scratch."
          ),
        allowedRooms: [ROOMS_PIECES.KITCHEN, ROOMS_PIECES.OUTDOOR_KITCHEN],
        tasks: [
          {
            title: (t) => t("Demolition"),
            description: (t) =>
              t(
                "To start a kitchen renovation, the existing cabinets, countertops, and appliances must be carefully removed, making way for the necessary repairs and structural changes needed to create a new and functional kitchen space. Consider donating usable appliances or cabinets to less fortunate families or organizations in need. It's a small but impactful way to give back to your community and contribute to a more environmentally responsible approach to renovation."
              ),
            category: GuideType.DEMOLITION,
            subCategory: SubCategory.DEMOLITION,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Kitchen Appliances"),
            description: (t) =>
              t(
                "Upgrading or adding kitchen appliances is a crucial step in a renovation. Choose appliances that fit the design and dimensions of the kitchen, with energy efficiency and ease of use in mind. Upgrading appliances improves functionality and overall appeal, creating a more efficient and enjoyable space and can also increase the value of your home and provide long-term cost savings on energy bills."
              ),
            category: GuideType.KITCHEN_SPECIFIC,
            subCategory: SubCategory.KITCHEN_APPLIANCE,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Kitchen Cabinets"),
            description: (t) =>
              t(
                "Cabinets are not only functional but also play a crucial role in the overall design of the kitchen. Updating or adding cabinets and doors can transform the look and feel of the kitchen, making it a more organized and enjoyable space to work in. With the right selection and installation of cabinets and doors, a kitchen can become a centerpiece of the home."
              ),
            category: GuideType.KITCHEN_SPECIFIC,
            subCategory: SubCategory.KITCHEN_CABINET,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Kitchen Cabinet Doors"),
            description: (t) =>
              t(
                "Cabinet doors are an important design element of a kitchen, as they can significantly impact the overall look and style of the space. Updating or adding new cabinet doors can be a great way to refresh and modernize the appearance of the kitchen, creating a more visually appealing and stylish environment. With the right selection and installation of doors, a kitchen can become a beautiful and functional space that reflects your personal style and taste."
              ),
            category: GuideType.KITCHEN_SPECIFIC,
            subCategory: SubCategory.KITCHEN_CABINET_DOOR,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Kitchen Fixtures"),
            description: (t) =>
              t(
                "Upgrading or adding faucets and sinks can enhance both the aesthetic and functional aspects of a kitchen renovation. Select durable and easy-to-use fixtures that fit the kitchen's style, or opt for custom fixtures for specific needs. The right selection and installation of faucets and sinks can improve efficiency and enjoyment while providing a refreshed look."
              ),
            category: GuideType.KITCHEN_SPECIFIC,
            subCategory: SubCategory.KITCHEN_FIXTURE,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Kitchen Countertops"),
            description: (t) =>
              t(
                "Countertops hold a significant role in a kitchen renovation, offering both practical and visual enhancements to the area. Upgrading or installing new countertops can dramatically alter the kitchen's overall look, making it more pleasurable to utilize while also increasing the home's value."
              ),
            category: GuideType.KITCHEN_SPECIFIC,
            subCategory: SubCategory.KITCHEN_COUNTERTOP_AND_BACKSPLASH,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Kitchen Backsplash"),
            description: (t) =>
              t(
                "A backsplash protects the walls behind countertops and appliances from water, grease, and other kitchen-related splatters. This helps maintain a clean and hygienic kitchen, while also making it easier to clean up spills and stains. Aesthetically, a well-chosen backsplash can act as a design focal point, enhancing the overall look of the kitchen, creating a cohesive and visually appealing space."
              ),
            category: GuideType.KITCHEN_SPECIFIC,
            subCategory: SubCategory.KITCHEN_COUNTERTOP_AND_BACKSPLASH,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Plumbing Connections"),
            description: (t) =>
              t(
                "Sewer lines are crucial components of a functional kitchen, allowing for the installation of sinks, dishwashers, and other water-dependent appliances. Upgrading your sewer lines can improve the functionality of your kitchen and prevent future plumbing issues. If your space does not currently have these lines in place it will be necessary to install sewer lines to accommodate your new kitchen."
              ),
            category: GuideType.PLUMBING,
            subCategory: SubCategory.WIRING, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Lighting Fixtures"),
            description: (t) =>
              t(
                "Lighting fixtures are an excellent way to add style and ambiance to any space. To create a visually appealing environment, it's important to choose fixtures that match the room's style and fit its scale. Regardless of whether you desire a cozy and welcoming environment or a chic and contemporary vibe, the appropriate lighting can significantly enhance your kitchen experience and contribute to a safer workspace."
              ),
            category: GuideType.ELECTRICAL,
            subCategory: SubCategory.LIGHTING_FIXTURE,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Lighting Controls"),
            description: (t) =>
              t(
                "When designing a space with lighting fixtures, it's important to carefully consider the placement and type of controls. Conveniently located lighting controls make it easy to adjust the lighting levels and create the desired ambiance. Controls should be easily accessible from all areas of the room and intuitive to use. Whether you're looking to create a relaxing atmosphere or brighten up the room for a specific task, the right lighting controls can make all the difference in achieving the desired effect."
              ),
            category: GuideType.ELECTRICAL,
            subCategory: SubCategory.LIGHTING_CONTROL,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Outlets"),
            description: (t) =>
              t(
                "When designing a kitchen, it's crucial to consider the placement and type of outlets. Sufficient outlets in the right locations are vital for functionality and convenience, whether it's for charging devices or other electrical needs. By thoughtfully considering the number and placement of outlets, you can create a space that is both functional and visually appealing, with no unsightly cords or electrical clutter.  Careful planning of these elements create a kitchen that is both safe and convenient to use, while also being highly functional and efficient."
              ),
            category: GuideType.ELECTRICAL,
            subCategory: SubCategory.OUTLET,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Electrical Connections"),
            description: (t) =>
              t(
                "Proper lighting, outlets, and controls are key considerations in a kitchen renovation. Good lighting enhances safety and functionality and can add a little drama to your culinary creations. Conveniently placed outlets in the proper location are important for powering appliances and charging devices, while well-placed controls make usage easy and intuitive. Careful planning of these elements create a kitchen that is both safe and convenient to use, while also being highly functional and efficient."
              ),
            category: GuideType.ELECTRICAL,
            subCategory: SubCategory.WIRING, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Heating & Cooling Vents"),
            description: (t) =>
              t(
                "When planning a kitchen renovation, it's important to evaluate your current HVAC system and determine if additional vents or ductwork will be needed to accommodate your new kitchen layout. The right placement and type of vent can not only ensure optimal temperature control but also contribute to the overall design and aesthetic of the space. With a range of options available, from discreet floor vents to stylish wall-mounted registers, you can choose the perfect vent that complements your new kitchen design and provides the comfort you need."
              ),
            category: GuideType.VENTILATION,
            subCategory: SubCategory.WIRING, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Wall Material"),
            description: (t) =>
              t(
                "Replacing your wall material during a kitchen renovation can be necessary if it's damaged or outdated. Additionally, if the material does not match your personal style preferences, updating it can give your kitchen a fresh and updated look. When choosing a new material, consider factors such as durability, cost, and ease of cleaning, as well as your personal style preferences and practical needs."
              ),
            category: GuideType.WALL_AND_CEILING_MATERIAL,
            subCategory: SubCategory.INTERIOR_WALL_MATERIAL, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Ceiling Material"),
            description: (t) =>
              t(
                "While not critical, updating your ceiling material during a kitchen renovation may be essential if it's damaged or outdated, or if it doesn't align with your personal style preferences. Revamping the ceiling can lend your kitchen a rejuvenated and contemporary appearance."
              ),
            category: GuideType.WALL_AND_CEILING_MATERIAL,
            subCategory: SubCategory.INTERIOR_CEILING_MATERIAL, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Floor Material"),
            description: (t) =>
              t(
                "When changing the layout of your kitchen during a renovation, it may be necessary to replace your existing flooring to accommodate the new design. Updating your kitchen flooring can breathe new life into your space and transform the overall look and feel of your kitchen. By selecting a new flooring material that matches your personal style preferences and practical needs, you can create a fresh and modern atmosphere in your kitchen."
              ),
            category: GuideType.FLOOR_MATERIAL,
            subCategory: SubCategory.INTERIOR_FLOOR_MATERIAL, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Baseboards"),
            description: (t) => t("No text"),
            category: GuideType.FLOOR_MATERIAL,
            intents: ADD_INTENT,
            subCategory: SubCategory.BASEBOARD,
          },
          {
            title: (t) => t("Door Trim"),
            description: (t) => t("No text"),
            category: GuideType.WINDOWS_AND_DOORS,
            intents: ADD_INTENT,
            subCategory: SubCategory.DOOR_TRIM,
          },
          {
            title: (t) => t("Window Trim"),
            description: (t) => t("No text"),
            category: GuideType.WINDOWS_AND_DOORS,
            subCategory: SubCategory.WINDOW_TRIM,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Crown Moulding"),
            description: (t) => t("No text"),
            category: GuideType.WINDOWS_AND_DOORS,
            subCategory: SubCategory.CROWN_MOULDING,
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Wall Paint"),
            description: (t) => t("No text"),
            category: GuideType.PAINT_AND_FINISHES,
            subCategory: SubCategory.PAINT, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Ceiling Paint"),
            description: (t) => t("No text"),
            category: GuideType.PAINT_AND_FINISHES,
            subCategory: SubCategory.PAINT, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Paint Baseboard"),
            description: (t) => t("No text"),
            category: GuideType.PAINT_AND_FINISHES,
            subCategory: SubCategory.PAINT, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Paint Door Trim"),
            description: (t) => t("No text"),
            category: GuideType.PAINT_AND_FINISHES,
            subCategory: SubCategory.PAINT, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Paint Window Trim"),
            description: (t) => t("No text"),
            category: GuideType.PAINT_AND_FINISHES,
            subCategory: SubCategory.PAINT, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
          {
            title: (t) => t("Paint Crown Moulding"),
            description: (t) => t("No text"),
            category: GuideType.PAINT_AND_FINISHES,
            subCategory: SubCategory.PAINT, // FIXME(clemens): placeholder
            intents: ADD_INTENT,
          },
        ],
      },
      {
        id: PresetGroupItemId.KITCHEN_UPGRADE,
        title: (t) => t("Kitchen Upgrade"),
        description: (t) =>
          t(
            "All new cabinets, countertops, major appliances and fixtures within your current kitchen layout."
          ),
        allowedRooms: [ROOMS_PIECES.KITCHEN],
        tasks: [],
      },
      {
        id: PresetGroupItemId.KITCHEN_FACELIFT,
        title: (t) => t("Kitchen Facelift"),
        description: (t) =>
          t(
            "All of the cosmetic details that will bring your kitchen back to life without affecting major systems."
          ),
        allowedRooms: [ROOMS_PIECES.KITCHEN],
        tasks: [],
      },
      {
        id: PresetGroupItemId.APPLIANCES_FIXTURES,
        title: (t) => t("Appliances & Fixtures"),
        description: (t) =>
          t("All new appliances and fixtures  for your kitchen. "),
        allowedRooms: [ROOMS_PIECES.KITCHEN],
        tasks: [],
      },
    ],
  },
];
