/**
 * Returns annotated react style object.
 *
 * @param style
 */
export const getCSSVarsStyle = (style = {}) => {
  /**
   * @type unknown as import('react').CSSProperties
   */
  return style;
};

export const rem = (pxValue) => {
  return `${Number(pxValue.replace(/[^\d.-]/g, "")) / 16}rem`;
};

export const getCSSVarValue = (key, node = null) => {
  const element = node || document.getElementById("root");

  if (!element) {
    return "";
  }

  const computedStyle = window.getComputedStyle(element);

  if (!computedStyle) {
    return "";
  }

  return computedStyle.getPropertyValue(key);
};

export const getCSSFloatValue = (key, node = null) => {
  const value = getCSSVarValue(key, node) || "0";

  return parseFloat(value) || 0;
};

export const getCSSVarNumberValue = (key, node = null) => {
  return parseInt(getCSSVarValue(key, node).replace(/\D+/g, "")) || 0;
};
