import {
  SystemSnackbar,
  SystemSnackbarProps,
} from "components/SystemSnackbars/components/SystemSnackbar/SystemSnackbar";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {} & SystemSnackbarProps;

export const TooManyRequestsSnackbar: FC<Props> = ({ id, onClose }) => {
  const { t } = useTranslation();
  return (
    <SystemSnackbar
      id={id}
      onClose={onClose}
      title={t(
        "We're detecting an unexpected amount of activity. Please try again later."
      )}
    />
  );
};
