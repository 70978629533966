import { TFunction } from "react-i18next";

import {
  ROOMS_PIECES_EXTERIOR,
  ROOMS_PIECES_LIVING,
  ROOMS_PIECES_TRANSITIONAL,
  ROOMS_PIECES_UTILITY,
  ROOMS_PIECES_WORK,
  RoomType,
} from "./roomPieces";

export enum RoomsCategories {
  LIVING = "living",
  WORK = "work",
  UTILITY = "utility",
  TRANSITIONAL = "transitional",
  EXTERIOR = "exterior",
}

export const ROOMS_CATEGORIES_TYPES_NAMES = (
  t: TFunction
): Record<RoomsCategories, string> => ({
  [RoomsCategories.LIVING]: t("Living"),
  [RoomsCategories.WORK]: t("Work & Entertainment"),
  [RoomsCategories.UTILITY]: t("Utility & Storage"),
  [RoomsCategories.TRANSITIONAL]: t("Transitional"),
  [RoomsCategories.EXTERIOR]: t("Exterior"),
});

export const ROOMS_CATEGORIES_LIST: Array<RoomsCategories> =
  Object.values(RoomsCategories);

export const ROOMS_CATEGORIES: Record<RoomsCategories, RoomType[]> = {
  [RoomsCategories.LIVING]: Object.values(ROOMS_PIECES_LIVING),
  [RoomsCategories.WORK]: Object.values(ROOMS_PIECES_WORK),
  [RoomsCategories.UTILITY]: Object.values(ROOMS_PIECES_UTILITY),
  [RoomsCategories.TRANSITIONAL]: Object.values(ROOMS_PIECES_TRANSITIONAL),
  [RoomsCategories.EXTERIOR]: Object.values(ROOMS_PIECES_EXTERIOR),
};

export const ROOMS_EXTERIOR_CATEGORIES: RoomsCategories[] = [
  RoomsCategories.EXTERIOR,
];
export const ROOMS_INTERIOR_CATEGORIES: RoomsCategories[] = [
  RoomsCategories.LIVING,
  RoomsCategories.WORK,
  RoomsCategories.UTILITY,
  RoomsCategories.TRANSITIONAL,
];
