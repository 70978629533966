import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipClasses,
  TooltipProps,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import clsx from "clsx";
import { useModalState } from "common/hooks/useModalState";
import { isTouchDevice } from "common/utils/isTouchDevice";
import { HBModalTooltip } from "components/HBModalTooltip/HBModalTooltip";
import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import styles from "./HBIconButton.module.scss";

export type HBIconButtonTooltipProps = Omit<
  TooltipProps,
  "title" | "children" | "placement" | "open" | "onOpen" | "onClose"
>;

type AnchorElementProps = Pick<
  HTMLProps<HTMLAnchorElement>,
  "target" | "href" | "rel"
>;

type Props = {
  title: ReactNode;
  children: ReactNode;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  isModalTooltip?: boolean;
  hideTooltip?: boolean;
  updateTitleTooltipOnClose?: boolean;
  placement?: TooltipProps["placement"];
  tooltipProps?: HBIconButtonTooltipProps;
  wrapperClassName?: string;
  variant?: "default" | "outlined";
} & Omit<IconButtonProps, "title"> &
  AnchorElementProps;

const tooltipClasses: Partial<TooltipClasses> = { tooltip: styles.tooltip };

const HBIconButtonComponent = (
  props: Props,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    placement,
    title,
    tooltipProps,
    open,
    onOpen,
    hideTooltip,
    onClose,
    updateTitleTooltipOnClose,
    variant = "default",
    isModalTooltip = false,
    wrapperClassName,
    children,
    ...iconButtonProps
  } = props;
  const [
    isTooltipShow,
    { handleModalOpen: onShowTooltip, handleModalClose: onHideTooltip },
  ] = useModalState(open);

  const handleUpdateTitle = useCallback(() => {
    setTitle(title);
  }, [title]);

  const handleShowTooltip = useCallback(() => {
    onShowTooltip();
    onOpen?.();
  }, [onShowTooltip, onOpen]);
  const handleCloseTooltip = useCallback(() => {
    onHideTooltip();
    onClose?.();
  }, [onHideTooltip, onClose]);

  const [finalTooltipText, setTitle] = useState(title);

  useEffect(() => {
    if (!updateTitleTooltipOnClose) {
      handleUpdateTitle();
    }
  }, [handleUpdateTitle, updateTitleTooltipOnClose]);

  const TransitionProps = useMemo<TransitionProps>(
    () => ({
      onExited: updateTitleTooltipOnClose ? handleUpdateTitle : undefined,
    }),
    [handleUpdateTitle, updateTitleTooltipOnClose]
  );

  const buttonComponent = (
    <span className={clsx(styles.wrapper, wrapperClassName)}>
      <IconButton
        {...iconButtonProps}
        className={clsx(styles[variant], iconButtonProps.className)}
        ref={ref}
        aria-label={
          typeof title !== "string" ? iconButtonProps["aria-label"] : title
        }
      >
        {children}
      </IconButton>
    </span>
  );

  if (isTouchDevice() || hideTooltip) {
    return buttonComponent;
  }

  if (isModalTooltip) {
    return (
      <HBModalTooltip
        {...tooltipProps}
        title={finalTooltipText}
        placement={placement}
        open={open && isTooltipShow}
        onOpen={handleShowTooltip}
        onClose={handleCloseTooltip}
        TransitionProps={TransitionProps}
      >
        {buttonComponent}
      </HBModalTooltip>
    );
  }
  return (
    <Tooltip
      {...tooltipProps}
      title={finalTooltipText}
      placement={placement}
      arrow
      open={open && isTooltipShow}
      TransitionProps={TransitionProps}
      onOpen={handleShowTooltip}
      onClose={handleCloseTooltip}
      classes={tooltipClasses}
      disableInteractive
      disableHoverListener={iconButtonProps.disabled}
      disableTouchListener={iconButtonProps.disabled}
    >
      {buttonComponent}
    </Tooltip>
  );
};

export const HBIconButton = forwardRef(HBIconButtonComponent);
