/**
 * eg.: items = ["bathroom 9", "bathroom 3", "bathroom 10"]
 *
 * With a.localCompare(b) items would be sorted like: ["bathroom 10", "bathroom 3", "bathroom 9"]
 *
 * With this natural sorting function, numbers are also taken into account in a string,
 * so items would be sorted like: ["bathroom 3", "bathroom 9", "bathroom 10"]
 **/
export const naturalSort = (a: string, b: string) => {
  const re = /(\d+)$/;
  const aMatch = a.match(re);
  const bMatch = b.match(re);

  if (aMatch && bMatch) {
    const aText = a.slice(0, aMatch.index);
    const bText = b.slice(0, bMatch.index);
    if (aText !== bText) {
      return aText.localeCompare(bText);
    }

    const aNum = parseInt(aMatch[0], 10);
    const bNum = parseInt(bMatch[0], 10);
    return aNum - bNum;
  }

  return a.localeCompare(b);
};
