import { TFunction } from "react-i18next";
import * as yup from "yup";

export const getWaitlistValidationSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .max(256, t("Please enter at most 256 characters"))
      .required(t("Your email is required"))
      .email(t("Please enter a valid email address")),
  });
