import { transform } from "api/utils";

import { AppDataV15 } from "./models/AppDataV15";
import { AppDataV16 } from "./models/AppDataV16";

export function mapV15ToV16(data: AppDataV15): AppDataV16 {
  return transform(data, (result) => {
    result.state.version = 16;

    result.state.data.hasSeenMediaHelperText =
      result.state.data.hasSeenMediaHelperText ??
      window.localStorage.getItem("homebase/media__hide_helper_text") ===
        "true";

    result.state.data.hasMyAlbumsSidebarOpened =
      result.state.data.hasMyAlbumsSidebarOpened ??
      window.localStorage.getItem(
        "homebase/media__my_albums_is_sidebar_open"
      ) === "true";

    result.state.data.hasSeenGuideCTA =
      result.state.data.hasSeenGuideCTA ??
      window.localStorage.getItem("homebase/guide_cta_shown") === "true";
  });
}
