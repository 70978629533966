import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import {
  OrchestratorEvent,
  OrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { HIPsRouteParams } from "router/models";

export const HIPsDashboardMachineConditions = () => {
  const hasActiveGuide = (
    _ctx: OrchestratorMachineContext,
    event: OrchestratorEvent<HIPsRouteParams>
  ) => {
    const { activeGuides } = getActivePropertyContext(_ctx);
    const { hipsId } = event.data;

    return activeGuides.some((guide) => guide._hipId === hipsId);
  };

  return {
    hasActiveGuide,
  };
};
