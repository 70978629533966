import { getOrderedMap } from "common/utils/listUtils";
import { TFunction } from "react-i18next";

export enum BUDGET_TAGS {
  MONEY1 = "money1",
  MONEY2 = "money2",
  MONEY3 = "money3",
  MONEY4 = "money4",
}

export enum RELEVANCE_TAGS {
  NEW_RELEASE = "new_release",
  BEST_SELLER = "best_seller",
  ECO_FRIENDLY = "eco_friendly",
  BUDGET_FRIENDLY = "budget_friendly",
  LUXURY = "luxury",
  LIMITED_EDITION = "limited_edition",
}

export enum DESIGN_STYLE_TAGS {
  ADOBE = "adobe",
  BUNGALOW = "bungalow",
  CAPE_COD = "cape_cod",
  COASTAL = "coastal",
  COLONIAL = "colonial",
  CONTEMPORARY = "contemporary",
  CRAFTSMAN = "craftsman",
  ENGLISH_COTTAGE = "english_cottage",
  FARMHOUSE = "farmhouse",
  FEDERAL_COLONIAL = "federal_colonial",
  FRENCH_COUNTRY = "french_country",
  GEORGIAN = "georgian",
  GREEK_REVIVAL = "greek_revival",
  INDUSTRIAL = "industrial",
  LOG = "log",
  MEDITERRANEAN = "mediterranean",
  MID_CENTURY_MODERN = "mid_century_modern",
  MODERN = "modern",
  NORTHWEST = "northwest",
  PRAIRIE = "prairie",
  RANCH = "ranch",
  RUSTIC = "rustic",
  SCANDINAVIAN = "scandinavian",
  SPANISH = "spanish",
  SOUTHERN = "southern",
  SOUTHWESTERN = "southwestern",
  TRADITIONAL = "traditional",
  TUDOR = "tudor",
  TUSCAN = "tuscan",
  VICTORIAN = "victorian",
}

export enum KITCHEN_SPECIFIC_TAGS {
  APPLIANCES = "appliances",
  CABINETS = "cabinets",
  COUNTERTOP = "countertop",
  BACKSPLASH = "backsplash",
  SINK = "sink",
  FAUCETS = "faucets",
}

export enum BATHROOM_SPECIFIC_TAGS {
  VANITY = "vanity",
  BATHROOM_SINK = "bathroom_sink",
  SINK_FAUCET = "sink_faucet",
  BATHTUB = "bathtub",
  BATH_FAUCET_CONTROLS = "bath_faucet_controls",
  SHOWER = "shower",
  SHOWER_FAUCET_CONTROLS = "shower_faucet_controls",
  TOILET = "toilet",
}

export enum MATERIAL_TAGS {
  STONE = "stone",
  TILE = "tile",
  WOOD = "wood",
  BRICK = "brick",
  METAL = "metal",
  GLASS = "glass",
  DRYWALL = "drywall",
  CONCRETE = "concrete",
  FIBERGLASS = "fiberglass",
  PORCELAIN = "porcelain",
  CERAMIC = "ceramic",
  TERRACOTTA = "terracotta",
  FIRECLAY = "fireclay",
  VINYL = "vinyl",
  PLASTIC = "plastic",
  FABRIC = "fabric",
  CARPET = "carpet",
}

export enum SHAPE_TAGS {
  SLAB = "slab",
  RECTANGLE = "rectangle",
  SQUARE = "square",
  TRIANGLE = "triangle",
  ROUND = "round",
  DOME = "dome",
  OVAL = "oval",
  HEXAGON = "hexagon",
  DIAMOND = "diamond",
  LEAF = "leaf",
  PEBBLE = "pebble",
  MOSAIC = "mosaic",
  IRREGULAR_SHAPE = "irregular_shape",
}

export enum LOOK_TAGS {
  SOLID_COLOR = "solid_color",
  MULTI_COLOR = "multi_color",
  MIXED_COLOR = "mixed_color",
  TRANSLUCENT_COLOR = "translucent_color",
  MIXED_SHADE = "mixed_shade",
  VEINED = "veined",
  FLECKED_SPECKLED = "flecked_speckled",
  SWIRLED = "swirled",
  MOTTLED = "mottled",
  TERRAZZO = "terrazzo",
  PATTERNED = "patterned",
}

export enum COLOR_TAGS {
  WHITE = "white",
  BLACK = "black",
  BROWN = "brown",
  RED = "red",
  BLUE = "blue",
  TURQUOISE = "turquoise",
  GREY = "grey",
  BEIGE = "beige",
  CREAM = "cream",
  GREEN = "green",
  YELLOW = "yellow",
  ORANGE = "orange",
  PINK = "pink",
  PURPLE = "purple",
  WOOD_TONES = "wood_tones",
  SILVER = "silver",
  GOLD = "gold",
}

export enum FINISH_TAGS {
  MATTE = "matte",
  SATIN = "satin",
  SEMI_GLOSS = "semi_gloss",
  HIGH_GLOSS = "high_gloss",
  NATURALLY_ROUGH = "naturally_rough",
  SMOOTH = "smooth",
  TEXTURED = "textured",
  BRUSHED = "brushed",
  HAMMERED = "hammered",
  SANDBLASTED = "sandblasted",
  POLISHED = "polished",
  STAMPED = "stamped",
  STENCILED = "stenciled",
  BROOM = "broom",
  SALT = "salt",
  EXPOSED = "exposed",
  DISTRESSED = "distressed",
  WIRE_BRUSHED = "wire_brushed",
  HAND_SCRAPED = "hand_scraped",
  POPCORN = "popcorn",
  ORANGE_PEEL = "orange_peel",
  SAND_SWIRL = "sand_swirl",
  COMB = "comb",
  SLAP_BRUSH = "slap_brush",
}

export const BUDGET_TAGS_LABELS = (t: TFunction) => ({
  [BUDGET_TAGS.MONEY1]: t("$"),
  [BUDGET_TAGS.MONEY2]: t("$$"),
  [BUDGET_TAGS.MONEY3]: t("$$$"),
  [BUDGET_TAGS.MONEY4]: t("$$$$"),
});

export const BUDGET_TAGS_ORDERS = getOrderedMap(Object.values(BUDGET_TAGS));

export const RELEVANCE_TAGS_LABELS = (t: TFunction) => ({
  [RELEVANCE_TAGS.NEW_RELEASE]: t("New release"),
  [RELEVANCE_TAGS.BEST_SELLER]: t("Best seller"),
  [RELEVANCE_TAGS.ECO_FRIENDLY]: t("Eco-friendly"),
  [RELEVANCE_TAGS.BUDGET_FRIENDLY]: t("Budget-friendly"),
  [RELEVANCE_TAGS.LUXURY]: t("Luxury"),
  [RELEVANCE_TAGS.LIMITED_EDITION]: t("Limited edition"),
});

export const RELEVANCE_TAGS_ORDERS = getOrderedMap(
  Object.values(RELEVANCE_TAGS)
);

export const DESIGN_STYLES_TAGS_LABELS = (t: TFunction) => ({
  [DESIGN_STYLE_TAGS.ADOBE]: t("Adobe"),
  [DESIGN_STYLE_TAGS.BUNGALOW]: t("Bungalow"),
  [DESIGN_STYLE_TAGS.CAPE_COD]: t("Cape Cod"),
  [DESIGN_STYLE_TAGS.COASTAL]: t("Coastal"),
  [DESIGN_STYLE_TAGS.COLONIAL]: t("Colonial"),
  [DESIGN_STYLE_TAGS.CONTEMPORARY]: t("Contemporary"),
  [DESIGN_STYLE_TAGS.CRAFTSMAN]: t("Craftsman"),
  [DESIGN_STYLE_TAGS.ENGLISH_COTTAGE]: t("English Cottage"),
  [DESIGN_STYLE_TAGS.FARMHOUSE]: t("Farmhouse"),
  [DESIGN_STYLE_TAGS.FEDERAL_COLONIAL]: t("Federal Colonial"),
  [DESIGN_STYLE_TAGS.FRENCH_COUNTRY]: t("French Country"),
  [DESIGN_STYLE_TAGS.GEORGIAN]: t("Georgian"),
  [DESIGN_STYLE_TAGS.GREEK_REVIVAL]: t("Greek Revival"),
  [DESIGN_STYLE_TAGS.INDUSTRIAL]: t("Industrial"),
  [DESIGN_STYLE_TAGS.LOG]: t("Log"),
  [DESIGN_STYLE_TAGS.MEDITERRANEAN]: t("Mediterranean"),
  [DESIGN_STYLE_TAGS.MID_CENTURY_MODERN]: t("Mid Century Modern"),
  [DESIGN_STYLE_TAGS.MODERN]: t("Modern"),
  [DESIGN_STYLE_TAGS.NORTHWEST]: t("Northwest"),
  [DESIGN_STYLE_TAGS.PRAIRIE]: t("Prairie"),
  [DESIGN_STYLE_TAGS.RANCH]: t("Ranch"),
  [DESIGN_STYLE_TAGS.RUSTIC]: t("Rustic"),
  [DESIGN_STYLE_TAGS.SCANDINAVIAN]: t("Scandinavian"),
  [DESIGN_STYLE_TAGS.SPANISH]: t("Spanish"),
  [DESIGN_STYLE_TAGS.SOUTHERN]: t("Southern"),
  [DESIGN_STYLE_TAGS.SOUTHWESTERN]: t("Southwestern"),
  [DESIGN_STYLE_TAGS.TRADITIONAL]: t("Traditional"),
  [DESIGN_STYLE_TAGS.TUDOR]: t("Tudor"),
  [DESIGN_STYLE_TAGS.TUSCAN]: t("Tuscan"),
  [DESIGN_STYLE_TAGS.VICTORIAN]: t("Victorian"),
});

export const DESIGN_STYLES_TAGS_ORDERS = getOrderedMap(
  Object.values(DESIGN_STYLE_TAGS)
);

export const KITCHEN_SPECIFIC_TAGS_LABELS = (t: TFunction) => ({
  [KITCHEN_SPECIFIC_TAGS.APPLIANCES]: t("Appliances"),
  [KITCHEN_SPECIFIC_TAGS.CABINETS]: t("Cabinets"),
  [KITCHEN_SPECIFIC_TAGS.COUNTERTOP]: t("Countertop"),
  [KITCHEN_SPECIFIC_TAGS.BACKSPLASH]: t("Backsplash"),
  [KITCHEN_SPECIFIC_TAGS.SINK]: t("Sink"),
  [KITCHEN_SPECIFIC_TAGS.FAUCETS]: t("Faucets"),
});

export const KITCHEN_SPECIFIC_TAGS_ORDERS = getOrderedMap(
  Object.values(KITCHEN_SPECIFIC_TAGS)
);

export const BATHROOM_SPECIFIC_TAGS_LABELS = (t: TFunction) => ({
  [BATHROOM_SPECIFIC_TAGS.VANITY]: t("Vanity"),
  [BATHROOM_SPECIFIC_TAGS.BATHROOM_SINK]: t("Bathroom Sink"),
  [BATHROOM_SPECIFIC_TAGS.SINK_FAUCET]: t("Sink Faucet"),
  [BATHROOM_SPECIFIC_TAGS.BATHTUB]: t("Bathtub"),
  [BATHROOM_SPECIFIC_TAGS.BATH_FAUCET_CONTROLS]: t("Bath Faucet Controls"),
  [BATHROOM_SPECIFIC_TAGS.SHOWER]: t("Shower"),
  [BATHROOM_SPECIFIC_TAGS.SHOWER_FAUCET_CONTROLS]: t("Shower Faucet Controls"),
  [BATHROOM_SPECIFIC_TAGS.TOILET]: t("Toilet"),
});

export const BATHROOM_SPECIFIC_TAGS_ORDERS = getOrderedMap(
  Object.values(BATHROOM_SPECIFIC_TAGS)
);

export const MATERIAL_TAGS_LABELS = (t: TFunction) => ({
  [MATERIAL_TAGS.STONE]: t("Stone"),
  [MATERIAL_TAGS.TILE]: t("Tile"),
  [MATERIAL_TAGS.WOOD]: t("Wood"),
  [MATERIAL_TAGS.BRICK]: t("Brick"),
  [MATERIAL_TAGS.METAL]: t("Metal"),
  [MATERIAL_TAGS.GLASS]: t("Glass"),
  [MATERIAL_TAGS.DRYWALL]: t("Drywall"),
  [MATERIAL_TAGS.CONCRETE]: t("Concrete"),
  [MATERIAL_TAGS.FIBERGLASS]: t("Fiberglass"),
  [MATERIAL_TAGS.PORCELAIN]: t("Porcelain"),
  [MATERIAL_TAGS.CERAMIC]: t("Ceramic"),
  [MATERIAL_TAGS.TERRACOTTA]: t("Terracotta"),
  [MATERIAL_TAGS.FIRECLAY]: t("Fireclay"),
  [MATERIAL_TAGS.VINYL]: t("Vinyl"),
  [MATERIAL_TAGS.PLASTIC]: t("Plastic"),
  [MATERIAL_TAGS.FABRIC]: t("Fabric"),
  [MATERIAL_TAGS.CARPET]: t("Carpet"),
});

export const MATERIAL_TAGS_ORDERS = getOrderedMap(Object.values(MATERIAL_TAGS));

export const SHAPE_TAGS_LABELS = (t: TFunction) => ({
  [SHAPE_TAGS.SLAB]: t("Slab"),
  [SHAPE_TAGS.RECTANGLE]: t("Rectangle"),
  [SHAPE_TAGS.SQUARE]: t("Square"),
  [SHAPE_TAGS.TRIANGLE]: t("Triangle"),
  [SHAPE_TAGS.ROUND]: t("Round"),
  [SHAPE_TAGS.DOME]: t("Dome"),
  [SHAPE_TAGS.OVAL]: t("Oval"),
  [SHAPE_TAGS.HEXAGON]: t("Hexagon"),
  [SHAPE_TAGS.DIAMOND]: t("Diamond"),
  [SHAPE_TAGS.LEAF]: t("Leaf"),
  [SHAPE_TAGS.PEBBLE]: t("Pebble"),
  [SHAPE_TAGS.MOSAIC]: t("Mosaic"),
  [SHAPE_TAGS.IRREGULAR_SHAPE]: t("Irregular Shape"),
});

export const SHAPE_TAGS_ORDERS = getOrderedMap(Object.values(SHAPE_TAGS));

export const LOOK_TAGS_LABELS = (t: TFunction) => ({
  [LOOK_TAGS.SOLID_COLOR]: t("Solid Color"),
  [LOOK_TAGS.MULTI_COLOR]: t("Multi Color"),
  [LOOK_TAGS.MIXED_COLOR]: t("Mixed Color"),
  [LOOK_TAGS.TRANSLUCENT_COLOR]: t("Translucent Color"),
  [LOOK_TAGS.MIXED_SHADE]: t("Mixed Shade"),
  [LOOK_TAGS.VEINED]: t("Veined"),
  [LOOK_TAGS.FLECKED_SPECKLED]: t("Flecked Speckled"),
  [LOOK_TAGS.SWIRLED]: t("Swirled"),
  [LOOK_TAGS.MOTTLED]: t("Mottled"),
  [LOOK_TAGS.TERRAZZO]: t("Terrazzo"),
  [LOOK_TAGS.PATTERNED]: t("Patterned"),
});

export const LOOK_TAGS_ORDERS = getOrderedMap(Object.values(LOOK_TAGS));

export const COLOR_TAGS_LABELS = (t: TFunction) => ({
  [COLOR_TAGS.WHITE]: t("White"),
  [COLOR_TAGS.BLACK]: t("Black"),
  [COLOR_TAGS.BROWN]: t("Brown"),
  [COLOR_TAGS.RED]: t("Red"),
  [COLOR_TAGS.BLUE]: t("Blue"),
  [COLOR_TAGS.TURQUOISE]: t("Turquoise"),
  [COLOR_TAGS.GREY]: t("Grey"),
  [COLOR_TAGS.BEIGE]: t("Beige"),
  [COLOR_TAGS.CREAM]: t("Cream"),
  [COLOR_TAGS.GREEN]: t("Green"),
  [COLOR_TAGS.YELLOW]: t("Yellow"),
  [COLOR_TAGS.ORANGE]: t("Orange"),
  [COLOR_TAGS.PINK]: t("Pink"),
  [COLOR_TAGS.PURPLE]: t("Purple"),
  [COLOR_TAGS.WOOD_TONES]: t("Wood Tones"),
  [COLOR_TAGS.SILVER]: t("Silver"),
  [COLOR_TAGS.GOLD]: t("Gold"),
});

export const COLOR_TAGS_ORDERS = getOrderedMap(Object.values(COLOR_TAGS));

export const FINISH_TAGS_LABELS = (t: TFunction) => ({
  [FINISH_TAGS.MATTE]: t("Matte"),
  [FINISH_TAGS.SATIN]: t("Satin"),
  [FINISH_TAGS.SEMI_GLOSS]: t("Semi Gloss"),
  [FINISH_TAGS.HIGH_GLOSS]: t("High Gloss"),
  [FINISH_TAGS.NATURALLY_ROUGH]: t("Naturally Rough"),
  [FINISH_TAGS.SMOOTH]: t("Smooth"),
  [FINISH_TAGS.TEXTURED]: t("Textured"),
  [FINISH_TAGS.BRUSHED]: t("Brushed"),
  [FINISH_TAGS.HAMMERED]: t("Hammered"),
  [FINISH_TAGS.SANDBLASTED]: t("Sandblasted"),
  [FINISH_TAGS.POLISHED]: t("Polished"),
  [FINISH_TAGS.STAMPED]: t("Stamped"),
  [FINISH_TAGS.STENCILED]: t("Stenciled"),
  [FINISH_TAGS.BROOM]: t("Broom"),
  [FINISH_TAGS.SALT]: t("Salt"),
  [FINISH_TAGS.EXPOSED]: t("Exposed"),
  [FINISH_TAGS.DISTRESSED]: t("Distressed"),
  [FINISH_TAGS.WIRE_BRUSHED]: t("Wire Brushed"),
  [FINISH_TAGS.HAND_SCRAPED]: t("Hand Scraped"),
  [FINISH_TAGS.POPCORN]: t("Popcorn"),
  [FINISH_TAGS.ORANGE_PEEL]: t("Orange Peel"),
  [FINISH_TAGS.SAND_SWIRL]: t("Sand Swirl"),
  [FINISH_TAGS.COMB]: t("Comb"),
  [FINISH_TAGS.SLAP_BRUSH]: t("Slap Brush"),
});

export const FINISH_TAGS_ORDERS = getOrderedMap(Object.values(FINISH_TAGS));
