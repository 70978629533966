import { useMutation } from "@tanstack/react-query";
import { joinWaitlist } from "api/homebase.api";
import { Waitlist } from "api/models/Waitlist";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getWaitlistValidationSchema } from "./WaitlistForm.validation";

export const useWaitlist = () => {
  const { t } = useTranslation();
  const {
    isPending: isLoading,
    isSuccess,
    mutate: handleJoinWaitlist,
    reset,
  } = useMutation({
    mutationFn: joinWaitlist,
  });
  const validationSchema = useMemo(() => getWaitlistValidationSchema(t), [t]);

  const formik = useFormik<Waitlist>({
    initialValues: { email: "" },
    onSubmit: (values) => {
      handleJoinWaitlist(values);
    },
    validationSchema,
  });
  const {
    handleSubmit,
    touched,
    handleBlur,
    errors,
    values,
    handleChange,
    resetForm,
  } = formik;
  const showErrorState = Boolean(touched.email && errors.email);
  return {
    showErrorState,
    touched,
    isLoading,
    isSuccess,
    handleSubmit,
    handleBlur,
    errors,
    values,
    handleChange,
    resetForm,
    reset,
  };
};
