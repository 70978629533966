import { Typography } from "@mui/material";
import clsx from "clsx";
import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { PRIVACY, TERMS } from "router/routes";

import styles from "./LegalInformation.module.scss";

interface LinkProps {
  href: string;
  className?: string;
  children?: ReactNode;
}

export const Link: FC<LinkProps> = ({ href, className, children }) => (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <a
    className={clsx(styles.link, className)}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

const components = {
  termsLink: <Link href={TERMS()} />,
  privacyLink: <Link href={PRIVACY()} />,
  newline: <br />,
};

interface Props {
  className?: string;
}

export const LegalInformation: FC<Props> = ({ className }) => {
  return (
    <Typography
      variant="p.caption"
      component="p"
      className={clsx(styles.document, className)}
      mt={3.125}
      mb={3}
    >
      <Trans
        defaults="By creating an account you agree to Nook's <newline /><termsLink>Terms of Use</termsLink> and <privacyLink>Privacy Policy</privacyLink>"
        components={components}
      />
    </Typography>
  );
};
