import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SidebarActions.module.scss";

interface Props {
  onSubmit?: () => void;
  submitButtonText: string;
  onCancel: () => void;
  hideCancel?: boolean;
  "data-testid": string;
}

export const SidebarActions: FC<Props> = ({
  "data-testid": DTI,
  onCancel,
  onSubmit,
  hideCancel,
  submitButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.actions}>
      <Button
        variant="contained"
        disableRipple
        type="submit"
        fullWidth
        className={styles.button}
        data-testid={`${DTI}__SubmitButton`}
        onClick={onSubmit}
      >
        <Typography variant="p" component="p">
          {submitButtonText}
        </Typography>
      </Button>

      {!hideCancel && (
        <Button
          variant="outlined"
          disableRipple
          type="reset"
          fullWidth
          className={clsx(styles.button, styles.button__cancel)}
          data-testid={`${DTI}__CancelButton`}
          onClick={onCancel}
        >
          <Typography variant="p" component="p">
            {t("Cancel")}
          </Typography>
        </Button>
      )}
    </div>
  );
};
