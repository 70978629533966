import { Typography } from "@mui/material";
import clsx from "clsx";
import { SidebarForm } from "components/shared/SidebarForm/SidebarForm";
import { FC, ReactNode } from "react";
import { SvgIconComponent } from "shared/types/SvgIcon";

import styles from "./SidebarBaseForm.module.scss";

interface Props {
  isOpen: boolean;
  Icon?: SvgIconComponent;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  onSubmit: () => void;
  onClose: () => void;
  inputs: ReactNode;
  children: ReactNode;
  contentClasses?: string;
  hideItems?: boolean;
  hideHeader?: boolean;
}

const DTI = "SidebarBaseForm";

export const SidebarBaseForm: FC<Props> = ({
  isOpen,
  Icon,
  title,
  description,
  onSubmit,
  onClose,
  inputs,
  children,
  contentClasses,
  hideItems,
  hideHeader,
}) => {
  return (
    <SidebarForm
      isOpen={isOpen}
      onSubmit={onSubmit}
      onClose={onClose}
      className={clsx(styles.content, contentClasses)}
      hideHeader={hideHeader}
    >
      {Icon && (
        <div className={styles.iconWrapper}>
          <Icon className={styles.icon} />
        </div>
      )}
      {typeof title !== "string" ? (
        title
      ) : (
        <Typography
          variant="h3"
          component="h3"
          className={styles.title}
          data-testid={`${DTI}__Title`}
        >
          {title}
        </Typography>
      )}
      {typeof description !== "string" ? (
        description
      ) : (
        <Typography
          variant="p"
          component="p"
          className={styles.description}
          data-testid={`${DTI}__Description`}
        >
          {description}
        </Typography>
      )}
      {!hideItems && <div className={styles.inputs}>{inputs}</div>}
      {children}
    </SidebarForm>
  );
};
