import { ComponentType, lazy as _lazy } from "react";

import { withAttempts } from "./promiseUtils";
import { hardReloadPage } from "./url.utils";

type Factory<T> = () => Promise<{ default: ComponentType<T> }>;

function preloader<T>(factory: Factory<T>) {
  let preloaded = false;
  return async () => {
    if (preloaded) {
      return false;
    }
    await factory();
    preloaded = true;
    return true;
  };
}

export function lazy<T>(factory: Factory<T>) {
  const factoryWithAttempts = withAttempts(factory, {
    attempts: 3,
    retryDelayMs: 300,
    shouldRetry: ({ error }) => {
      /*
      Note(pavel): Webpack makes generic Error and augment it later with other
      properties, so we have to check the name rather than instance.

      For details see Webpack's CssLoadingRuntimeModule.js:304
       */
      if (error && error instanceof Error && error.name === "ChunkLoadError") {
        return true;
      }

      return false;
    },
  });
  const loadFn = async () => {
    try {
      return await factoryWithAttempts();
    } catch (e: unknown) {
      console.error(e);
      hardReloadPage();
    }
  };

  const Component = _lazy(loadFn);
  return Object.assign(Component, { preload: preloader(loadFn) });
}
