import { Trans } from "components/shared/Trans/Trans";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { SidebarEarlyAccess } from "./SidebarEarlyAccess";
import styles from "./SidebarEarlyAccessClient.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const components = {
  blue: <span className={styles.blue} />,
};

const Info = (
  <div className={styles.client}>
    <Trans
      defaults="Nook is always <blue>FREE</blue> for homeowners"
      components={components}
    />
  </div>
);

export const SidebarEarlyAccessClient: FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <SidebarEarlyAccess
      isOpen={isOpen}
      type="client"
      title={t("Get ready to feel great \nabout improving your home")}
      info={Info}
      onClose={onClose}
    />
  );
};
