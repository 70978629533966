import { useEffect } from "react";
function setDocHeight() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight / 100}px`
  );
}
export const useWindowsInnerHeight = () => {
  useEffect(() => {
    setDocHeight();
    window.addEventListener("orientationchange", setDocHeight);
    window.addEventListener("resize", setDocHeight);
    return () => {
      window.removeEventListener("orientationchange", setDocHeight);
      window.removeEventListener("resize", setDocHeight);
    };
  }, []);
};
