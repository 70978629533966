import { FC, useMemo } from "react";
import {
  Navigate as BaseNavigate, // eslint-disable-line no-restricted-imports
  NavigateProps,
  useLocation,
} from "react-router-dom";

import { RouteState } from "./hooks/useRouteState";

type Props = Pick<NavigateProps, "to" | "replace"> & {
  state?: RouteState;
};

export const Navigate: FC<Props> = ({ to, replace, state: _state }) => {
  const { pathname } = useLocation();

  const state = useMemo(
    () => ({
      ..._state,
      previous: pathname,
    }),
    [_state, pathname]
  );

  return <BaseNavigate to={to} replace={replace} state={state} />;
};
