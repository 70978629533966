import {
  Grid,
  Slide,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@mui/material";
import { ReactComponent as IconClose } from "assets/icons/buttons/close.svg";
import { ReactComponent as AlertIcon } from "assets/icons/snackbar/alert.svg";
import { ReactComponent as InformationIcon } from "assets/icons/snackbar/info.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/snackbar/success.svg";
import { ReactComponent as WarningIcon } from "assets/icons/snackbar/warning.svg";
import clsx from "clsx";
import { HBIconButton } from "components/HBIconButton/HBIconButton";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./HBSnackbarTitled.module.scss";

const icons = {
  info: <InformationIcon />,
  success: <SuccessIcon />,
  alert: <AlertIcon />,
  warning: <WarningIcon />,
};

interface Props {
  variant?: "info" | "infoBlue" | "infoAlert" | "success" | "alert" | "warning";
  size?: "standard" | "large";
  "data-testid"?: string;
  open: boolean;
  title: string;
  icon?: ReactNode;
  closable?: boolean;
  positionVertical?: "bottom" | "top";
  positionHorizontal?: "left" | "right" | "center";
  autoHideDuration?: number;
  onClose?: (reason?: SnackbarCloseReason) => void;
  children?: ReactNode;
  action?: ReactNode;
  className?: string;
  rootClassName?: string;
  showIcon?: boolean;
  messageContainerClassName?: string;
  titleClassName?: string;
}

export const HBSnackbarTitled: FC<Props> = ({
  variant = "info",
  size = "standard",
  "data-testid": testId = "HBSnackbar",
  open,
  title,
  icon,
  closable = false,
  positionVertical = "bottom",
  positionHorizontal = "center",
  autoHideDuration = 5000,
  onClose,
  children,
  action,
  className,
  rootClassName,
  showIcon = true,
  messageContainerClassName,
  titleClassName,
}) => {
  const { t } = useTranslation();
  const anchorOrigin = useMemo(
    () => ({
      vertical: positionVertical,
      horizontal: positionHorizontal,
    }),
    [positionHorizontal, positionVertical]
  );

  const onSnackbarClose = useCallback(
    (_, reason: SnackbarCloseReason) => {
      if (onClose) {
        onClose(reason);
      }
    },
    [onClose]
  );

  const onClickClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const contentProps = useMemo(
    () => ({
      classes: {
        root: `${styles[variant]} ${styles[size]} ${rootClassName}`,
        message: `${styles.messageContainer} ${messageContainerClassName}`,
      },
    }),
    [variant, size, rootClassName, messageContainerClassName]
  );

  return (
    <Snackbar
      className={clsx(styles.container, className)}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onSnackbarClose}
      anchorOrigin={anchorOrigin}
      TransitionComponent={Slide}
      action={
        action ?? (
          <>
            {closable && (
              <HBIconButton
                className={styles.closeButton}
                onClick={onClickClose}
                title={t("Close")}
              >
                <IconClose />
              </HBIconButton>
            )}
          </>
        )
      }
      message={
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          wrap="nowrap"
        >
          {showIcon && <Grid item>{icon ?? icons[variant]}</Grid>}
          <Grid item>
            <Typography
              data-testid={`${testId}__Snackbar--Title`}
              pl={2}
              pr={2}
              variant="p.caption"
              component="p"
              className={clsx(styles.title, titleClassName)}
            >
              {title}
            </Typography>
            {children && (
              <div
                data-testid={`${testId}__Snackbar--Message`}
                className={styles.subtitleContainer}
              >
                {children}
              </div>
            )}
          </Grid>
        </Grid>
      }
      ContentProps={contentProps}
    />
  );
};
