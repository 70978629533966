import { useActor } from "@xstate/react";
import {
  removeToast,
  systemSnackbarMachineActor,
  ToastVariants,
} from "api/SystemSnackbarMachine";
import { FC, useCallback } from "react";

import { SystemSnackbarProps } from "./components/SystemSnackbar/SystemSnackbar";
import { TooManyRequestsSnackbar } from "./components/TooManyRequestsSnackbar/TooManyRequestsSnackbar";

const SnackbarMap: Record<ToastVariants, FC<SystemSnackbarProps>> = {
  tooManyRequests: TooManyRequestsSnackbar,
};

export const SystemSnackbars: FC = () => {
  const [state, _send] = useActor(systemSnackbarMachineActor);
  const toasts = state.context.toasts;
  const handleRemoveToast = useCallback(
    (id: string) => {
      _send(removeToast({ id }));
    },
    [_send]
  );
  return (
    <>
      {toasts.map((toast) => {
        const Component = SnackbarMap[toast.variant];
        return (
          <Component
            key={toast.id}
            id={toast.id}
            {...toast.meta}
            onClose={handleRemoveToast}
          />
        );
      })}
    </>
  );
};
