import { useIOS } from "common/hooks/useIOS";

export const useIOSMeta = () => {
  const isIos = useIOS();
  if (isIos) {
    document
      .querySelector("[name=viewport]")
      // Note(Andrei): On iOS devices if we focus on input with font-size < 16px
      // it will automatically auto zoom the page so we need to prevent this
      // by setting maximum-scale=1 only for iOS
      .setAttribute(
        "content",
        "minimum-scale=1, initial-scale=1.0, width=device-width, maximum-scale=1"
      );
  }
};
