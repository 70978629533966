import { Typography } from "@mui/material";
import { ReactComponent as Logo } from "assets/logos/nook_logo.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { SidebarEarlyAccessClose } from "./SidebarEarlyAccessClose";
import styles from "./SidebarEarlyAccessTitle.module.scss";

interface Props {
  onClose: () => void;
  title?: string;
  hideClose?: boolean;
}

export const SidebarEarlyAccessTitle: FC<Props> = (props) => {
  const { onClose, hideClose, title } = props;
  const { t } = useTranslation();

  return (
    <>
      {!hideClose && <SidebarEarlyAccessClose onClose={onClose} />}
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <div className={styles.textWrapper}>
          <Typography variant="h3" component="h3">
            {title || t("Join Nook Early Access")}
          </Typography>
          <Typography variant="p3" className={styles.subtitle}>
            {t(
              "Thank you for your interest in Nook..\nYou’ll hear back from us within 1-2 business days."
            )}
          </Typography>
        </div>
      </div>
    </>
  );
};
