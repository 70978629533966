import { QueryClient } from "@tanstack/react-query";
import { Property } from "api/models/Property";
import { Upload } from "api/models/Upload";
import { ResponseWrapper } from "api/utils";
import { naturalSort } from "common/utils/string.utils";
import { OrchestratorHIPSContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import produce from "immer";
import { isEmpty } from "lodash-es";
import {
  COMMON_TAG_CATEGORIES,
  getCommonTagCategoriesOrder,
  getTagOrdersByCategory,
} from "pages/Media/constants";
import {
  AllStatusUploads,
  BuildStatusKey,
  MediaMachineActionsType,
  PropertyContextId,
  SelectedImage,
  Tag,
  Uploads,
  UploadsPageData,
} from "pages/Media/types";
import { getFiltersList, isUploadsCompareView } from "pages/Media/util";
import {
  RoomAndSpace,
  RoomsAndSpaces,
} from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { findBy } from "shared/util/findBy";
import { getKeys } from "shared/util/getKeys";

import { HIDDEN_TAG_CATEGORIES, MEDIA_QUERY_KEY_PREFIX } from "./constants";
import { MediaMachineContext } from "./models";

export const clearMediaCache = (queryClient: QueryClient) => () =>
  queryClient.invalidateQueries({ queryKey: [MEDIA_QUERY_KEY_PREFIX] });

export const propertyIdTagFor = (
  propertyId: Property["id"]
): PropertyContextId => `property_${propertyId}`;

export const propertyIdFromContext = (
  propertyContextId: string | undefined
) => {
  if (!propertyContextId) {
    return undefined;
  }
  return parseInt(propertyContextId.replace("property_", ""));
};

export const getAllowedFiltersList = (filters: string[]) => {
  const allowedFilters: string[] = [];
  filters.forEach((filter) => {
    if (!HIDDEN_TAG_CATEGORIES.has(filter)) {
      allowedFilters.push(filter);
    }
  });
  return allowedFilters;
};

export const getMediaVariables = (
  mediaMachineActions: MediaMachineActionsType
) => {
  const {
    multipleSelectUploads,
    buildStatusUploads,
    buildStatusFilters,
    uploads,
    initialFetch,
    uploadStatus,
    state,
    filters,
    tags,
    stateAddRecentUploadsTransitionLoad,
  } = mediaMachineActions;
  const stateIsUploading =
    state.matches("upload.uploadFiles") ||
    state.matches("upload.retryAllFailedUploads");
  const stateIdle = state.matches("upload.idle");
  const stateIsReady = state.matches("ready.idle");

  const isAllRegularUploadsSelected =
    multipleSelectUploads.length ===
    uploads.results.length + (uploadStatus.uploaded?.results.length ?? 0);
  const isAllBuildStatusUploadsSelected =
    buildStatusUploads.before.results.length +
      buildStatusUploads.after.results.length +
      buildStatusUploads.during.results.length ===
    multipleSelectUploads.length;
  const isAllUploadsSelected =
    multipleSelectUploads.length !== 0 &&
    (isAllRegularUploadsSelected || isAllBuildStatusUploadsSelected);
  const showSkeleton = initialFetch;
  const showRecentUploads = uploadStatus.uploaded.results.length !== 0;
  const normalizedFilters = getFiltersList(filters);
  const isMediaEmpty =
    uploads.count === 0 &&
    isEmpty(Object.keys(uploadStatus.status)) &&
    normalizedFilters.length === 0 &&
    !stateAddRecentUploadsTransitionLoad;
  const showFilterEmptyState = Boolean(
    (!uploads.count || uploads.count === 0) && normalizedFilters.length
  );
  const recentUploadsCount = uploadStatus.uploaded.results.length;
  const hasTags = Object.values(tags).some(
    (category) => Object.keys(category).length > 0
  );
  const hideImageFilters =
    (uploads.results.length === 0 &&
      uploadStatus.uploaded.results.length === 0 &&
      stateIdle &&
      normalizedFilters.length === 0 &&
      buildStatusFilters.size === 0) ||
    !hasTags;
  const showSplitUploadsView = isUploadsCompareView(buildStatusUploads);

  const showUploads = !initialFetch && !showSplitUploadsView;
  const multipleSelectActionsDisabled = multipleSelectUploads.length === 0;
  return {
    isAllRegularUploadsSelected,
    isAllBuildStatusUploadsSelected,
    isAllUploadsSelected,
    showSkeleton,
    showRecentUploads,
    stateIsUploading,
    stateIdle,
    stateIsReady,
    isMediaEmpty,
    showFilterEmptyState,
    recentUploadsCount,
    hideImageFilters,
    showSplitUploadsView,
    showUploads,
    multipleSelectActionsDisabled,
  };
};

export const sortTagsFn = (props: {
  categoryOrder: COMMON_TAG_CATEGORIES[];
  rooms: RoomsAndSpaces;
  hips: OrchestratorHIPSContext[];
}) => {
  const { categoryOrder, rooms, hips } = props;

  return (a: Tag, b: Tag) => {
    const indexA = categoryOrder.indexOf(a.category as COMMON_TAG_CATEGORIES);
    const indexB = categoryOrder.indexOf(b.category as COMMON_TAG_CATEGORIES);

    //Note(mate): If category is not in the list, assign a large index
    const categoryOrderA = indexA === -1 ? categoryOrder.length : indexA;
    const categoryOrderB = indexB === -1 ? categoryOrder.length : indexB;

    if (categoryOrderA !== categoryOrderB) {
      return categoryOrderA - categoryOrderB;
    }

    const rankA = getTagOrdersByCategory[a.category]?.[a.name];
    const rankB = getTagOrdersByCategory[b.category]?.[b.name];

    if (rankA !== undefined && rankB !== undefined) {
      return rankA - rankB;
    }

    const aName =
      findBy(rooms, "id", a.name)?.name ??
      findBy(hips, "id", a.name)?.onboarding.title;

    const bName =
      findBy(rooms, "id", b.name)?.name ??
      findBy(hips, "id", b.name)?.onboarding.title;

    if (aName && bName) {
      return naturalSort(aName, bName);
    }

    return a.name.localeCompare(b.name);
  };
};

export const sortUploadsTags = (params: {
  uploadsByBuildStatus: Record<BuildStatusKey, { content: Uploads }>;
  rooms: RoomAndSpace[];
  hips: OrchestratorHIPSContext[];
}): Record<BuildStatusKey, { content: Uploads }> => {
  const { uploadsByBuildStatus, rooms, hips } = params;
  const categoryOrder = getCommonTagCategoriesOrder;

  return produce(uploadsByBuildStatus, (ctx) => {
    const byBuildStatusList = Object.values(ctx).filter(Boolean);

    byBuildStatusList.forEach(({ content }) => {
      const uploads = content.results;
      uploads.forEach((upload) => {
        upload.tags.sort(sortTagsFn({ categoryOrder, hips, rooms }));
      });
    });
  });
};

export const mapUploadsPageData = (
  allStatusUploads: Partial<Record<BuildStatusKey, Uploads>>
): UploadsPageData[] => {
  const allKeys = Object.keys(allStatusUploads);

  const mapperFn = (buildStatusKey: BuildStatusKey) => {
    const source = allStatusUploads[buildStatusKey];
    return source?.results.map(({ id }) => ({
      id,
      page: source.self,
      buildStatusKey,
    }));
  };

  return allKeys.flatMap(mapperFn).filter(Boolean);
};

export const mergeUploadsPageData = (
  currentPageData: UploadsPageData[],
  newPageData: UploadsPageData[]
) => {
  const mergeMap = new Map<number, UploadsPageData>();

  currentPageData.forEach((item) => mergeMap.set(item.id, item));
  newPageData.forEach((item) => mergeMap.set(item.id, item));

  return Array.from(mergeMap.values());
};

const getUpdatedUploadsAfterDelete = (
  uploadsForPage: Uploads,
  targetUploads: Upload[]
) => {
  const mergeUploadsRemovingDuplicate = (
    existingUploads: Upload[],
    newUploads: Upload[]
  ): Upload[] => {
    const uniqueUploadsMap = new Map<number, Upload>();
    [...existingUploads, ...newUploads].forEach((upload) =>
      uniqueUploadsMap.set(upload.id, upload)
    );
    return Array.from(uniqueUploadsMap.values());
  };

  const updatedUploads = mergeUploadsRemovingDuplicate(
    targetUploads,
    uploadsForPage.results
  );

  return {
    ...uploadsForPage,
    results: updatedUploads,
  };
};

export const updateUploadsContextAfterDeletion = (props: {
  uploadsForBuildStatusPage: Partial<Record<BuildStatusKey, Uploads>>;
  deletedUploads?: SelectedImage[];
  deletedUploadId?: Upload["id"];
  ctx: MediaMachineContext;
}) => {
  const { uploadsForBuildStatusPage, deletedUploads, deletedUploadId, ctx } =
    props;
  const { uploads, buildStatusUploads } = ctx;

  const buildStatusKeys = getKeys(uploadsForBuildStatusPage).filter(
    (key) => uploadsForBuildStatusPage[key] !== undefined
  );

  const allStatusUploads: AllStatusUploads = {
    regular: ctx.uploads,
    ...buildStatusUploads,
  };

  const firstElements = buildStatusKeys.reduce<
    Partial<Record<BuildStatusKey, Upload["id"]>>
  >((acc, curr) => {
    const foundUpload = deletedUploads?.find((deletedUpload) =>
      allStatusUploads[curr]?.results.some(
        (allStatusUpload) => allStatusUpload.id === deletedUpload.id
      )
    );
    acc[curr] = deletedUploadId ?? foundUpload?.id;
    return acc;
  }, {});

  buildStatusKeys.forEach((buildStatusKey) => {
    const source =
      buildStatusKey === "regular"
        ? uploads
        : buildStatusUploads[buildStatusKey];
    const index = source.results.findIndex(
      (upload: Upload) => upload.id === firstElements[buildStatusKey]
    );
    const filtered = source.results.slice(0, index);
    const updatedUploads = getUpdatedUploadsAfterDelete(
      uploadsForBuildStatusPage[buildStatusKey],
      filtered
    );

    if (buildStatusKey === "regular") {
      ctx.uploads = updatedUploads;
    } else {
      ctx.buildStatusUploads[buildStatusKey] = updatedUploads;
    }
  });
};

export const getPageDataByStatus = (
  uploads: Upload[],
  uploadsPageData: UploadsPageData[]
) =>
  uploads.reduce<Record<BuildStatusKey, UploadsPageData[]>>(
    (acc, curr) => {
      const index = uploadsPageData.findIndex((data) => data.id === curr.id);
      const pageData = getPreviousPageData(index, uploadsPageData);
      acc[pageData.buildStatusKey].push(pageData);
      return acc;
    },
    { during: [], after: [], before: [], regular: [] }
  );

const getPreviousPageData = (
  index: number,
  uploadsPageData: UploadsPageData[]
) => {
  const current = uploadsPageData[index];

  if (index <= 0) {
    return current;
  }

  const previous = uploadsPageData[index - 1];
  return current.buildStatusKey === previous.buildStatusKey
    ? previous
    : current;
};

export const transformUploadsResponse = (
  uploadsResponse: Record<string, ResponseWrapper<Uploads> | undefined>
) =>
  Object.keys(uploadsResponse).reduce((acc, key) => {
    acc[key] = uploadsResponse[key]?.content;
    return acc;
  }, {} as AllStatusUploads);

export const sortSelectedImagesByIndex = (uploads: SelectedImage[]) => {
  return [...uploads].sort((a, b) => a.index - b.index);
};

export const getFlattenedAllUploads = (ctx: MediaMachineContext): Upload[] => {
  const buildStatusUploads = Object.values(ctx.buildStatusUploads)
    .map((it) => it.results)
    .filter(Boolean)
    .flat();
  return [...ctx.uploads.results, ...buildStatusUploads];
};
