import { TelemetryEventName } from "api/models/Telemetry";
import { useTrackEvent } from "core/telemetry";
import { useCallback } from "react";

export interface CallbackTelemetry {
  trackEvent?: TelemetryEventName;
}

export const useSendCallback = <
  Data = undefined,
  Type extends string = string,
  Send extends Function = Function
>(
  sendFn: Send,
  type: Type,
  telemetry: CallbackTelemetry = undefined
) => {
  const trackEvent = useTrackEvent();
  const { trackEvent: eventName } = telemetry || {};
  return useCallback(
    (data?: Data) => {
      sendFn({
        type,
        data,
      });
      if (eventName) {
        trackEvent(eventName);
      }
    },
    [sendFn, type, eventName, trackEvent]
  );
};
