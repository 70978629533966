import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import {
  OrchestratorEvent,
  OrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import {
  CategoryRouteParams,
  CategoryRouteRoomParams,
  HIPsRoomRouteParams,
  HIPsRouteParams,
} from "router/models";
import {
  DASHBOARD,
  GUIDE,
  HIPS_DASHBOARD,
  HIPS_PLAN_DETAILS,
  HIPS_PLANNING_AREA,
  HIPS_PROJECT_SCOPE,
  HIPS_PROJECT_SCOPE_CHOOSE_PRESET,
  HIPS_PROJECT_SCOPE_CHOOSE_TASKS,
  HIPS_PROJECT_SCOPE_CHOOSE_TASKS_CATEGORY,
  HIPS_PROJECT_SCOPE_OVERVIEW,
  HIPS_PROJECT_SCOPE_PRESET_GROUP_PAGE,
  HIPS_PROJECT_SCOPE_ROOM,
  HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS,
  HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS_CATEGORY,
  HIPS_ROOM,
} from "router/routes";

import {
  BaseHipsPayload,
  NavigateToGuidePayload,
  OpenPresetGroupEventData,
  PlanningAreaEventData,
} from "./Models";

interface Props {
  navigate: ReturnType<typeof useStableNavigate>;
  isNavigateFromRoom?: boolean;
}

export const getHIPsMachineNavigateActions = ({
  navigate,
  isNavigateFromRoom,
}: Props) => {
  return {
    goBack(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(HIPS_PROJECT_SCOPE_CHOOSE_TASKS(event.data));
    },
    goRootDashboard(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(DASHBOARD(event.data));
    },
    goToDashboard(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(HIPS_DASHBOARD(event.data));
    },
    goToSOWChooseFromPreset(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(HIPS_PROJECT_SCOPE_CHOOSE_PRESET(event.data));
    },
    goToSOWChooseIndividually(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(
        HIPS_PROJECT_SCOPE_CHOOSE_TASKS(event.data),
        isNavigateFromRoom
          ? {
              state: { goBackPage: document.location.pathname },
            }
          : undefined
      );
    },
    goToSOWRoomChooseIndividually(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRoomRouteParams>
    ) {
      navigate(HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS(event.data), {
        state: { goBackPage: document.location.pathname },
      });
    },
    goToRoomPage(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<PlanningAreaEventData>
    ) {
      navigate(HIPS_ROOM(event.data));
    },
    goToPlanDetailsPage(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<PlanningAreaEventData>
    ) {
      navigate(HIPS_PLAN_DETAILS(event.data));
    },
    goToGuidePage(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<NavigateToGuidePayload>
    ) {
      navigate(GUIDE(event.data));
    },
    goToPlaningArea(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(HIPS_PLANNING_AREA(event.data));
    },
    goToSubCategory(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<CategoryRouteParams>
    ) {
      navigate(
        HIPS_PROJECT_SCOPE_CHOOSE_TASKS_CATEGORY(event.data),
        isNavigateFromRoom
          ? {
              state: { goBackPage: document.location.pathname },
            }
          : undefined
      );
    },
    goToRoomSubCategory(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<CategoryRouteRoomParams>
    ) {
      navigate(HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS_CATEGORY(event.data), {
        state: { goBackPage: document.location.pathname },
      });
    },
    goToProjectScope(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) {
      navigate(
        HIPS_PROJECT_SCOPE(event.data),
        isNavigateFromRoom
          ? {
              state: { goBackPage: document.location.pathname },
            }
          : undefined
      );
    },
    goToRoomProjectScope(
      _: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRoomRouteParams>
    ) {
      navigate(HIPS_PROJECT_SCOPE_ROOM(event.data), {
        state: { goBackPage: document.location.pathname },
      });
    },
    goToPresetGroup(_, event: OrchestratorEvent<OpenPresetGroupEventData>) {
      navigate(HIPS_PROJECT_SCOPE_PRESET_GROUP_PAGE(event.data));
    },
    goToCreateRoomLayout(
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<NavigateToGuidePayload & { id?: string }>
    ) {
      const { propertyId, id, roomId, hipsId, type } = event.data;

      if (id) {
        navigate(
          GUIDE({
            propertyId,
            id,
            roomId,
            hipsId,
          })
        );

        return;
      }

      const ctx = getActivePropertyContext(_ctx);
      const hip = ctx.hips.find((h) => h.id === hipsId);
      const guide = hip.scopeOfWork.guides.find(
        (g) => g.type === type && g.roomId === roomId
      );

      navigate(
        GUIDE({
          propertyId,
          id: guide.id,
          roomId,
          hipsId,
        })
      );
    },
    goToOverviewPage(_, event: OrchestratorEvent<BaseHipsPayload>) {
      navigate(HIPS_PROJECT_SCOPE_OVERVIEW(event.data));
    },
  };
};
