import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { SidebarInput } from "components/shared/SidebarForm/SidebarInput/SidebarInput";
import { Trans } from "components/shared/Trans/Trans";
import { Link } from "components/SignUp/LegalInformation/LegalInformation";
import { useFormik } from "formik";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { PRIVACY, TERMS } from "router/routes";

import styles from "./SidebarEarlyAccessInputs.module.scss";

interface Props {
  values: ReturnType<typeof useFormik>["initialValues"];
  errors: Record<string, string>;
  touched: Record<string, boolean>;
  onChange: ReturnType<typeof useFormik>["handleChange"];
  onBlur: ReturnType<typeof useFormik>["handleBlur"];
  submitText?: string;
  emailLabel?: string;
  onSubmit: () => void;
  info?: ReactNode;
}

const DTI = "SidebarEarlyAccessInputs";

const components = {
  newline: <br />,
  termsLink: <Link href={TERMS()} className={styles.agreeLinks} />,
  privacyLink: <Link href={PRIVACY()} className={styles.agreeLinks} />,
  blue: <span className={styles.blue} />,
};

export const SidebarEarlyAccessInputs: FC<Props> = ({
  errors,
  touched,
  values,
  submitText,
  emailLabel,
  onBlur,
  onChange,
  onSubmit,
  info,
}) => {
  const { t } = useTranslation();
  const buttonLabel = submitText || t("Get Early Access");

  return (
    <>
      <SidebarInput
        name="name"
        label={values.name && t("Full Name")}
        errorText={errors.name}
        value={values.name}
        touched={touched.name}
        fullWidth
        placeholder={t("Full Name")}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={`${DTI}__NameInput`}
      />

      <SidebarInput
        name="email"
        label={values.email && (emailLabel || t("Email"))}
        errorText={errors.email}
        value={values.email}
        touched={touched.email}
        inputMode="email"
        fullWidth
        placeholder={emailLabel || t("Email")}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={`${DTI}__EmailInput`}
      />

      <FormControl
        className={styles.checkBoxField}
        error={touched.checked && Boolean(errors.checked)}
      >
        <FormControlLabel
          name="checked"
          control={
            <Checkbox
              checked={values.checked}
              inputProps={{ "aria-label": t("agree") }}
            />
          }
          onChange={onChange}
          label={
            <Typography className={styles.checkboxText}>
              <Trans
                defaults="I agree to receive emails from Nook and accept <newline /> Nook’s <privacyLink>Privacy Policy</privacyLink> and <termsLink>Terms of Conditions</termsLink>."
                components={components}
              />
            </Typography>
          }
        />
        <FormHelperText className={styles.checkBoxHelper}>
          {touched.checked && errors.checked}
        </FormHelperText>
      </FormControl>

      <div className={styles.buttonsWrapper}>
        <Button
          variant="contained"
          disableRipple
          type="submit"
          fullWidth
          className={styles.submitButton}
          data-testid={`${DTI}__SubmitButton`}
          onClick={onSubmit}
        >
          <Typography className={styles.submitButtonText}>
            {buttonLabel}
          </Typography>
        </Button>
        {info}
      </div>
    </>
  );
};
