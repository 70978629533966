import { transform } from "api/utils";

import { AppDataV17 } from "./models/AppDataV17";
import { AppDataV18 } from "./models/AppDataV18";

export function mapV17ToV18(data: AppDataV17): AppDataV18 {
  return transform(data, (result) => {
    result.state.version = 18;
    if (result.state.data.hasSeenConfirmationWarning === undefined) {
      result.state.data.hasSeenConfirmationWarning = {};
    }
  });
}
