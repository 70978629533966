import { useEffect } from "react";

import { useBoolean } from "./useBoolean";

export const useDelayed = (isReady: boolean, minDuration: number = 500) => {
  const [isMinDurationPassed, setIsMinDurationPassed] = useBoolean(isReady);

  useEffect(() => {
    const timeoutId = setTimeout(setIsMinDurationPassed, minDuration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [minDuration, setIsMinDurationPassed]);

  return isReady && isMinDurationPassed;
};
