import { Tooltip, Typography } from "@mui/material";

import styles from "./HBModalTooltip.module.scss";

/** @type {Partial<import("@mui/material").TooltipClasses>} */
const classes = {
  tooltip: styles.tooltip,
  arrow: styles.arrow,
};
export const HBModalTooltip = ({ title, children, ...props }) => {
  return (
    <Tooltip
      TransitionProps={{ timeout: 0 }}
      {...props}
      classes={classes}
      disableInteractive
      title={
        <Typography
          variant="p2"
          component="p"
          fontWeight="400"
          className={styles.title}
        >
          {title}
        </Typography>
      }
      arrow
    >
      {children}
    </Tooltip>
  );
};
