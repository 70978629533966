import { ReactComponent as HVACCloset } from "assets/icons/rooms_and_spaces/ac_closet.svg";
import { ReactComponent as Attic } from "assets/icons/rooms_and_spaces/attic.svg";
import { ReactComponent as Basement } from "assets/icons/rooms_and_spaces/basement.svg";
import { ReactComponent as Bathroom } from "assets/icons/rooms_and_spaces/bathroom.svg";
import { ReactComponent as Bedroom } from "assets/icons/rooms_and_spaces/bedroom.svg";
import { ReactComponent as CrawlSpace } from "assets/icons/rooms_and_spaces/crawl_space.svg";
import { ReactComponent as Deck } from "assets/icons/rooms_and_spaces/deck.svg";
import { ReactComponent as Den } from "assets/icons/rooms_and_spaces/den.svg";
import { ReactComponent as DiningRoom } from "assets/icons/rooms_and_spaces/dining_room.svg";
import { ReactComponent as Driveway } from "assets/icons/rooms_and_spaces/driveway.svg";
import { ReactComponent as Entrance } from "assets/icons/rooms_and_spaces/entrance.svg";
import { ReactComponent as FamilyRoom } from "assets/icons/rooms_and_spaces/family_room.svg";
import { ReactComponent as Foyer } from "assets/icons/rooms_and_spaces/foyer.svg";
import { ReactComponent as FrontGarden } from "assets/icons/rooms_and_spaces/front_garden.svg";
import { ReactComponent as FrontPorch } from "assets/icons/rooms_and_spaces/front_porch.svg";
import { ReactComponent as GameRoom } from "assets/icons/rooms_and_spaces/game_room.svg";
import { ReactComponent as Garage } from "assets/icons/rooms_and_spaces/garage.svg";
import { ReactComponent as Gym } from "assets/icons/rooms_and_spaces/gym.svg";
import { ReactComponent as HalfBath } from "assets/icons/rooms_and_spaces/half_bath.svg";
import { ReactComponent as Hallway } from "assets/icons/rooms_and_spaces/hallway.svg";
import { ReactComponent as HomeTheater } from "assets/icons/rooms_and_spaces/home_theater.svg";
import { ReactComponent as Kitchen } from "assets/icons/rooms_and_spaces/kitchen.svg";
import { ReactComponent as Laundry } from "assets/icons/rooms_and_spaces/laundry.svg";
import { ReactComponent as Library } from "assets/icons/rooms_and_spaces/library.svg";
import { ReactComponent as LivingRoom } from "assets/icons/rooms_and_spaces/living_room.svg";
import { ReactComponent as Loft } from "assets/icons/rooms_and_spaces/loft.svg";
import { ReactComponent as MusicRoom } from "assets/icons/rooms_and_spaces/music_room.svg";
import { ReactComponent as Nursery } from "assets/icons/rooms_and_spaces/nursery.svg";
import { ReactComponent as Office } from "assets/icons/rooms_and_spaces/office.svg";
import { ReactComponent as OutdoorKitchen } from "assets/icons/rooms_and_spaces/outdoor_kitchen.svg";
import { ReactComponent as PlayRoom } from "assets/icons/rooms_and_spaces/play_room.svg";
import { ReactComponent as Pool } from "assets/icons/rooms_and_spaces/pool.svg";
import { ReactComponent as RearGarden } from "assets/icons/rooms_and_spaces/rear_garden.svg";
import { ReactComponent as Roof } from "assets/icons/rooms_and_spaces/roof.svg";
import { ReactComponent as ScreenedPorch } from "assets/icons/rooms_and_spaces/screened_porch.svg";
import { ReactComponent as SideGarden } from "assets/icons/rooms_and_spaces/side_garden.svg";
import { ReactComponent as Staircase } from "assets/icons/rooms_and_spaces/staircase.svg";
import { ReactComponent as StorageRoom } from "assets/icons/rooms_and_spaces/storage_room.svg";
import { ReactComponent as SunRoom } from "assets/icons/rooms_and_spaces/sun_room.svg";
import { ReactComponent as UtilityRoom } from "assets/icons/rooms_and_spaces/utility_room.svg";
import { ReactComponent as WalkInCloset } from "assets/icons/rooms_and_spaces/walk_in_closet.svg";
import { ReactComponent as WalkInPantry } from "assets/icons/rooms_and_spaces/walk_in_pantry.svg";
import { ReactComponent as Walkway } from "assets/icons/rooms_and_spaces/walkway.svg";
import { ReactComponent as WineCellar } from "assets/icons/rooms_and_spaces/wine_cellar.svg";
import { SvgIconComponent } from "shared/types/SvgIcon";

import { ROOMS_PIECES, RoomType } from "./roomPieces";

export const ROOMS_PIECES_ICONS: Record<RoomType, SvgIconComponent> = {
  [ROOMS_PIECES.BEDROOM]: Bedroom,
  [ROOMS_PIECES.BATHROOM]: Bathroom,
  [ROOMS_PIECES.HALF_BATH]: HalfBath,
  [ROOMS_PIECES.LIVING_ROOM]: LivingRoom,
  [ROOMS_PIECES.DINING_ROOM]: DiningRoom,
  [ROOMS_PIECES.KITCHEN]: Kitchen,
  [ROOMS_PIECES.FAMILY_ROOM]: FamilyRoom,
  [ROOMS_PIECES.DEN]: Den,
  [ROOMS_PIECES.NURSERY]: Nursery,
  [ROOMS_PIECES.LOFT]: Loft,
  [ROOMS_PIECES.OFFICE]: Office,
  [ROOMS_PIECES.LIBRARY]: Library,
  [ROOMS_PIECES.PLAY_ROOM]: PlayRoom,
  [ROOMS_PIECES.GAME_ROOM]: GameRoom,
  [ROOMS_PIECES.HOME_THEATER]: HomeTheater,
  [ROOMS_PIECES.GYM]: Gym,
  [ROOMS_PIECES.SUN_ROOM]: SunRoom,
  [ROOMS_PIECES.MUSIC_ROOM]: MusicRoom,
  [ROOMS_PIECES.WALK_IN_CLOSET]: WalkInCloset,
  [ROOMS_PIECES.LAUNDRY]: Laundry,
  [ROOMS_PIECES.HVAC_CLOSET]: HVACCloset,
  [ROOMS_PIECES.WALKIN_PANTRY]: WalkInPantry,
  [ROOMS_PIECES.WINE_CELLAR]: WineCellar,
  [ROOMS_PIECES.ATTIC]: Attic,
  [ROOMS_PIECES.BASEMENT]: Basement,
  [ROOMS_PIECES.STORAGE_ROOM]: StorageRoom,
  [ROOMS_PIECES.UTILITY_ROOM]: UtilityRoom,
  [ROOMS_PIECES.FRONT_PORCH]: FrontPorch,
  [ROOMS_PIECES.ENTRANCE]: Entrance,
  [ROOMS_PIECES.FOYER]: Foyer,
  [ROOMS_PIECES.HALLWAY]: Hallway,
  [ROOMS_PIECES.STAIRCASE]: Staircase,
  [ROOMS_PIECES.GARAGE]: Garage,
  [ROOMS_PIECES.DRIVEWAY]: Driveway,
  [ROOMS_PIECES.WALKWAY]: Walkway,
  [ROOMS_PIECES.SCREENED_PORCH]: ScreenedPorch,
  [ROOMS_PIECES.DECK]: Deck,
  [ROOMS_PIECES.FRONT_GARDEN]: FrontGarden,
  [ROOMS_PIECES.SIDE_GARDEN]: SideGarden,
  [ROOMS_PIECES.REAR_GARDEN]: RearGarden,
  [ROOMS_PIECES.OUTDOOR_KITCHEN]: OutdoorKitchen,
  [ROOMS_PIECES.POOL]: Pool,
  [ROOMS_PIECES.ROOF]: Roof,
  [ROOMS_PIECES.CRAWL_SPACE]: CrawlSpace,
};
