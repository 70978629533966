import { useCallback, useState } from "react";
import { useDidUpdate } from "rooks";

enum Status {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export const useImageLoaded = (url: string | undefined) => {
  const [status, setStatus] = useState(url ? Status.LOADING : Status.FAILURE);

  const resetImageLoaded = useCallback(() => {
    setStatus(Status.LOADING);
  }, []);

  useDidUpdate(() => {
    if (url) {
      resetImageLoaded();
    }
  }, [url, resetImageLoaded]);

  const handleImageLoaded = useCallback(() => {
    setStatus(Status.SUCCESS);
  }, []);

  const handleImageError = useCallback(() => {
    setStatus(Status.FAILURE);
  }, []);

  return {
    imageLoaded: status === Status.SUCCESS,
    imageError: status === Status.FAILURE,
    imageLoading: status === Status.LOADING,
    handleImageLoaded,
    resetImageLoaded,
    handleImageError,
  };
};
