export enum LightingControlTypes {
  DIMMER = "dimmer",
  SENSOR = "sensor",
  CONTROL_PANEL = "lighting_control_panel",
  SWITCH = "lighting_switch",
  PULL_CHORD = "lighting_pull_chord",
}

export enum LightingControlIconStates {
  SENSOR_DISABLED = "SENSOR_DISABLED",
  SWITCH_DISABLED = "SWITCH_DISABLED",
  CONTROL_PANEL_DISABLED = "CONTROL_PANEL_DISABLED",
  DIMMER_DISABLED = "DIMMER_DISABLED",
  PULL_CHORD_DISABLED = "PULL_CHORD_DISABLED",
}
