import { FC, useMemo } from "react";
import { Trans as I18nTrans, TransProps } from "react-i18next";

import styles from "./Trans.module.scss";

type Props = {} & TransProps<string>;

const defaultComponents = {
  b: <b className={styles.bold} />,
  nl: <br className={styles.newline} />,
  mnl: <br className={styles.mobileNewline} />,
  nbsp: <span className={styles.nonBreakingSpace} />,
};

// TODO(Andrei): Create a task to replace original <Trans/> component with the new one
export const Trans: FC<Props> = ({ components, ...rest }) => {
  const finalComponents = useMemo(
    () => ({ ...defaultComponents, ...components }),
    [components]
  );
  return <I18nTrans components={finalComponents} {...rest} />;
};
