import { useLocation } from "react-router-dom";

export interface RouteState {
  previous?: string;
  goBackPage?: string;
  scrollTop?: boolean;
  getEducated?: {
    scrollToBlogSection?: boolean;
    scrollToBlogPost?: string;
    scrollToBlogChapter?: boolean;
    scrollSmooth?: boolean;
  };
  dashboard?: {
    isPropertyTransferSuccessful?: boolean;
  };
}

const NO_STATE = {};

export const useRouteState = (): RouteState => {
  const { state } = useLocation();
  return state ?? NO_STATE;
};
