import { CoverPhotoItem } from "api/models/CoverPhotos";
import { transform } from "api/utils";

import { AppDataV7 } from "./models/AppDataV7";
import { AppDataV8 } from "./models/AppDataV8";

type CoverPhotoOld = CoverPhotoItem & { image_id: number };

export function mapV7ToV8(data: AppDataV7): AppDataV8 {
  return transform(data, (result) => {
    result.state.version = 8;

    result.state.data.properties.forEach((property) => {
      if (property?.media?.coverPhotos) {
        const { coverPhotos } = property.media;
        Object.values(coverPhotos).map((coverPhoto: CoverPhotoOld) => {
          coverPhoto.id = coverPhoto.image_id;
          delete coverPhoto.image_id;
          return coverPhoto;
        });
      }
    });
  });
}
