/**
 * Checks if we're in the automated environment.
 */
export const isE2E = () => navigator.webdriver;

/**
 * Returns duration for animations (and probably something else) depends on environment.
 */
export const smartDuration = (duration: number) => {
  return isE2E() ? Math.min(duration, 100) : duration;
};

/**
 * Timeout, but it can patch duration internally for tests.
 */
export const smartTimeout = (callback: () => void, timeout: number) => {
  return setTimeout(callback, smartDuration(timeout));
};
