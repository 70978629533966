import { ReactComponent as CloseIcon } from "assets/icons/buttons/close.svg";
import { HBIconButton } from "components/HBIconButton/HBIconButton";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SidebarEarlyAccessClose.module.scss";

const DTI = "SidebarEarlyAccess";

interface Props {
  onClose: () => void;
}

export const SidebarEarlyAccessClose: FC<Props> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <HBIconButton
        title={t("Close")}
        onClick={onClose}
        disableRipple
        className={styles.close}
        data-testid={`${DTI}__CloseButton`}
      >
        <CloseIcon />
      </HBIconButton>
    </div>
  );
};
