// eslint-disable-next-line no-restricted-imports
import { useInterpret as _useInterpret } from "@xstate/react";
import { useEffect } from "react";
import { useWindowEventListener } from "rooks";
import { Interpreter } from "xstate";

const alwaysTransitions = ["xstate.", "INITIAL_"];

export const useInterpret = (
  getMachine: Parameters<typeof _useInterpret>[0]
) => {
  return _useInterpret(getMachine, {
    devTools: process.env.NODE_ENV !== "production",
  });
};

export const setupXStateDebugger = <T>(machine: Interpreter<T>) => {
  if (process.env.NODE_ENV === "production") {
    return;
  }

  machine.onTransition((e) => {
    const state = e.value as string;
    const action = e.event.type;

    const isActionAllowedForState =
      e.machine.config.states?.[state]?.on?.[action] !== undefined;

    const isActionAllowedForMachine =
      e.machine.config.on?.[action] !== undefined;

    const isAlwaysTransition = alwaysTransitions.some((prefix) =>
      action.startsWith(prefix)
    );

    if (
      !e.changed &&
      !isActionAllowedForState &&
      !isActionAllowedForMachine &&
      !isAlwaysTransition
    ) {
      console.error(
        `Received action ${action} which isn't allowed for state`,
        state
      );
    }
  });
};

export const useXStateBack = <T>(
  backEventName: string,
  send: (event: string) => void,
  service: Interpreter<T>
) => {
  useWindowEventListener("popstate", () => {
    send(backEventName);
  });

  useEffect(() => {
    service.onTransition((state, event) => {
      if (state.changed && event.type !== backEventName) {
        history.pushState({}, "", window.location.pathname);
      }
    });
  }, [service, backEventName]);
};
