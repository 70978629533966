import { EarlyAccessFormData } from "api/models/EarlyAccess";
import { getEarlyAccessValidationSchema } from "components/SidebarForms/validations/EarlyAccessValidation";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

export const useEarlyAccessForm = (
  onSubmit: (data: EarlyAccessFormData) => void,
  accountType: EarlyAccessFormData["account_type"] = "homeowner"
) => {
  const { t } = useTranslation();
  const validationSchema = getEarlyAccessValidationSchema(t);

  return useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      account_type: accountType,
      checked: false,
    },
    onSubmit,
    validationSchema,
  });
};
