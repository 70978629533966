import { transform } from "api/utils";
import { GuideItemsTypes } from "constants/guides/GuideItems";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";

import { AppDataV10 } from "./models/AppDataV10";
import { AppDataV11 } from "./models/AppDataV11";

export function mapV10ToV11(data: AppDataV10): AppDataV11 {
  return transform(data, (result) => {
    result.state.version = 11;

    result.state.data.properties.forEach((property, index) => {
      const savedInspirationMapping =
        data.state.data.properties[index]?.inspiration?.savedInspiration;
      if (!savedInspirationMapping) {
        return;
      }
      if (!Array.isArray(property.inspiration.savedInspiration)) {
        property.inspiration.savedInspiration = [];
      }

      Object.entries(savedInspirationMapping).forEach(
        ([guideCategory, items]) => {
          Object.entries(items).forEach(([itemType, mappingItems]) => {
            mappingItems.forEach((mapping) => {
              property.inspiration.savedInspiration.push({
                guideCategory: guideCategory as GuideItemTypeCategory,
                guideItemType: itemType as GuideItemsTypes,
                inspirationId: mapping.id,
                mediaId: mapping.mappedToId,
              });
            });
          });
        }
      );
    });
  });
}
