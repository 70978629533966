import { AppDataV2 } from "./models/AppDataV2";
import { AppDataV3 } from "./models/AppDataV3";

export function mapV2ToV3(data: AppDataV2): AppDataV3 {
  return {
    state: {
      version: 3,
      data: {
        ...data.state.data,
        activeGuides:
          data.state.data.activeGuide &&
          Object.keys(data.state.data.activeGuide).length > 0
            ? [data.state.data.activeGuide]
            : [],
      },
    },
  };
}
