import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRouteState } from "router/hooks/useRouteState";

export const useResetRouterScroll = () => {
  const { pathname } = useLocation();
  const { scrollTop } = useRouteState();
  useEffect(() => {
    if (pathname && scrollTop !== false) {
      window.scrollTo(0, 0);
    }
  }, [pathname, scrollTop]);
};
