import { transform } from "api/utils";
import { getTagNameFromRoomName } from "pages/Guides/tabs/GuideImagesTab/components/ImagePanel/utils";

import { AppDataV6 } from "./models/AppDataV6";
import { AppDataV7 } from "./models/AppDataV7";

export function mapV6ToV7(data: AppDataV6): AppDataV7 {
  return transform(data, (result) => {
    result.state.version = 7;

    result.state.data.properties.forEach((property) => {
      if (property?.media?.coverPhotos) {
        const { coverPhotos } = property.media;

        property.rooms.forEach((room) => {
          const roomTag = getTagNameFromRoomName(room.name);

          if (coverPhotos[roomTag]) {
            coverPhotos[room.id] = coverPhotos[roomTag];
            delete coverPhotos[roomTag];
          }
        });
      }
    });
  });
}
