import { ButtonBase } from "@mui/material";
import { hardReloadPage } from "common/utils/url.utils";
import { HBSnackbarTitled } from "components/Snackbars/HBSnackbarTitled/HBSnackbarTitled";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLockBodyScroll } from "rooks";

import styles from "./ConcurrentUpdateError.module.scss";

export const ConcurrentUpdateError: FC = () => {
  const { t } = useTranslation();

  useLockBodyScroll(true);
  return (
    <div className={styles.backdrop}>
      <HBSnackbarTitled
        open
        rootClassName={styles.snackbarWrapper}
        autoHideDuration={null}
        showIcon={false}
        title={t("This page has been updated somewhere else")}
        titleClassName={styles.titleText}
        messageContainerClassName={styles.messageContainer}
        variant="infoAlert"
        action={
          <ButtonBase
            disableRipple
            disableTouchRipple
            onClick={hardReloadPage}
            className={styles.actionButton}
          >
            {t("Reload")}
          </ButtonBase>
        }
      >
        <span>{t("Please reload to see the latest version")}</span>
      </HBSnackbarTitled>
    </div>
  );
};
