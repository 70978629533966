import { Typography } from "@mui/material";
import { ReactComponent as Icon } from "assets/icons/upcoming.svg";
import { ReactComponent as LogoIcon } from "assets/logos/nook_logo.svg";
import { SidebarForm } from "components/shared/SidebarForm/SidebarForm";
import { SidebarInput } from "components/shared/SidebarForm/SidebarInput/SidebarInput";
import { SidebarLoading } from "components/shared/SidebarForm/SidebarLoading/SidebarLoading";
import { SidebarSuccess } from "components/shared/SidebarForm/SidebarSuccess/SidebarSuccess";
import { SidebarActions } from "components/SidebarForms/components/SidebarBaseForm/SidebarActions/SidebarActions";
import { BlogWaitlistInfo } from "pages/GetEducated/components/Waitlist/BlogWaitlistInfo/BlogWaitlistInfo";
import { useWaitlist } from "pages/GetEducated/components/Waitlist/WaitlistForm/useWaitlist";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SidebarSubscribe.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const DTI = "SidebarSubscribe";

export const SidebarSubscribe: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isLoading,
    isSuccess,
    values,
    resetForm,
    reset,
  } = useWaitlist();
  useEffect(() => {
    if (isOpen) {
      resetForm();
      reset();
    }
  }, [isOpen, reset, resetForm]);
  return (
    <SidebarForm
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      className={styles.content}
    >
      <LogoIcon className={styles.logo} />
      <Typography
        variant="h3"
        component="h3"
        className={styles.title}
        data-testid={`${DTI}__Title`}
      >
        {t("Let Nook\n Be Your Guide")}
      </Typography>
      <Typography
        variant="p"
        component="p"
        className={styles.description}
        data-testid={`${DTI}__Description`}
      >
        {t(
          "Subscribe to our newsletter\n and receive updates about the new\n features releasing soon on Nook!"
        )}
      </Typography>
      <SidebarInput
        name="email"
        value={values.email}
        errorText={errors.email}
        touched={touched.email}
        label={t("Email")}
        placeholder={t("Your Email")}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        data-testid={`${DTI}__EmailInput`}
      />
      <SidebarActions
        data-testid={DTI}
        onCancel={onClose}
        onSubmit={handleSubmit}
        submitButtonText={t("Subscribe")}
      />
      <BlogWaitlistInfo
        className={styles.infoContainer}
        textClassName={styles.info}
        addBreakSpace
      />
      {isLoading && <SidebarLoading />}
      {isSuccess && (
        <SidebarSuccess
          Icon={Icon}
          title={t("Welcome Aboard!")}
          description={t(
            "Please check your inbox to confirm your subscription to our newsletter."
          )}
          onClose={onClose}
        />
      )}
    </SidebarForm>
  );
};
