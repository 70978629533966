export const SYMBOL_REGEX = /^(?=.*[!@#$%^&*])/;
export const UPPERCASE_REGEX = /^(?=.*[A-Z])/;

export const ERROR_CODES = {
  MIN: "errorCode.min",
  MAX: "errorCode.max",
  SYMBOL: "errorCode.symbol",
  UPPERCASE: "errorCode.uppercase",
  REQUIRED: "errorCode.required",
  EMAIL: "errorCode.email",
  FULLNAME: {
    NUMBER: "errorCode.fullname.number",
  },
};
