import { lazy, Suspense } from "react";

const SentryReplayControllerLazy = lazy(
  /* webpackChunkName: "sentry_replay_controller" */
  () => import("components/SentryReplayController/SentryReplayController")
);

export const SentryReplayDevtools = () => {
  return (
    <Suspense fallback={<div />}>
      <SentryReplayControllerLazy />
    </Suspense>
  );
};
