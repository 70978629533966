import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { saveData as _saveData } from "api/homebase.api";
import { SECONDS } from "constants/time";
import { DebouncedFunc, noop, throttle } from "lodash-es";
import { useCallback, useEffect, useRef } from "react";

import { OrchestratorMachineContext } from "./OrchestratorMachine/OrchestratorMachine.types";

type SaveDataCallbackFn = (data: OrchestratorMachineContext) => Promise<void>;
export type DrainFn = DebouncedFunc<() => Promise<void>>;

const QUEUE_MAX_LENGTH = 10;
const DRAIN_MIN_WAIT = 1 * SECONDS;
const DRAIN_MAX_WAIT = 5 * SECONDS;
const RETRY_COUNT = 3;

const useGlobalStateQueue = (apiCallback: SaveDataCallbackFn) => {
  const queueRef = useRef<OrchestratorMachineContext[]>([]);
  const backlogRef = useRef<OrchestratorMachineContext[]>([]);

  const push = useCallback((data: OrchestratorMachineContext) => {
    return queueRef.current.push(data);
  }, []);

  const drain = useCallback(async () => {
    const queue = queueRef.current;
    const backlog = backlogRef.current;

    const data = queue.splice(0, queue.length);
    const backlogData = backlog.length ? backlog : null;
    const dataToSend = backlogData ? backlogData.concat(data) : data;

    if (dataToSend.length > 0) {
      const queueLastElement = dataToSend[dataToSend.length - 1];

      try {
        await apiCallback(queueLastElement);
        if (backlog.length) {
          backlog.splice(0, backlog.length);
        }
      } catch (error) {
        captureException(error);
        if (error?.status !== 412) {
          backlog.push(queueLastElement);
        }
      }
    }
  }, [apiCallback]);

  // Note(mate): For some unknown reason inlining throttle function messed up the types
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDrain = useCallback(throttle(drain, DRAIN_MIN_WAIT), [drain]);

  return {
    drain: throttledDrain,
    push,
  };
};

const usePeriodicDrain = (drain: DrainFn) => {
  const intervalIdRef = useRef<ReturnType<typeof setInterval>>();

  const stop = useCallback(() => {
    const intervalId = intervalIdRef.current;
    if (intervalId !== undefined) {
      clearInterval(intervalId);
    }
  }, []);

  const start = useCallback(() => {
    intervalIdRef.current = setInterval(
      () => drain().catch(captureException),
      DRAIN_MAX_WAIT
    );
  }, [drain]);

  const restart = useCallback(() => {
    stop();
    start();
  }, [start, stop]);

  useEffect(() => {
    restart();
    const onVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        drain().catch(captureException);
        drain.flush().catch(noop);
        stop();
      } else {
        restart();
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [restart, stop, drain]);

  return {
    stop,
    restart,
  };
};

export const useSaveGlobalState = () => {
  const { mutateAsync: saveState, error: saveStateError } = useMutation<
    void,
    Response,
    OrchestratorMachineContext
  >({
    mutationFn: _saveData,
    retry: (failureCount, response) => {
      return (
        failureCount < RETRY_COUNT &&
        response.status !== 412 &&
        response.status !== 401
      );
    },
  });

  const { push: pushToQueue, drain: drainQueue } =
    useGlobalStateQueue(saveState);
  const { restart: drainRestart } = usePeriodicDrain(drainQueue);
  const saveData = useCallback(
    (data: OrchestratorMachineContext) => {
      const queueLength = pushToQueue(data);

      if (queueLength > QUEUE_MAX_LENGTH) {
        drainQueue().catch(captureException);
        drainRestart();
      }
    },
    [pushToQueue, drainQueue, drainRestart]
  );

  return { saveData, saveStateError, drainQueue };
};
