import { ORCHESTRATOR_ACTIONS } from "core/state/global/OrchestratorMachine/constants";
import { useOrchestratorCallback } from "core/state/global/OrchestratorMachine/hooks";
import { EphemeralSidebarType } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const SIDEBAR_QUERY_PARAM = "sidebar";

const allowedEphemeral: EphemeralSidebarType[] = [
  "help",
  "subscribe",
  "bug",
  "suggestion",
];

export const useOpenSidebarByQueryParam = () => {
  const [params] = useSearchParams();

  const handleOpen = useOrchestratorCallback<EphemeralSidebarType>(
    ORCHESTRATOR_ACTIONS.SIDEBAR.OPEN
  );

  useEffect(() => {
    const sidebarType = params.get(SIDEBAR_QUERY_PARAM) as EphemeralSidebarType;
    if (sidebarType && allowedEphemeral.includes(sidebarType)) {
      handleOpen(sidebarType);
    }
  }, [params, handleOpen]);
};
