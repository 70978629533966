// eslint-disable-next-line filenames/no-index
import "./styles/_global.scss";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HomebaseRouter } from "router/Router";

import { setupApp } from "./setupApp";

const container = document.getElementById("root");
const root = createRoot(container);

setupApp().then(() =>
  root.render(
    <StrictMode>
      <HomebaseRouter />
    </StrictMode>
  )
);
