import { useMutation } from "@tanstack/react-query";
import { contactUsRequest } from "api/homebase.api";
import { ContactUs } from "api/models/ContactUs";

export const useContactUs = (type: ContactUs["type"]) => {
  return useMutation({
    mutationKey: [type],
    mutationFn: (data: Omit<ContactUs, "type">) =>
      contactUsRequest({
        email: data.email ? data.email : null,
        name: data.name ? data.name : null,
        message: data.message,
        type,
      }),
  });
};
