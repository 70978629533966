import {
  Alert,
  AlertClasses,
  ButtonBase,
  Dialog,
  DialogClasses,
  Typography,
} from "@mui/material";
import { ReactComponent as AlertIcon } from "assets/icons/snackbar/alert.svg";
import clsx from "clsx";
import { useMobileOnly } from "common/hooks/useMobileOnly";
import { useModalState } from "common/hooks/useModalState";
import { Trans } from "components/shared/Trans/Trans";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./HomeBetaInfo.module.scss";

const classes: Partial<AlertClasses> = { message: styles.message };
const dialogPaper: Partial<DialogClasses> = { paper: styles.paper };

interface Props {
  className?: string;
}

export const HomeBetaInfo: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const isMobile = useMobileOnly();
  const [isOpen, { handleModalOpen, handleModalClose }] = useModalState();
  return (
    <>
      <Alert
        icon={<AlertIcon className={styles.alertIcon} />}
        severity="info"
        className={clsx(styles.alert, className)}
        classes={classes}
      >
        <Typography variant="p" component="p" className={styles.text}>
          {t("Nook is currently in public beta &\nfree for everyone.")}
        </Typography>
        <ButtonBase className={styles.btn} onClick={handleModalOpen}>
          {t("Learn More")}
        </ButtonBase>
      </Alert>
      <Dialog open={isOpen} onClose={handleModalClose} classes={dialogPaper}>
        <Typography variant="h6" component="h6" className={styles.header}>
          {t("About Nook Public Beta")}
        </Typography>
        <div className={styles.content}>
          {isMobile ? (
            <Trans defaults="Nook is currently in public beta and free for everyone.<nl/><nl/><mnl/><mnl/>Upon the official release, all Professional accounts will seamlessly transition to official accounts. These accounts will enjoy a 60-day free trial, with no credit card required.<nl/><nl/><mnl/><mnl/>After the trial period, Professional accounts can subscribe to Nook for <b>$14.99 per month or $149.90 annually.</b><nl/><mnl/>" />
          ) : (
            <Trans defaults="Nook is currently in public beta and free for everyone.<nl/><nl/><mnl/><mnl/>Upon the official release, all Professional accounts will seamlessly transition to official accounts. These accounts will enjoy a 60-day free trial, with no credit card required.<nl/><nl/><mnl/><mnl/>After the trial period, Professional accounts can subscribe to Nook for <nl/><mnl/><b>$14.99 per month or $149.90 annually.</b>" />
          )}
        </div>
        <div className={styles.actions}>
          <ButtonBase onClick={handleModalClose} className={styles.closeBtn}>
            {t("Close")}
          </ButtonBase>
        </div>
      </Dialog>
    </>
  );
};
