import { EARLY_ACCESS_ENABLED } from "constants/env";
import { FC } from "react";
import { Navigate } from "router/Navigate";
import { NOT_AVAILABLE_MOBILE } from "router/routes";

interface Props {
  disabled?: boolean;
  children: JSX.Element;
}

export const IsSmallScreenGuard: FC<Props> = ({ disabled, children }) => {
  if (!disabled && window.innerWidth <= 768 && EARLY_ACCESS_ENABLED) {
    return <Navigate to={NOT_AVAILABLE_MOBILE()} replace />;
  }

  return children;
};
