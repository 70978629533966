import { uuid } from "shared/util/uuid";

import { AppDataV5 } from "./models/AppDataV5";
import { AppDataV6 } from "./models/AppDataV6";

export function mapV5ToV6(data: AppDataV5): AppDataV6 {
  if (data.state.data.property && !data.state.data.property.id) {
    data.state.data.property.id = uuid() as unknown as number;
  }
  return {
    state: {
      version: 6,
      data: {
        properties: [data.state.data],
        activePropertyId: data.state.data.property?.id,
      } as AppDataV6["state"]["data"],
    },
  };
}
