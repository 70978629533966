import { ReactComponent as bath_faucet_controls } from "assets/icons/filters/pro/bathroom_specific/bath_faucet_controls.svg";
import { ReactComponent as bathroom_sink } from "assets/icons/filters/pro/bathroom_specific/bathroom_sink.svg";
import { ReactComponent as bathtub } from "assets/icons/filters/pro/bathroom_specific/bathtub.svg";
import { ReactComponent as shower } from "assets/icons/filters/pro/bathroom_specific/shower.svg";
import { ReactComponent as shower_faucet_controls } from "assets/icons/filters/pro/bathroom_specific/shower_faucet_controls.svg";
import { ReactComponent as sink_faucet } from "assets/icons/filters/pro/bathroom_specific/sink faucet.svg";
import { ReactComponent as toilet } from "assets/icons/filters/pro/bathroom_specific/toilet.svg";
import { ReactComponent as vanity } from "assets/icons/filters/pro/bathroom_specific/vanity.svg";
import { ReactComponent as money1 } from "assets/icons/filters/pro/budget/$.svg";
import { ReactComponent as money2 } from "assets/icons/filters/pro/budget/$$.svg";
import { ReactComponent as money3 } from "assets/icons/filters/pro/budget/$$$.svg";
import { ReactComponent as money4 } from "assets/icons/filters/pro/budget/$$$$.svg";
import beige_fallback from "assets/icons/filters/pro/color/beige.jpg";
import beige from "assets/icons/filters/pro/color/beige.webp";
import black_fallback from "assets/icons/filters/pro/color/black.jpg";
import black from "assets/icons/filters/pro/color/black.webp";
import blue_fallback from "assets/icons/filters/pro/color/blue.jpg";
import blue from "assets/icons/filters/pro/color/blue.webp";
import brown_fallback from "assets/icons/filters/pro/color/brown.jpg";
import brown from "assets/icons/filters/pro/color/brown.webp";
import cream_fallback from "assets/icons/filters/pro/color/cream.jpg";
import cream from "assets/icons/filters/pro/color/cream.webp";
import gold_fallback from "assets/icons/filters/pro/color/gold.jpg";
import gold from "assets/icons/filters/pro/color/gold.webp";
import green_fallback from "assets/icons/filters/pro/color/green.jpg";
import green from "assets/icons/filters/pro/color/green.webp";
import grey_fallback from "assets/icons/filters/pro/color/grey.jpg";
import grey from "assets/icons/filters/pro/color/grey.webp";
import orange_fallback from "assets/icons/filters/pro/color/orange.jpg";
import orange from "assets/icons/filters/pro/color/orange.webp";
import pink_fallback from "assets/icons/filters/pro/color/pink.jpg";
import pink from "assets/icons/filters/pro/color/pink.webp";
import purple_fallback from "assets/icons/filters/pro/color/purple.jpg";
import purple from "assets/icons/filters/pro/color/purple.webp";
import red_fallback from "assets/icons/filters/pro/color/red.jpg";
import red from "assets/icons/filters/pro/color/red.webp";
import silver_fallback from "assets/icons/filters/pro/color/silver.jpg";
import silver from "assets/icons/filters/pro/color/silver.webp";
import turquoise_fallback from "assets/icons/filters/pro/color/turquoise.jpg";
import turquoise from "assets/icons/filters/pro/color/turquoise.webp";
import white_fallback from "assets/icons/filters/pro/color/white.jpg";
import white from "assets/icons/filters/pro/color/white.webp";
import wood_tones_fallback from "assets/icons/filters/pro/color/wood_tones.jpg";
import wood_tones from "assets/icons/filters/pro/color/wood_tones.webp";
import yellow_fallback from "assets/icons/filters/pro/color/yellow.jpg";
import yellow from "assets/icons/filters/pro/color/yellow.webp";
import { ReactComponent as design_style } from "assets/icons/filters/pro/design_styles/design_style.svg";
import broom_fallback from "assets/icons/filters/pro/finish/broom.jpg";
import broom from "assets/icons/filters/pro/finish/broom.webp";
import brushed_fallback from "assets/icons/filters/pro/finish/brushed.jpg";
import brushed from "assets/icons/filters/pro/finish/brushed.webp";
import comb_fallback from "assets/icons/filters/pro/finish/comb.jpg";
import comb from "assets/icons/filters/pro/finish/comb.webp";
import distressed_fallback from "assets/icons/filters/pro/finish/distressed.jpg";
import distressed from "assets/icons/filters/pro/finish/distressed.webp";
import exposed_fallback from "assets/icons/filters/pro/finish/exposed.jpg";
import exposed from "assets/icons/filters/pro/finish/exposed.webp";
import hammered_fallback from "assets/icons/filters/pro/finish/hammered.jpg";
import hammered from "assets/icons/filters/pro/finish/hammered.webp";
import hand_scraped_fallback from "assets/icons/filters/pro/finish/hand_scraped.jpg";
import hand_scraped from "assets/icons/filters/pro/finish/hand_scraped.webp";
import high_gloss_fallback from "assets/icons/filters/pro/finish/high_gloss.jpg";
import high_gloss from "assets/icons/filters/pro/finish/high_gloss.webp";
import matte_fallback from "assets/icons/filters/pro/finish/matte.jpg";
import matte from "assets/icons/filters/pro/finish/matte.webp";
import naturally_rough_fallback from "assets/icons/filters/pro/finish/naturally_rough.jpg";
import naturally_rough from "assets/icons/filters/pro/finish/naturally_rough.webp";
import orange_peel_fallback from "assets/icons/filters/pro/finish/orange_peel.jpg";
import orange_peel from "assets/icons/filters/pro/finish/orange_peel.webp";
import polished_fallback from "assets/icons/filters/pro/finish/polished.jpg";
import polished from "assets/icons/filters/pro/finish/polished.webp";
import popcorn_fallback from "assets/icons/filters/pro/finish/popcorn.jpg";
import popcorn from "assets/icons/filters/pro/finish/popcorn.webp";
import salt_fallback from "assets/icons/filters/pro/finish/salt.jpg";
import salt from "assets/icons/filters/pro/finish/salt.webp";
import sand_swirl_fallback from "assets/icons/filters/pro/finish/sand_swirl.jpg";
import sand_swirl from "assets/icons/filters/pro/finish/sand_swirl.webp";
import sandblasted_fallback from "assets/icons/filters/pro/finish/sandblasted.jpg";
import sandblasted from "assets/icons/filters/pro/finish/sandblasted.webp";
import satin_fallback from "assets/icons/filters/pro/finish/satin.jpg";
import satin from "assets/icons/filters/pro/finish/satin.webp";
import semi_gloss_fallback from "assets/icons/filters/pro/finish/semi_gloss.jpg";
import semi_gloss from "assets/icons/filters/pro/finish/semi_gloss.webp";
import slap_brush_fallback from "assets/icons/filters/pro/finish/slap_brush.jpg";
import slap_brush from "assets/icons/filters/pro/finish/slap_brush.webp";
import smooth_fallback from "assets/icons/filters/pro/finish/smooth.jpg";
import smooth from "assets/icons/filters/pro/finish/smooth.webp";
import stamped_fallback from "assets/icons/filters/pro/finish/stamped.jpg";
import stamped from "assets/icons/filters/pro/finish/stamped.webp";
import stenciled_fallback from "assets/icons/filters/pro/finish/stenciled.jpg";
import stenciled from "assets/icons/filters/pro/finish/stenciled.webp";
import textured_fallback from "assets/icons/filters/pro/finish/textured.jpg";
import textured from "assets/icons/filters/pro/finish/textured.webp";
import wire_brushed_fallback from "assets/icons/filters/pro/finish/wire_brushed.jpg";
import wire_brushed from "assets/icons/filters/pro/finish/wire_brushed.webp";
import { ReactComponent as appliances } from "assets/icons/filters/pro/kitchen_specific/appliances.svg";
import { ReactComponent as backsplash } from "assets/icons/filters/pro/kitchen_specific/backsplash.svg";
import { ReactComponent as cabinets } from "assets/icons/filters/pro/kitchen_specific/cabinets.svg";
import { ReactComponent as countertop } from "assets/icons/filters/pro/kitchen_specific/countertop.svg";
import { ReactComponent as faucets } from "assets/icons/filters/pro/kitchen_specific/faucets.svg";
import { ReactComponent as sink } from "assets/icons/filters/pro/kitchen_specific/sink.svg";
import flecked_speckled_fallback from "assets/icons/filters/pro/look/flecked_speckled.jpg";
import flecked_speckled from "assets/icons/filters/pro/look/flecked_speckled.webp";
import mixed_color_fallback from "assets/icons/filters/pro/look/mixed_color.jpg";
import mixed_color from "assets/icons/filters/pro/look/mixed_color.webp";
import mixed_shade_fallback from "assets/icons/filters/pro/look/mixed_shade.jpg";
import mixed_shade from "assets/icons/filters/pro/look/mixed_shade.webp";
import mottled_fallback from "assets/icons/filters/pro/look/mottled.jpg";
import mottled from "assets/icons/filters/pro/look/mottled.webp";
import multi_color_fallback from "assets/icons/filters/pro/look/multi_color.jpg";
import multi_color from "assets/icons/filters/pro/look/multi_color.webp";
import patterned_fallback from "assets/icons/filters/pro/look/patterned.jpg";
import patterned from "assets/icons/filters/pro/look/patterned.webp";
import solid_color_fallback from "assets/icons/filters/pro/look/solid_color.jpg";
import solid_color from "assets/icons/filters/pro/look/solid_color.webp";
import swirled_fallback from "assets/icons/filters/pro/look/swirled.jpg";
import swirled from "assets/icons/filters/pro/look/swirled.webp";
import terrazzo_fallback from "assets/icons/filters/pro/look/terrazzo.jpg";
import terrazzo from "assets/icons/filters/pro/look/terrazzo.webp";
import translucent_color_fallback from "assets/icons/filters/pro/look/translucent_color.jpg";
import translucent_color from "assets/icons/filters/pro/look/translucent_color.webp";
import veined_fallback from "assets/icons/filters/pro/look/veined.jpg";
import veined from "assets/icons/filters/pro/look/veined.webp";
import brick_fallback from "assets/icons/filters/pro/material/brick.jpg";
import brick from "assets/icons/filters/pro/material/brick.webp";
import carpet_fallback from "assets/icons/filters/pro/material/carpet.jpg";
import carpet from "assets/icons/filters/pro/material/carpet.webp";
import ceramic_fallback from "assets/icons/filters/pro/material/ceramic.jpg";
import ceramic from "assets/icons/filters/pro/material/ceramic.webp";
import concrete_fallback from "assets/icons/filters/pro/material/concrete.jpg";
import concrete from "assets/icons/filters/pro/material/concrete.webp";
import drywall_fallback from "assets/icons/filters/pro/material/drywall.jpg";
import drywall from "assets/icons/filters/pro/material/drywall.webp";
import fabric_fallback from "assets/icons/filters/pro/material/fabric.jpg";
import fabric from "assets/icons/filters/pro/material/fabric.webp";
import fiberglass_fallback from "assets/icons/filters/pro/material/fiberglass.jpg";
import fiberglass from "assets/icons/filters/pro/material/fiberglass.webp";
import fireclay_fallback from "assets/icons/filters/pro/material/fireclay.jpg";
import fireclay from "assets/icons/filters/pro/material/fireclay.webp";
import glass_fallback from "assets/icons/filters/pro/material/glass.jpg";
import glass from "assets/icons/filters/pro/material/glass.webp";
import metal_fallback from "assets/icons/filters/pro/material/metal.jpg";
import metal from "assets/icons/filters/pro/material/metal.webp";
import plastic_fallback from "assets/icons/filters/pro/material/plastic.jpg";
import plastic from "assets/icons/filters/pro/material/plastic.webp";
import porcelain_fallback from "assets/icons/filters/pro/material/porcelain.jpg";
import porcelain from "assets/icons/filters/pro/material/porcelain.webp";
import stone_fallback from "assets/icons/filters/pro/material/stone.jpg";
import stone from "assets/icons/filters/pro/material/stone.webp";
import terracotta_fallback from "assets/icons/filters/pro/material/terracotta.jpg";
import terracotta from "assets/icons/filters/pro/material/terracotta.webp";
import tile_fallback from "assets/icons/filters/pro/material/tile.jpg";
import tile from "assets/icons/filters/pro/material/tile.webp";
import vinyl_fallback from "assets/icons/filters/pro/material/vinyl.jpg";
import vinyl from "assets/icons/filters/pro/material/vinyl.webp";
import wood_fallback from "assets/icons/filters/pro/material/wood.jpg";
import wood from "assets/icons/filters/pro/material/wood.webp";
import { ReactComponent as best_seller } from "assets/icons/filters/pro/relevance/best_seller.svg";
import { ReactComponent as budget_friendly } from "assets/icons/filters/pro/relevance/budget_friendly.svg";
import { ReactComponent as eco_friendly } from "assets/icons/filters/pro/relevance/eco_friendly.svg";
import { ReactComponent as limited_edition } from "assets/icons/filters/pro/relevance/limited_edition.svg";
import { ReactComponent as luxury } from "assets/icons/filters/pro/relevance/luxury.svg";
import { ReactComponent as new_release } from "assets/icons/filters/pro/relevance/new_release.svg";
import { ReactComponent as diamond_tile } from "assets/icons/filters/pro/shape/diamond_tile.svg";
import { ReactComponent as dome } from "assets/icons/filters/pro/shape/dome.svg";
import { ReactComponent as hexagon_tile } from "assets/icons/filters/pro/shape/hexagon_tile.svg";
import { ReactComponent as irregular_shape } from "assets/icons/filters/pro/shape/irregular_shape.svg";
import { ReactComponent as leaf_tile } from "assets/icons/filters/pro/shape/leaf_tile.svg";
import { ReactComponent as mosaic_tile } from "assets/icons/filters/pro/shape/mosaic_tile.svg";
import { ReactComponent as oval } from "assets/icons/filters/pro/shape/oval.svg";
import { ReactComponent as pebble_tile } from "assets/icons/filters/pro/shape/pebble_tile.svg";
import { ReactComponent as rectangle_tile } from "assets/icons/filters/pro/shape/rectangle_tile.svg";
import { ReactComponent as round_tile } from "assets/icons/filters/pro/shape/round_tile.svg";
import { ReactComponent as slab } from "assets/icons/filters/pro/shape/slab.svg";
import { ReactComponent as square_tile } from "assets/icons/filters/pro/shape/square_tile.svg";
import { ReactComponent as triangle_tile } from "assets/icons/filters/pro/shape/triangle_tile.svg";

const ProFiltersIconMapping = {
  bathtub: bathtub,
  bath_faucet_controls: bath_faucet_controls,
  shower: shower,
  shower_faucet_controls: shower_faucet_controls,
  sink_faucet: sink_faucet,
  sink: sink,
  toilet: toilet,
  vanity: vanity,
  money4: money4,
  money3: money3,
  money2: money2,
  money1: money1,
  beige: { src: beige, fallbackSrc: beige_fallback },
  black: { src: black, fallbackSrc: black_fallback },
  blue: { src: blue, fallbackSrc: blue_fallback },
  brown: { src: brown, fallbackSrc: brown_fallback },
  cream: { src: cream, fallbackSrc: cream_fallback },
  gold: { src: gold, fallbackSrc: gold_fallback },
  green: { src: green, fallbackSrc: green_fallback },
  grey: { src: grey, fallbackSrc: grey_fallback },
  orange: { src: orange, fallbackSrc: orange_fallback },
  pink: { src: pink, fallbackSrc: pink_fallback },
  purple: { src: purple, fallbackSrc: purple_fallback },
  red: { src: red, fallbackSrc: red_fallback },
  silver: { src: silver, fallbackSrc: silver_fallback },
  turquoise: { src: turquoise, fallbackSrc: turquoise_fallback },
  white: { src: white, fallbackSrc: white_fallback },
  wood_tones: { src: wood_tones, fallbackSrc: wood_tones_fallback },
  yellow: { src: yellow, fallbackSrc: yellow_fallback },
  design_style: design_style,
  broom: { src: broom, fallbackSrc: broom_fallback },
  brushed: { src: brushed, fallbackSrc: brushed_fallback },
  comb: { src: comb, fallbackSrc: comb_fallback },
  distressed: { src: distressed, fallbackSrc: distressed_fallback },
  exposed: { src: exposed, fallbackSrc: exposed_fallback },
  hammered: { src: hammered, fallbackSrc: hammered_fallback },
  hand_scraped: { src: hand_scraped, fallbackSrc: hand_scraped_fallback },
  high_gloss: { src: high_gloss, fallbackSrc: high_gloss_fallback },
  matte: { src: matte, fallbackSrc: matte_fallback },
  naturally_rough: {
    src: naturally_rough,
    fallbackSrc: naturally_rough_fallback,
  },
  orange_peel: { src: orange_peel, fallbackSrc: orange_peel_fallback },
  polished: { src: polished, fallbackSrc: polished_fallback },
  popcorn: { src: popcorn, fallbackSrc: popcorn_fallback },
  salt: { src: salt, fallbackSrc: salt_fallback },
  sand_swirl: { src: sand_swirl, fallbackSrc: sand_swirl_fallback },
  sandblasted: { src: sandblasted, fallbackSrc: sandblasted_fallback },
  satin: { src: satin, fallbackSrc: satin_fallback },
  semi_gloss: { src: semi_gloss, fallbackSrc: semi_gloss_fallback },
  slap_brush: { src: slap_brush, fallbackSrc: slap_brush_fallback },
  smooth: { src: smooth, fallbackSrc: smooth_fallback },
  stamped: { src: stamped, fallbackSrc: stamped_fallback },
  stenciled: { src: stenciled, fallbackSrc: stenciled_fallback },
  textured: { src: textured, fallbackSrc: textured_fallback },
  wire_brushed: { src: wire_brushed, fallbackSrc: wire_brushed_fallback },
  appliances: appliances,
  backsplash: backsplash,
  cabinets: cabinets,
  countertop: countertop,
  faucets: faucets,
  bathroom_sink: bathroom_sink,
  flecked_speckled: {
    src: flecked_speckled,
    fallbackSrc: flecked_speckled_fallback,
  },
  mixed_color: { src: mixed_color, fallbackSrc: mixed_color_fallback },
  mixed_shade: { src: mixed_shade, fallbackSrc: mixed_shade_fallback },
  mottled: { src: mottled, fallbackSrc: mottled_fallback },
  multi_color: { src: multi_color, fallbackSrc: multi_color_fallback },
  patterned: { src: patterned, fallbackSrc: patterned_fallback },
  solid_color: { src: solid_color, fallbackSrc: solid_color_fallback },
  swirled: { src: swirled, fallbackSrc: swirled_fallback },
  terrazzo: { src: terrazzo, fallbackSrc: terrazzo_fallback },
  translucent_color: {
    src: translucent_color,
    fallbackSrc: translucent_color_fallback,
  },
  veined: { src: veined, fallbackSrc: veined_fallback },
  brick: { src: brick, fallbackSrc: brick_fallback },
  carpet: { src: carpet, fallbackSrc: carpet_fallback },
  ceramic: { src: ceramic, fallbackSrc: ceramic_fallback },
  concrete: { src: concrete, fallbackSrc: concrete_fallback },
  drywall: { src: drywall, fallbackSrc: drywall_fallback },
  fabric: { src: fabric, fallbackSrc: fabric_fallback },
  fiberglass: { src: fiberglass, fallbackSrc: fiberglass_fallback },
  fireclay: { src: fireclay, fallbackSrc: fireclay_fallback },
  glass: { src: glass, fallbackSrc: glass_fallback },
  metal: { src: metal, fallbackSrc: metal_fallback },
  plastic: { src: plastic, fallbackSrc: plastic_fallback },
  porcelain: { src: porcelain, fallbackSrc: porcelain_fallback },
  stone: { src: stone, fallbackSrc: stone_fallback },
  terracotta: { src: terracotta, fallbackSrc: terracotta_fallback },
  tile: { src: tile, fallbackSrc: tile_fallback },
  vinyl: { src: vinyl, fallbackSrc: vinyl_fallback },
  wood: { src: wood, fallbackSrc: wood_fallback },
  best_seller: best_seller,
  budget_friendly: budget_friendly,
  eco_friendly: eco_friendly,
  limited_edition: limited_edition,
  luxury: luxury,
  new_release: new_release,
  diamond: diamond_tile,
  dome: dome,
  hexagon: hexagon_tile,
  irregular_shape: irregular_shape,
  leaf: leaf_tile,
  mosaic: mosaic_tile,
  oval: oval,
  pebble: pebble_tile,
  rectangle: rectangle_tile,
  round: round_tile,
  slab: slab,
  square: square_tile,
  triangle: triangle_tile,
} as const;

export const getProFilterIcon = (name: string) => {
  return (
    ProFiltersIconMapping[name as keyof typeof ProFiltersIconMapping] ||
    design_style
  );
};
