import { useSelector } from "@xstate/react";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import type { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";

export const orchestratorContextSelector = (state: {
  context: OrchestratorMachineContext;
}) => state.context;

export const useAppData = () => {
  const { orchestrator } = useGlobalState();
  return useSelector(orchestrator, orchestratorContextSelector);
};
