export enum OutletTypes {
  TWO_FORTY_V = "TWO_FORTY_V",
  WEATHER_RESISTANT = "WEATHER_RESISTANT",
  SINGLE = "SINGLE",
  DOUBLE = "DOUBLE",
}

export enum OutletIconStateTypes {
  SINGLE_FILLED = "SINGLE_FILLED",
  DOUBLE_FIRST_FILLED = "DOUBLE_FIRST_FILLED",
  DOUBLE_SECOND_FILLED = "DOUBLE_SECOND_FILLED",
  DOUBLE_BOTH_FILLED = "DOUBLE_BOTH_FILLED",
  SINGLE_FILLED_DISABLED = "SINGLE_FILLED_DISABLED",
  DOUBLE_FIRST_FILLED_DISABLED = "DOUBLE_FIRST_FILLED_DISABLED",
  DOUBLE_SECOND_FILLED_DISABLED = "DOUBLE_SECOND_FILLED_DISABLED",
  DOUBLE_BOTH_FILLED_DISABLED = "DOUBLE_BOTH_FILLED_DISABLED",
  TWO_FORTY_V_DISABLED = "TWO_FORTY_V_DISABLED",
  WEATHER_RESISTANT_DISABLED = "WEATHER_RESISTANT_DISABLED",
}
