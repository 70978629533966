import {
  COMMON_TAG_CATEGORIES,
  DEFAULT_IMAGE,
  OTHER_TAGS,
} from "pages/Media/constants";
import { Tag, Tags, Uploads } from "pages/Media/types";

import { InitialMediaMachineContext, MediaMachineContext } from "./models";

export const PROPERTY_ID_TAG_CATEGORY = "property";
export const INSPIRATION_TAG: Tag = {
  name: OTHER_TAGS.INSPIRATION,
  category: COMMON_TAG_CATEGORIES.OTHER,
};
export const GET_EDUCATED_TAG_IMAGE = "get-educated";
// TODO: Refactor this when general approach for uploads will be implemented
// possible solution will be to apply tag `uploads` to all images uploaded by the user
// so we should change the logic according to this change. E.g remove this tag and change it to the newly created `uploads`
// https://tree.taiga.io/project/homebase-engineering/us/656
export const HIDDEN_FROM_ALL_PHOTOS_TAG = "hidden_from_all_photos";
export const VERIFICATION_DOCUMENTS_TAG_CATEGORY = "verification_documents";
export const INSPIRATION_VENDOR_TAG = "author";
export const INSPIRATION_VENDOR_ATTRIBUTION_TAG = "author_link";
const HIDE_ALL_INSPIRATION_IMAGES_TAG = `-${INSPIRATION_TAG.name}`;
const SHOW_ONLY_UPLOADED_IMAGES_TAG = `-${HIDDEN_FROM_ALL_PHOTOS_TAG}`;

export const EXCLUDED_COMMON_TAGS_FROM_SHOWING = new Set<string>([
  COMMON_TAG_CATEGORIES.SHARED_WITH_ME,
]);
export const DESCRIPTION_TAG = "description";

export const HIDDEN_TAG_CATEGORIES = new Set([
  PROPERTY_ID_TAG_CATEGORY,
  VERIFICATION_DOCUMENTS_TAG_CATEGORY,
  HIDDEN_FROM_ALL_PHOTOS_TAG,
  INSPIRATION_VENDOR_TAG,
  INSPIRATION_VENDOR_ATTRIBUTION_TAG,
  HIDE_ALL_INSPIRATION_IMAGES_TAG,
  SHOW_ONLY_UPLOADED_IMAGES_TAG,
  GET_EDUCATED_TAG_IMAGE,
]);

export const MEDIA_QUERY_KEY_PREFIX = "media";
export const emptyTags: Tags = {
  build_status: {},
  improvement_projects: {},
  other: {},
  planning_categories: {},
  rooms_and_spaces: {},
  shared_with_me: {},
  shape: {},
  bathroom_specific: {},
  budget: {},
  color: {},
  design_style: {},
  finish: {},
  kitchen_specific: {},
  look: {},
  material: {},
  shared: {},
  relevance: {},
};

export const UPLOADS_DEFAULT: Uploads = {
  count: 0,
  next: undefined,
  previous: undefined,
  results: [],
};

export const ALLOWED_TAG_RANK_CATEGORIES = [
  COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES,
  COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES,
];

export const SUB_RANK_MULTIPLIER = 100;

export const getMediaMachineContext = (
  initialContext: InitialMediaMachineContext
): MediaMachineContext => {
  return {
    propertyId: initialContext.propertyId,
    filters: initialContext?.filters || [],
    initialUnselectableFilters: initialContext?.initialUnselectableFilter || [],
    collection: initialContext.collection,
    shared: initialContext.shared,
    inspiration: initialContext.inspiration,
    buildStatusFilters: [],
    tags: {
      [COMMON_TAG_CATEGORIES.IMPROVEMENT_PROJECTS]: {},
      [COMMON_TAG_CATEGORIES.BUDGET]: {},
      [COMMON_TAG_CATEGORIES.BATHROOM_SPECIFIC]: {},
      [COMMON_TAG_CATEGORIES.KITCHEN_SPECIFIC]: {},
      [COMMON_TAG_CATEGORIES.RELEVANCE]: {},
      [COMMON_TAG_CATEGORIES.COLOR]: {},
      [COMMON_TAG_CATEGORIES.MATERIAL]: {},
      [COMMON_TAG_CATEGORIES.DESIGN_STYLE]: {},
      [COMMON_TAG_CATEGORIES.LOOK]: {},
      [COMMON_TAG_CATEGORIES.SHAPE]: {},
      [COMMON_TAG_CATEGORIES.FINISH]: {},
      [COMMON_TAG_CATEGORIES.BUILD_STATUS]: {},
      [COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES]: {},
      [COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES]: {},
      [COMMON_TAG_CATEGORIES.SHARED_WITH_ME]: {},
      [COMMON_TAG_CATEGORIES.OTHER]: {},
      [COMMON_TAG_CATEGORIES.HOMEOWNER]: {},
    },
    addedTags: {
      images: [],
      tags: [],
      tagsCategory: {},
    },
    removedTags: {
      images: [],
      tags: [],
      tagsCategory: {},
    },
    ordering: {
      type: "-timestamp",
      rankCategory: undefined,
    },
    uploads: {
      ...UPLOADS_DEFAULT,
    },
    propertyCardImage: null,
    architecturalDrawing: null,
    buildStatusUploads: {
      before: { ...UPLOADS_DEFAULT },
      during: { ...UPLOADS_DEFAULT },
      after: { ...UPLOADS_DEFAULT },
    },
    multipleSelectUploads: [],
    selectedImage: DEFAULT_IMAGE,
    initialFetch: true,
    uploadStatus: {
      uploaded: { results: [] },
      status: {},
      errors: [],
    },
    uploadCount: 0,
    coverPhotos: {},
    originalCoverPhotos: initialContext.coverPhotos,
    error: false,
    stateTransitionLoad: false,
    deleteUploadStatus: "IDLE",
    hipsIds: [],
    stateAddRecentUploadsTransitionLoad: false,
    _hips: initialContext._hips ?? [],
    _rooms: initialContext._rooms ?? [],
    _uploadsPageData: [],
  };
};
