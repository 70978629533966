import { useQuery } from "@tanstack/react-query";
import { reactQueryDefaultRetry } from "api/HBQueryClient";
import { fetchBuildVersion } from "api/homebase.api";
import { NewCodeAvailableNotification } from "components/NewCodeAvailableNotification/NewCodeAvailableNotification";
import { BUILD_VERSION } from "constants/env";
import { MINUTES } from "constants/time";
import { FC, PropsWithChildren } from "react";

const REFETCH_INTERVAL = 5 * MINUTES;

export const GlobalGuards: FC<PropsWithChildren> = ({ children }) => {
  const { data: appBuildVersion } = useQuery({
    queryKey: ["app_health"],
    queryFn: fetchBuildVersion,
    retry: reactQueryDefaultRetry,
    refetchInterval: REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
  });

  const isCodeOutdated = appBuildVersion && appBuildVersion !== BUILD_VERSION;
  return (
    <>
      {isCodeOutdated && <NewCodeAvailableNotification />}
      {children}
    </>
  );
};
