import { transform } from "api/utils";

import { AppDataV13 } from "./models/AppDataV13";
import { AppDataV14 } from "./models/AppDataV14";

export function mapV13ToV14(data: AppDataV13): AppDataV14 {
  return transform(data, (result) => {
    result.state.version = 14;
    result.state.data.state = {
      activePropertyId: data.state.data.activePropertyId,
      getEducated: data.state.data.getEducated,
      hasSeenHipTour: data.state.data.hasSeenHipTour,
    };
  });
}
