import { uniqBy } from "lodash-es";

import { GroupedMap } from "./listUtils.types";

export const getUniqueValuesBy = <T = unknown, RT = T>(
  list: Array<T>,
  mapFn?: (value: T, index: number, list: Array<T>) => RT
): Array<RT> => {
  const mapper =
    typeof mapFn === "function" ? mapFn : (value: T) => value as unknown as RT;

  const mapped = list.map(mapper);
  const uniq = new Set(mapped);

  return Array.from(uniq);
};

export const mergeByKey = <
  T extends object = object,
  Key extends keyof T = keyof T
>(
  list: Array<T>,
  listToMerge: Array<T>,
  key: Key | "id" = "id"
): Array<T> => {
  return uniqBy([...list, ...listToMerge], key);
};

export const getGroupedMap = <T = unknown>(
  list: Array<T>,
  getFn: (value: T) => string
): GroupedMap<T> => {
  const result: GroupedMap<T> = {};

  for (const value of list) {
    const key = getFn(value);

    if (result[key]) {
      result[key].push(value);
    } else {
      result[key] = [value];
    }
  }

  return result;
};

export const getMapFromList = <V, T extends string = string>(
  list: Array<T>,
  getValue?: (item: T) => V,
  getKey?: (item: T) => T
): Record<T, V> => {
  const result: Partial<Record<T, V>> = {};
  const getValueFn = typeof getValue === "function" ? getValue : (item) => item;
  const getKeyFn = typeof getKey === "function" ? getKey : (item) => item;

  for (const key of list) {
    result[getKeyFn(key)] = getValueFn(key);
  }

  return result as Record<T, V>;
};

export const counterBy = <T = unknown, K extends string = string>(
  list: Array<T>,
  getKeyFn?: (item: T) => K
): Partial<Record<K, number>> => {
  const map: Partial<Record<K, number>> = {};
  const getKey =
    typeof getKeyFn === "function" ? getKeyFn : (item: T) => String(item);

  for (const item of list) {
    const key = getKey(item);

    if (!key) {
      continue;
    }

    if (map[key]) {
      map[key] = map[key] + 1;
    } else {
      map[key] = 1;
    }
  }

  return map;
};

export const getOrderedMap = <T extends string = string>(
  list: Array<T>,
  keepOriginalIndex?: Boolean
): Record<T, number> => {
  const result: Partial<Record<T, number>> = {};

  for (let i = 0; i < list.length; i++) {
    result[list[i]] = keepOriginalIndex ? i : i + 1;
  }

  return result as Record<T, number>;
};

export const getOrderedMapFrom = <T extends string = string, V = unknown>(
  map: Partial<Record<T, V>>,
  keys: Array<T>
) => {
  return keys.reduce((result, key) => {
    const value = map[key];
    if (value) {
      result[key] = value;
    }
    return result;
  }, {} as typeof map);
};

export const moveElementAtIndex = <T>(
  array: Array<T>,
  at: number,
  to: number
) => {
  const cArray = [...array];
  const temp = cArray[at];
  cArray[at] = cArray[to];
  cArray[to] = temp;
  return cArray;
};
