import { captureException, setUser } from "@sentry/react";
import { isFirefox } from "common/utils/isFirefox";
import { API_BASE, IS_STAGING } from "constants/env";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import STORAGE_KEYS from "constants/storageKeys.json";
import { getMemory, getTokenStore } from "core/persistence";
import {
  createRestoreOrchestratorMachineContext,
  getOrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/actions.utils";
import {
  OrchestratorMachineContext,
  OrchestratorPropertyContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { pick } from "lodash-es";
import { Ordering } from "pages/Media/components/MediaHeader/MediaHeader.types";
import { propertyIdTagFor } from "pages/Media/machine/utils";
import { Tag } from "pages/Media/types";

import { components } from "./backend";
import { DATA_VERSION, PROPERTIES_VERSION } from "./constants";
import { migrateData } from "./migrations";
import { AlbumsOrdering } from "./models/AlbumsOrdering";
import { AppDataProperty } from "./models/AppData";
import { AskCollectionShare } from "./models/AskCollectionShare";
import { Business } from "./models/Business";
import { Collection } from "./models/Collection";
import { CollectionShare } from "./models/CollectionShare";
import { ContactUs } from "./models/ContactUs";
import { CreateBusiness } from "./models/CreateBusiness";
import { CreateCollection } from "./models/CreateCollection";
import { CreateCollectionShare } from "./models/CreateCollectionShare";
import {
  CreateReaction,
  CreateReactionResponse,
} from "./models/CreateReaction";
import { EarlyAccessFormData } from "./models/EarlyAccess";
import { GetCollectionShare } from "./models/GetCollectionShare";
import { GetPricesResponse } from "./models/GetPricesResponse";
import { InspirationPageSize } from "./models/Inspiration";
import { Paginated } from "./models/Paginated";
import { PaginatedSharedDataList } from "./models/PaginatedSharedDataList";
import { PatchedBusiness } from "./models/PatchedBusiness";
import { PatchedUpdateCollectionShare } from "./models/PatchedUpdateCollectionShare";
import { PatchedUpdateReaction } from "./models/PatchedUpdateReaction";
import { Property } from "./models/Property";
import { PropertyBasic } from "./models/PropertyBasic";
import { RespondCollectionShare } from "./models/RespondCollectionShare";
import { SaveSharedUpload } from "./models/SaveSharedUpload";
import { SentCollectionShare } from "./models/SentCollectionShare";
import { SharedCollectionType } from "./models/SharedCollectionType";
import { SharedData } from "./models/SharedData";
import { SharedDataCreate } from "./models/SharedDataCreate";
import { SharedDataSend } from "./models/SharedDataSend";
import { UnlinkedUploads } from "./models/UnlinkedUploads";
import { ZoomLevel } from "./models/ZoomLevel";
import { pricesData } from "./static/getPricesData";
import {
  executePoll,
  fetchRequest,
  fetchWithAuth,
  ResponseWrapper,
} from "./utils";

type TagAndQuery = string;
type TagOrQuery = `${TagAndQuery},${TagAndQuery}`;
type TagQuery = TagAndQuery | TagOrQuery;
type SentryUser = Pick<schemas["User"], "id" | "email" | "full_name">;

export type schemas = components["schemas"];

const memory = getMemory();

const setEpoch = (response: Response) => {
  const epoch = response.headers.get("x-epoch");
  if (epoch) {
    memory.setStringSync(STORAGE_KEYS.EPOCH, epoch);
  }
};

const getEpoch = () => memory.getStringSync(STORAGE_KEYS.EPOCH);

const setSentryUser = (user: null | SentryUser) => {
  if (!IS_STAGING) {
    return;
  }
  setUser(
    user === null
      ? null
      : {
          id: user.id,
          email: user.email,
          username: user.full_name,
        }
  );
};

const commonPostRequestInit: Partial<RequestInit> = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

const getSharedQueryString = (
  shared: SharedCollectionType,
  firstQueryParam = false
) => {
  const sign = firstQueryParam ? "?" : "&";
  return shared ? `${sign}shared=${shared}` : "";
};

const getPropertyContextQuery = (propertyId?: Property["id"]) => {
  const query = new URLSearchParams();

  if (propertyId) {
    const tag = propertyIdTagFor(propertyId);
    query.set("context", tag);
  }

  return query;
};

type PropertyTransferArgs = Omit<
  schemas["CreateTransfer"],
  "state" | "property" | "id"
> & {
  context: OrchestratorPropertyContext;
};

export const createPropertyTransfer = async (args: PropertyTransferArgs) => {
  const { context, ...rest } = args;

  const state: AppDataProperty = {
    version: PROPERTIES_VERSION,
    data: context,
  };

  const body: Omit<schemas["CreateTransfer"], "id"> = {
    state: { ...state },
    property: context.property.id as number,
    ...rest,
  };

  const response = await fetchWithAuth<schemas["CreateTransfer"]>({
    path: "/transfers/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });

  if (response.status !== 201) {
    throw response;
  }
  return response.content;
};

export const respondPropertyTransfer = async (
  key: string,
  accepted: boolean
) => {
  const body: schemas["RespondTransfer"] = {
    accepted,
  };

  const response = await fetchRequest(
    `${API_BASE}/transfers/response/${key}/`,
    {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    }
  );

  if (response.status !== 200) {
    throw response;
  }
};

export const getPropertyTransfer = async (
  key: string
): Promise<schemas["GetTransfer"]> => {
  const response = await fetchRequest(`${API_BASE}/transfers/response/${key}/`);

  if (response.status !== 200) {
    throw response;
  }

  return response.json();
};

export const getPropertyTransfers = async () => {
  const response = await fetchWithAuth<schemas["PaginatedListTransferList"]>({
    path: "/transfers/",
  });

  if (response.status !== 200) {
    throw response;
  }

  return response.content;
};

export const getNextPropertyTransfers = (path: string) => {
  return fetchWithAuth<schemas["PaginatedListTransferList"]>({
    path,
    defaultValue: {
      content: {
        count: 0,
        results: [],
      },
      status: 200,
      headers: undefined,
    },
  });
};

export const getProperties = (ids: number[]) => {
  return fetchWithAuth<Paginated<PropertyBasic>>({
    path: `/properties/?id=${ids.join("&id=")}`,
  });
};

export const leaveSharedAlbum = (id: number) => {
  return fetchWithAuth({
    path: `/uploads/shares/${id}/leave/`,
    init: {
      ...commonPostRequestInit,
    },
  });
};

export const rejoinSharedAlbum = (id: number) => {
  return fetchWithAuth({
    path: `/uploads/shares/${id}/rejoin/`,
    init: {
      ...commonPostRequestInit,
    },
  });
};

export const cancelPropertyTransfer = async (id: number) => {
  const response = await fetchWithAuth<void>({
    path: `/transfers/${id}/`,
    init: {
      method: "DELETE",
    },
  });

  if (response.status !== 204) {
    throw response;
  }
};

export const createPropertyClaimSupportTicket = async (
  propertyId: Property["id"],
  email: string
) => {
  const body: schemas["Contact"] = {
    requestor: email,
    category: "claim",
    message: `${propertyId}`,
  };

  const response = await fetchRequest(`${API_BASE}/contact/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status !== 201) {
    throw response;
  }
};

export const createIncorrectPropertyImageSupportTicket = async (
  propertyId: Property["id"]
) => {
  const body: schemas["Contact"] = {
    category: "rooftop",
    message: `${propertyId}`,
  };

  const response = await fetchRequest(`${API_BASE}/contact/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status !== 201) {
    throw response;
  }
};

export const checkIsPropertyClaimed = async (propertyId: Property["id"]) => {
  let response: ResponseWrapper<undefined>;

  try {
    response = await fetchWithAuth({
      path: `/properties/claim/${propertyId}/`,
      init: commonPostRequestInit,
    });
  } catch (e) {
    response = e;
  }

  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 404
  ) {
    return { claimed: false };
  } else if (response.status === 409) {
    return { claimed: true };
  } else {
    throw response;
  }
};

export const getPrices = (params: {
  categories: GuideItemTypeCategory[];
}): Promise<GetPricesResponse> => {
  const result: GetPricesResponse = {
    labor: {
      categories: pick(pricesData.labor.categories, params.categories),
      types: pick(pricesData.labor.types, params.categories),
    },
    items: {
      categories: pick(pricesData.items.categories, params.categories),
      types: pick(pricesData.items.types, params.categories),
      subtypes: pick(pricesData.items.subtypes, params.categories),
    },
  };

  return Promise.resolve(result);
};

export const getAddresses = async (address: string) => {
  const response = await fetchWithAuth<schemas["Address"]>({
    path: `/properties/addresses/${encodeURIComponent(address)}.json`,
  });

  if (response.status !== 200) {
    throw response;
  }

  return response.content;
};

const contextToAppData = (
  data: OrchestratorMachineContext
): schemas["AppDataUpdate"] => ({
  state: {
    version: DATA_VERSION,
    data: data.state,
  },
  properties: Object.fromEntries(
    data.properties.map((it) => {
      const key = it.property.id;
      const value = { version: PROPERTIES_VERSION, data: it };
      return [key, value];
    })
  ),
});

export const fetchData = async (): Promise<OrchestratorMachineContext> => {
  const tokenStore = getTokenStore();
  const token = tokenStore.getToken();
  if (!token) {
    return null;
  }

  const response = await fetchRequest(`${API_BASE}/appdata/`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });

  if (response.status !== 200) {
    throw response;
  }

  setEpoch(response);

  const responseData = await response.json();

  const { data } = migrateData(responseData);

  const appData: OrchestratorMachineContext = {
    state: data.state.data,
    properties: Object.values(data.properties).map((it) => ({
      ...it.data,
      property: {
        ...it.data.property,
        locked: it.locked,
      },
    })),
  };

  return createRestoreOrchestratorMachineContext(
    appData,
    getOrchestratorMachineContext().state
  );
};

export const getSharedPlans = (context: string) => {
  return fetchWithAuth<PaginatedSharedDataList>({
    path: `/appdata/shared/?context=${context}`,
  });
};

export const getPublicSharedPlan = (key: string) => {
  return fetchRequest(`${API_BASE}/appdata/shared/${key}/`).then((res) => {
    if (res.status === 200) {
      return res.json() as Promise<SharedData>;
    }
    throw res;
  });
};

export const sharePlan = (data: SharedDataCreate) => {
  return fetchWithAuth<SharedDataCreate>({
    path: "/appdata/shared/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const updateSharedPlan = ({
  key,
  ...data
}: Pick<SharedDataCreate, "key"> & Partial<SharedDataCreate>) => {
  return fetchWithAuth<SharedDataCreate>({
    path: `/appdata/shared/${key}/`,
    init: {
      ...commonPostRequestInit,
      method: "PATCH",
      body: JSON.stringify(data),
    },
  });
};

export const deleteSharedPlan = (key: string) => {
  return fetchWithAuth<SharedDataCreate>({
    path: `/appdata/shared/${key}/`,
    init: {
      method: "DELETE",
    },
  });
};

export const sharePlanViaEmail = (key: string, data: SharedDataSend) => {
  return fetchWithAuth<void>({
    path: `/appdata/shared/${key}/send/`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const saveData = async (ctx: OrchestratorMachineContext) => {
  const tokenStore = getTokenStore();
  const token = tokenStore.getToken();
  if (!token) {
    return;
  }

  const response = await fetchRequest(`${API_BASE}/appdata/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-Epoch": getEpoch(),
      Authorization: `token ${token}`,
    },
    body: JSON.stringify(contextToAppData(ctx)),
    //NOTE(mate): API is not supported by Firefox
    keepalive: isFirefox ? undefined : true,
  });

  if (response.status !== 200) {
    throw response;
  }

  setEpoch(response);
};

export const getFlats = async (address: string, prefix?: string) => {
  const response = await fetchWithAuth<schemas["Flat"]>({
    path: `/properties/flats/${encodeURIComponent(address)}.json${
      prefix ? "?q=" + encodeURIComponent(prefix) : ""
    }`,
  });

  if (response.status !== 200) {
    throw response;
  }

  return response.content;
};

export const getRooftop = ({
  propertyId,
  bare = false,
  zoomLevel,
}: {
  propertyId: Property["id"];
  bare?: boolean;
  zoomLevel?: ZoomLevel;
}) => {
  const query = [];
  if (bare) {
    query.push("b");
  }
  if (zoomLevel) {
    query.push(`z=${encodeURIComponent(zoomLevel)}`);
  }

  const qs = query.length === 0 ? "" : `?${query.join("&")}`;

  return `${API_BASE}/properties/rooftop/${propertyId}.jpeg${qs}`;
};

export const getAddressDetails = async (
  _address: string,
  _flat: string | undefined
) => {
  const address = encodeURIComponent(_address);
  const flat = _flat ? encodeURIComponent(_flat) : undefined;

  const url = flat
    ? `/properties/details/unit/${address}/${flat}.json`
    : `/properties/details/${address}.json`;

  const response = await fetchWithAuth<schemas["Property"]>({ path: url });

  if (response.status === 200) {
    return response.content;
  }

  throw response;
};

export const signUp = async (data: {
  fullName: string;
  displayName: string;
  email: string;
  password: string;
  propertyId: Property["id"];
  invite: string;
  transfer: string;
  share: string;
  state: OrchestratorMachineContext;
}) => {
  const body: schemas["Register"] = {
    full_name: data.fullName,
    display_name: data.displayName,
    email: data.email,
    password1: data.password,
    password2: data.password,
    property_id: data.propertyId,
    invite: data.invite,
    share: data.share,
    transfer: data.transfer,
    state: contextToAppData(data.state),
  };
  const response = await fetchRequest(`${API_BASE}/accounts/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status === 204) {
    return undefined;
  } else if (response.status === 201) {
    const login: schemas["Token"] = await response.json();
    setSentryUser(login.user);
    return login;
  } else {
    const error = await response.json();
    const messages = Object.values(error).join(" ");
    const exception = new Error(messages);
    exception["status"] = response.status;
    throw exception;
  }
};

export const resendEmail = async (body: schemas["ResendEmailVerification"]) => {
  const response = await fetchRequest(
    `${API_BASE}/accounts/verify-email/resend/`,
    {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    throw response;
  }
};

type SocialLoginRequest = Omit<schemas["SocialLogin"], "state"> & {
  state: OrchestratorMachineContext;
};

export const googleSignIn = async (data: SocialLoginRequest) => {
  const body: schemas["SocialLogin"] = {
    ...data,
    state: contextToAppData(data.state),
  };

  const response = await fetchRequest(`${API_BASE}/accounts/google/login/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const error = await response.json();
    const messages = Object.values(error).join(" ");
    const exception = new Error(messages);
    exception["status"] = response.status;
    throw exception;
  }

  const token: schemas["Token"] = await response.json();

  setSentryUser(token.user);

  return token;
};

export const facebookSignIn = async (data: SocialLoginRequest) => {
  const body: schemas["SocialLogin"] = {
    ...data,
    state: contextToAppData(data.state),
  };

  const response = await fetchRequest(`${API_BASE}/accounts/facebook/login/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const error = await response.json();
    const messages = Object.values(error).join(" ");
    const exception = new Error(messages);
    exception["status"] = response.status;
    throw exception;
  }

  const token: schemas["Token"] = await response.json();

  setSentryUser(token.user);

  return token;
};

export const verifyEmail = async (body: schemas["VerifyEmail"]) => {
  const response = await fetchRequest(`${API_BASE}/accounts/verify-email/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status === 200 || response.status === 201) {
    const token: schemas["Token"] = await response.json();
    setSentryUser(token.user);
    return token;
  }

  throw response;
};

export const verifyWaitlist = async (
  body: schemas["VerifyWaitlist"]
): Promise<void> => {
  const response = await fetchRequest(`${API_BASE}/waitlist/verify-email/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status !== 200) {
    throw response;
  }
};

export const joinWaitlist = async (
  body: schemas["Waitlist"]
): Promise<void> => {
  const response = await fetchRequest(`${API_BASE}/waitlist/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status !== 200) {
    throw response;
  }
};

export const joinEarlyAccess = async (
  body: EarlyAccessFormData
): Promise<void> => {
  const response = await fetchRequest(`${API_BASE}/waitlist/early-access/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (response.status !== 200) {
    throw response;
  }
};

export const emailLogin = async (credentials: schemas["Login"]) => {
  const response = await fetchRequest(`${API_BASE}/accounts/login/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    const error = await response.json();
    const messages = Object.values(error).join(" ");
    const exception = new Error(messages);
    exception["status"] = response.status;
    throw exception;
  }

  const token: schemas["Token"] = await response.json();

  setSentryUser(token.user);

  return token;
};

export const logout = async () => {
  await fetchWithAuth<void>({
    path: "/accounts/logout/",
    init: commonPostRequestInit,
  }).catch((error) => {
    captureException(error);
    return error;
  });

  setSentryUser(null);
};

export const requestPasswordReset = async (body: schemas["PasswordReset"]) => {
  const response = await fetchRequest(`${API_BASE}/accounts/reset-password/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw response;
  }
};

export const confirmPasswordReset = async ({
  uid,
  token,
  password,
}: {
  uid: string;
  token: string;
  password: string;
}) => {
  const body: schemas["PasswordResetConfirm"] = {
    uid,
    token,
    new_password1: password,
    new_password2: password,
  };

  const response = await fetchRequest(
    `${API_BASE}/accounts/reset-password/confirm/`,
    {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    if (response.status >= 400 && response.status < 500) {
      const error = await response.json();
      error.status = response.status;
      throw error;
    } else {
      const exception = new Error(`Unwanted status code: ${response.status}`);
      exception["status"] = response.status;
      throw exception;
    }
  }
};

export const getUploads = ({
  ordering,
  tag,
  collection,
  shared,
  viewer,
}: {
  ordering: Ordering;
  tag: Set<TagQuery>;
  collection?: number;
  viewer?: string[];
  shared?: SharedCollectionType;
}) => {
  const query = new URLSearchParams();
  const { type, rankCategory } = ordering;
  query.set("ordering", type);

  if (rankCategory) {
    query.set("rank_category", rankCategory);
  }
  if (collection) {
    query.set("collection", collection.toString());
  }
  if (shared) {
    query.set("shared", shared);
  }
  if (viewer.length > 0) {
    query.append("viewer", viewer.join(","));
  }

  tag.forEach((t) => {
    query.append("tag", t);
  });

  const path = `/uploads/?${query.toString()}`;

  return fetchWithAuth<schemas["PaginatedUploadList"]>({ path });
};

export const getNextUploads = ({ path }: { path: string }) => {
  return fetchWithAuth<schemas["PaginatedUploadList"]>({ path });
};

export const deleteUploads = (ids: Array<{ id: number }>) => {
  const body: schemas["BulkUploads"] = { uploads: ids };

  return fetchWithAuth<void>({
    path: "/uploads/delete/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });
};

export const updateUpload = (uploadId: number, date: string) => {
  const body: schemas["PatchedUpdateUpload"] = { created_on: date };

  return fetchWithAuth<schemas["CreateUploadResponse"]>({
    path: `/uploads/${uploadId}/`,
    init: {
      ...commonPostRequestInit,
      method: "PATCH",
      body: JSON.stringify(body),
    },
  });
};

export const createUpload = (
  name: string,
  tags: components["schemas"]["Tag"][]
) => {
  const body: schemas["CreateUploadRequest"] = { name, tags };

  return fetchWithAuth<schemas["CreateUploadResponse"]>({
    path: "/uploads/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });
};

export const putFileToS3 = ({
  file,
  presignedURL,
  contentType,
  onProgress,
}: {
  file: File;
  presignedURL: string;
  contentType: string;
  onProgress: (progress: number) => void;
}) => {
  onProgress(0);

  const xhr = new XMLHttpRequest();
  return new Promise<void>((resolve, reject) => {
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable && typeof onProgress === "function") {
        onProgress(event.loaded / event.total);
      }
    });
    xhr.addEventListener("loadend", () => {
      xhr.status === 200 ? resolve() : reject();
    });
    xhr.open("PUT", presignedURL, true);
    xhr.setRequestHeader("Content-Type", contentType);
    xhr.send(file);
  });
};

export const completeFileUpload = async (id: number) => {
  const response = await fetchWithAuth<void>({
    path: `/uploads/${id}/complete/`,
    init: {
      ...commonPostRequestInit,
    },
  });

  if (response.status !== 202) {
    throw response;
  }

  return executePoll<schemas["Upload"]>(response);
};

export const getTags = (
  tag: Set<string>,
  {
    collectionId,
    shared,
  }: { collectionId?: number; shared?: SharedCollectionType }
) => {
  const query = new URLSearchParams();

  tag.forEach((t) => {
    query.append("tag", t);
  });
  if (collectionId) {
    query.append("collection", collectionId.toString());
  }
  if (shared) {
    query.append("shared", shared);
  }

  return fetchWithAuth<
    { [tagName: string]: { [tagCategory: string]: number } }[]
  >({
    path: `/uploads/tags/?${query.toString()}`,
  });
};

export const tagUploads = (uploads: schemas["BulkTags"]) => {
  return fetchWithAuth<void>({
    path: "/uploads/tags/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(uploads),
    },
  });
};

export const updateTags = (tags: schemas["Tag"][]) => {
  return fetchWithAuth<void>({
    path: "/uploads/tags/update/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify({ tags }),
    },
  });
};

export const untagUploads = (uploads: schemas["BulkTags"]) => {
  return fetchWithAuth<void>({
    path: "/uploads/tags/untag/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(uploads),
    },
  });
};

export const deleteTags = (tags: Tag[]) => {
  const body: schemas["DeleteTags"] = { tags };

  return fetchWithAuth<void>({
    path: "/uploads/tags/delete/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });
};

export const getInspiration = async ({
  tag,
  take,
  next,
}: {
  tag: string[];
  take?: InspirationPageSize;
  next?: InspirationPageSize;
}): Promise<schemas["PaginatedUploadList"]> => {
  const query = new URLSearchParams();

  tag.forEach((t) => {
    query.append("tag", t);
  });

  if (take !== undefined) {
    query.append("take", String(take));
  }

  if (next !== undefined) {
    query.append("next", String(next));
  }

  const response = await fetchRequest(
    `${API_BASE}/uploads/inspiration.json?${query.toString()}`
  );

  if (response.status !== 200) {
    throw response;
  }

  return response.json();
};

export const getUploadItem = (id: number, shared?: SharedCollectionType) => {
  const query = shared ? getSharedQueryString(shared, true) : "";
  return fetchWithAuth<schemas["Upload"]>({
    path: `/uploads/${id}/${query}`,
  });
};

export const getNextInspiration = async (
  path: string
): Promise<schemas["PaginatedUploadList"]> => {
  const response = await fetchRequest(API_BASE + path);

  if (response.status !== 200) {
    throw response;
  }

  return response.json();
};

export const saveInspiration = async (
  id: number,
  tags: components["schemas"]["Tag"][]
) => {
  const body: schemas["SaveInspiration"] = { tags };

  const response = await fetchWithAuth<void>({
    path: `/uploads/inspiration/${id}/save/`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });

  if (response.status !== 202) {
    throw response;
  }

  return executePoll<schemas["Upload"]>(response);
};

export const saveBlogImage = async (
  imageName: string,
  tags: components["schemas"]["Tag"][]
) => {
  const body: schemas["SaveInspiration"] = { tags };

  const response = await fetchWithAuth<void>({
    path: `/uploads/blog/${imageName}/save/`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });

  if (response.status !== 202) {
    throw response;
  }

  return executePoll<schemas["Upload"]>(response);
};

export const getUserDetails = async () => {
  const response = await fetchWithAuth<schemas["UserDetails"]>({
    path: "/accounts/me/",
  });

  setSentryUser(response.content);

  return response.content;
};

export const updateUserDetails = async (
  body: schemas["PatchedUserDetails"]
) => {
  const response = await fetchWithAuth<schemas["PatchedUserDetails"]>({
    path: "/accounts/me/",
    init: {
      ...commonPostRequestInit,
      method: "PATCH",
      body: JSON.stringify(body),
    },
  });

  return response.content;
};

export const deleteUser = async () => {
  await fetchWithAuth<void>({
    path: "/accounts/delete/",
    init: {
      ...commonPostRequestInit,
    },
  });
};

export const clearNotifySharedArchived = async () => {
  await fetchWithAuth<void>({
    path: "/uploads/collections/clear_notify_share_archived/",
    init: {
      ...commonPostRequestInit,
    },
  });
};

export const getAlbums = ({
  shared,
  ...query
}: {
  ordering: AlbumsOrdering;
  context?: string;
  shared?: SharedCollectionType;
  name?: string;
}) => {
  const queryString = getSharedQueryString(shared);
  const params = new URLSearchParams(Object.entries(query));
  return fetchWithAuth<schemas["PaginatedCollectionList"]>({
    path: `/uploads/collections/?${params.toString()}${queryString}`,
    init: {},
    defaultValue: {
      content: {
        count: 0,
        results: [],
        summary: {},
      },
      status: 200,
      headers: undefined,
    },
  });
};

export const getNextPageRequest = <T>(
  path: string,
  defaultValue?: ResponseWrapper<T>
) => {
  return fetchWithAuth<T>({
    path,
    defaultValue,
  });
};

export const getMoreAlbums = (path: string) => {
  return getNextPageRequest<schemas["PaginatedCollectionList"]>(path, {
    content: {
      count: 0,
      results: [],
      summary: {},
    },
    status: 200,
    headers: undefined,
  });
};

export const getAlbum = (albumId: number, shared?: SharedCollectionType) => {
  const queryString = getSharedQueryString(shared, true);
  return fetchWithAuth<Collection>({
    path: `/uploads/collections/${albumId}/${queryString}`,
  });
};

export const createAlbum = (
  body: Omit<CreateCollection, "id" | "created_on">
) => {
  return fetchWithAuth<CreateCollection>({
    path: "/uploads/collections/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });
};

export const updateAlbum = (
  id: number,
  body: schemas["PatchedUpdateCollection"],
  shared?: SharedCollectionType
) => {
  const queryString = getSharedQueryString(shared, true);
  return fetchWithAuth<schemas["PatchedUpdateCollection"]>({
    path: `/uploads/collections/${id}/${queryString}`,
    init: {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(body),
    },
  });
};

export const deleteAlbum = (id: number) => {
  return fetchWithAuth<UnlinkedUploads>({
    path: `/uploads/collections/${id}/`,
    init: {
      method: "DELETE",
    },
  });
};

export const updateAlbumImages = (
  albumId: number,
  data: schemas["CollectionMembers"],
  shared?: SharedCollectionType
) => {
  const queryString = getSharedQueryString(shared, true);
  return fetchWithAuth<UnlinkedUploads>({
    path: `/uploads/collections/${albumId}/members/${queryString}`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const updateImageAlbums = (
  imageId: number,
  data: schemas["CollectionMembers"],
  shared?: SharedCollectionType
) => {
  const queryString = getSharedQueryString(shared, true);
  return fetchWithAuth<void>({
    path: `/uploads/${imageId}/collections/${queryString}`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const createBusinessAccount = (data: CreateBusiness) => {
  return fetchWithAuth({
    path: "/businesses/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const updateBusinessAccount = ({ id, ...data }: PatchedBusiness) => {
  return fetchWithAuth({
    path: `/businesses/${id}/`,
    init: {
      ...commonPostRequestInit,
      method: "PATCH",
      body: JSON.stringify(data),
    },
  });
};

export const getBusinesses = () => {
  return fetchWithAuth<Paginated<Business>>({
    path: "/businesses/",
  });
};

export const askShareInvite = (data: AskCollectionShare) => {
  return fetchWithAuth<schemas["CollectionShareCreated"]>({
    path: "/uploads/shares/ask/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const createShareInvite = (data: CreateCollectionShare) => {
  return fetchWithAuth<schemas["CollectionShareCreated"]>({
    path: "/uploads/shares/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const getShareInvite = async (
  key: string | number
): Promise<schemas["GetCollectionShare"]> => {
  const response = await fetchRequest(
    `${API_BASE}/uploads/shares/response/${key}/`
  );

  if (response.status !== 200) {
    throw response;
  }

  return response.json();
};

export const acceptShareInvite = (
  key: string | number,
  data: RespondCollectionShare
) => {
  return fetchWithAuth<schemas["CollectionAcceptedInvite"]>({
    path: `/uploads/shares/response/${key}/`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const resendShareInvite = (id: number) => {
  return fetchWithAuth<void>({
    path: `/uploads/shares/${id}/resend/`,
    init: {
      ...commonPostRequestInit,
    },
  });
};

export const cancelShareInvite = (id: number) => {
  return fetchWithAuth<void>({
    path: `/uploads/shares/${id}/`,
    init: {
      ...commonPostRequestInit,
      method: "DELETE",
    },
  });
};

export const getShareSentRequests = (propertyId?: Property["id"]) => {
  const query = getPropertyContextQuery(propertyId);

  return fetchWithAuth<Paginated<SentCollectionShare>>({
    path: `/uploads/shares/sent/?${query.toString()}`,
  });
};

export const getShareReceivedRequests = (propertyId?: Property["id"]) => {
  const query = getPropertyContextQuery(propertyId);

  return fetchWithAuth<Paginated<CollectionShare>>({
    path: `/uploads/shares/received/?${query.toString()}`,
  });
};

export const getShareReceivedRequest = (key: string) => {
  return fetchWithAuth<GetCollectionShare>({
    path: `/uploads/shares/response/${key}/`,
  });
};

export const updateShareSettings = (
  id: number,
  data: PatchedUpdateCollectionShare
) => {
  return fetchWithAuth<void>({
    path: `/uploads/shares/${id}/`,
    init: {
      ...commonPostRequestInit,
      method: "PATCH",
      body: JSON.stringify(data),
    },
  });
};

export const saveSharedImage = async (id: number, data: SaveSharedUpload) => {
  const response = await fetchWithAuth<void>({
    path: `/uploads/shares/${id}/save/`,
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });

  if (response.status !== 202) {
    throw response;
  }

  return executePoll<schemas["Upload"]>(response);
};

export const addReaction = (data: CreateReaction) => {
  return fetchWithAuth<CreateReactionResponse>({
    path: "/uploads/reactions/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(data),
    },
  });
};

export const updateReaction = (
  reactionId: number,
  data: PatchedUpdateReaction
) => {
  return fetchWithAuth<PatchedUpdateReaction>({
    path: `/uploads/reactions/${reactionId}/`,
    init: {
      ...commonPostRequestInit,
      method: "PATCH",
      body: JSON.stringify(data),
    },
  });
};

export const deleteReaction = (reactionId: number) => {
  return fetchWithAuth<void>({
    path: `/uploads/reactions/${reactionId}/`,
    init: {
      ...commonPostRequestInit,
      method: "DELETE",
    },
  });
};

export const acknowledgeReaction = (reactionId: number) => {
  return fetchWithAuth<void>({
    path: `/uploads/reactions/${reactionId}/acknowledge/`,
    init: {
      ...commonPostRequestInit,
    },
  });
};

export const contactUsRequest = async (data: ContactUs) => {
  const body: schemas["Contact"] = {
    category: data.type,
    message: data.message,
    requestor: data.email,
    subject: data.name,
  };
  const response = await fetchRequest(`${API_BASE}/contact/`, {
    ...commonPostRequestInit,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw response;
  }
  return response.json();
};

export const createCheckoutSession = (priceId: string) => {
  const body: schemas["CreateSession"] = {
    price_id: priceId,
  };

  return fetchWithAuth<schemas["CreateSessionResponse"]>({
    path: "/subscriptions/session/",
    init: {
      ...commonPostRequestInit,
      body: JSON.stringify(body),
    },
  });
};

export const fetchCheckoutSession = async (
  sessionId: string
): Promise<schemas["GetSession"]> => {
  const response = await fetchRequest(
    `${API_BASE}/subscriptions/session/${sessionId}/`
  );
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

export const fetchPricingPlans = async (): Promise<schemas["Plans"]> => {
  const response = await fetchRequest(`${API_BASE}/subscriptions/plans.json`);
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

export const fetchBuildVersion = async (): Promise<string> => {
  const response = await fetchRequest("/health/");

  if (!response.ok) {
    throw response;
  }

  const data = await response.json();
  return data.version;
};
