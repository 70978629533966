import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { stopPropagation } from "shared/util/eventUtils";

export const useModalState = (defaultValue: boolean = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const handleModalOpen = useCallback(
    (e?: SyntheticEvent) => {
      stopPropagation(e);
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const handleModalClose = useCallback(
    (e?: SyntheticEvent) => {
      stopPropagation(e);
      setIsOpen(false);
    },
    [setIsOpen]
  );

  const handleModalToggle = useCallback(
    (e?: SyntheticEvent, cb?: (state: boolean) => void) => {
      stopPropagation(e);
      setIsOpen((open) => {
        cb?.(!open);
        return !open;
      });
    },
    [setIsOpen]
  );
  const handlers = useMemo(
    () => ({ handleModalOpen, handleModalClose, handleModalToggle }),
    [handleModalOpen, handleModalClose, handleModalToggle]
  );

  return [isOpen, handlers] as const;
};
