import clsx from "clsx";
import { CSSProperties, FC } from "react";

import styles from "./Skeleton.module.scss";

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const Skeleton: FC<Props> = ({ className, style }) => {
  return <div className={clsx(styles.skeleton, className)} style={style} />;
};
