import { ReactComponent as IconBathroomSpecific } from "assets/icons/categories/bathroom_specific.svg";
import { ReactComponent as IconConcrete } from "assets/icons/categories/concrete.svg";
import { ReactComponent as IconDemolition } from "assets/icons/categories/demolition.svg";
import { ReactComponent as IconElectrical } from "assets/icons/categories/electrical.svg";
import { ReactComponent as IconExterior } from "assets/icons/categories/exterior_facade.svg";
import { ReactComponent as IconFlooring } from "assets/icons/categories/flooring.svg";
import { ReactComponent as IconFraming } from "assets/icons/categories/framing.svg";
import { ReactComponent as IconHardscape } from "assets/icons/categories/hardscrape.svg";
import { ReactComponent as IconHomeSecurity } from "assets/icons/categories/home_security.svg";
import { ReactComponent as IconInsulationAndSoundproofing } from "assets/icons/categories/insulation_and_soundproofing.svg";
import { ReactComponent as IconInteriorMaterial } from "assets/icons/categories/interior_wall_and_ceiling_material.svg";
import { ReactComponent as IconKitchenSpecific } from "assets/icons/categories/kitchen_specific.svg";
import { ReactComponent as IconLandscape } from "assets/icons/categories/landscape.svg";
import { ReactComponent as IconMasonry } from "assets/icons/categories/masonry.svg";
import { ReactComponent as IconMechanical } from "assets/icons/categories/mechanical.svg";
import { ReactComponent as IconPainting } from "assets/icons/categories/painting_and_coating.svg";
import { ReactComponent as IconPlumbing } from "assets/icons/categories/plumbing.svg";
import { ReactComponent as IconRoofing } from "assets/icons/categories/roofing.svg";
import { ReactComponent as IconSheathing } from "assets/icons/categories/sheathing.svg";
import { ReactComponent as IconSitework } from "assets/icons/categories/sitework.svg";
import { ReactComponent as IconVentilation } from "assets/icons/categories/ventilation.svg";
import { ReactComponent as IconWaterFeatures } from "assets/icons/categories/water_features.svg";
import { ReactComponent as IconWaterproofing } from "assets/icons/categories/waterproofing.svg";
import { ReactComponent as IconWindowsAndDoors } from "assets/icons/categories/windows_and_doors.svg";
import { ReactComponent as IconWoodwork } from "assets/icons/categories/woodwork.svg";
import { ReactComponent as NO_ICON } from "assets/icons/no_icon.svg";
import { GuideType } from "constants/guides/GuideType";
import { SvgIconComponent } from "shared/types/SvgIcon";

export const CategoryTypeToIconsKeysMap: Record<GuideType, SvgIconComponent> = {
  [GuideType.DEMOLITION]: IconDemolition,
  [GuideType.SITEWORK]: IconSitework,
  [GuideType.CONCRETE]: IconConcrete,
  [GuideType.MASONRY]: IconMasonry,
  [GuideType.FRAMING]: IconFraming,
  [GuideType.SHEATHING]: IconSheathing,
  [GuideType.WATERPROOFING]: IconWaterproofing,
  [GuideType.MECHANICAL]: IconMechanical,
  [GuideType.ELECTRICAL]: IconElectrical,
  [GuideType.PLUMBING]: IconPlumbing,
  [GuideType.INSULATION]: IconInsulationAndSoundproofing,
  [GuideType.VENTILATION]: IconVentilation,
  [GuideType.WALL_AND_CEILING_MATERIAL]: IconInteriorMaterial,
  [GuideType.FLOOR_MATERIAL]: IconFlooring,
  [GuideType.MILLWORK_AND_TRIM]: IconWoodwork,
  [GuideType.PAINT_AND_FINISHES]: IconPainting,
  [GuideType.KITCHEN_SPECIFIC]: IconKitchenSpecific,
  [GuideType.BATHROOM_SPECIFIC]: IconBathroomSpecific,
  [GuideType.ROOF]: IconRoofing,
  [GuideType.HOME_SECURITY]: IconHomeSecurity,
  [GuideType.WATER_FEATURES]: IconWaterFeatures,
  [GuideType.WINDOWS_AND_DOORS]: IconWindowsAndDoors,
  [GuideType.HARDSCAPE]: IconHardscape,
  [GuideType.LANDSCAPE]: IconLandscape,
  [GuideType.EXTERIOR]: IconExterior,
  [GuideType.HOME_IMPROVEMENT]: NO_ICON,
  [GuideType.GUIDE_HELP_ONLY]: NO_ICON,
  [GuideType.PRESET_GROUP_TYPE]: NO_ICON,
  [GuideType.ADD_ROOM_LAYOUT]: NO_ICON,
};
