import { getOrderedMap, getOrderedMapFrom } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideType } from "constants/guides/GuideType";
import { TFunction } from "react-i18next";

export enum SubCategory {
  BASEBOARD = "baseboard",
  BATHROOM_SPECIFIC = "bathroom_specific",
  BOILER = "boiler",
  BRICK = "brick",
  CARPET = "carpet",
  CEILING_FAN = "ceiling_fan",
  CEILING_FAN_CONTROL = "ceiling_fan_control",
  CEILING_FRAME_MOULDING = "ceiling_frame_moulding",
  CEILING_PANELING = "ceiling_paneling",
  CHAIR_RAIL = "chair_rail",
  CONCRETE = "concrete",
  CROWN_MOULDING = "crown_moulding",
  DECKING = "decking",
  DEMOLITION = "demolition",
  DOOR_TRIM = "door_trim",
  DORMER = "dormer",
  DRAIN = "drain",
  DRIP_EDGE = "drig_edge",
  DRYWALL = "drywall",
  ELECTRICAL_PANEL = "electrical_panel",
  ELECTRICAL_SOURCE = "electrical_source",
  EXTERIOR_CEILING_MATERIAL = "exterior_ceiling_material",
  EXTERIOR_DOOR = "exterior_door",
  EXTERIOR_FLOOR_MATERIAL = "exterior_floor_material",
  EXTERIOR_SEWER_LINE = "exterior_sewer_line",
  EXTERIOR_WALL_MATERIAL = "exterior_wall_material",
  EXTERIOR_WATER_LINE = "exterior_water_line",
  FINANCES = "finances",
  FIREPLACE_MANTLE = "fireplace_mantle",
  FLASHING = "flashing",
  FURNACE = "furnace",
  GAS_LINE = "gas_line",
  GAS_SOURCE = "gas_source",
  GUTTER = "gutter",
  HOMEOWNER_INSPECTION_CHECKLISTS = "homeowner_inspection_checklists",
  HOME_IMPROVEMENT_PROFESSIONALS = "home_improvement_professionals",
  ICE_WATER_SHIELD = "ice_water_shield",
  INTERIOR_CEILING_MATERIAL = "interior_ceiling_material",
  INTERIOR_DOOR = "interior_door",
  INTERIOR_FLOOR_MATERIAL = "interior_floor_material",
  INTERIOR_SEWER_LINE = "interior_sewer_line",
  INTERIOR_WALL_MATERIAL = "interior_wall_material",
  INTERIOR_WATER_LINE = "interior_water_line",
  KITCHEN_APPLIANCE = "kitchen_appliance",
  KITCHEN_CABINET = "kitchen_cabinet",
  KITCHEN_CABINET_DOOR = "kitchen_cabinet_door",
  KITCHEN_COUNTERTOP_AND_BACKSPLASH = "kitchen_countertop_and_backsplash",
  KITCHEN_FIXTURE = "kitchen_fixture",
  LATHE_AND_PLASTER = "lathe_and_plaster",
  LIGHTING_CONTROL = "lighting_control",
  LIGHTING_FIXTURE = "lighting_fixture",
  MINISPLIT_SYSTEM = "minisplit_system",
  MIRROR = "mirror",
  OUTLET = "outlet",
  PACKAGED_SYSTEM = "packaged_system",
  PAINT = "paint",
  PLASTER = "plaster",
  PROTECTIVE_FINISH = "protective_finish",
  RESEARCH_AND_PLANNING = "research_and_planning",
  RIDGE_CAP = "ridge_cap",
  ROOF_COVERING = "roof_covering",
  SEALER = "sealer",
  SEWER_SYSTEM = "sewer_system",
  SHELVING = "shelving",
  SMOKE_ALARM = "smoke_alarm",
  SPLIT_SYSTEM = "split_system",
  STAIN = "stain",
  STONE = "stone",
  SUMP_PUMP = "sump_pump",
  THE_RENOVATION_PROCESS = "the_renovation_process",
  TILE = "tile",
  UNDERLAYMENT = "underlayment",
  VALLEY = "valley",
  WAINSCOTING = "wainscoting",
  WALL_FRAME_MOULDING = "wall_frame_moulding",
  WALL_PANELING = "wall_paneling",
  WATER_HEATER = "water_heater",
  WATER_SHUT_OFF_VALVE = "water_shut_off_valve",
  WATER_SOURCE_INDEPDENDENT_WELL = "water_source_independent_well",
  WATER_SOURCE_MUNICIPAL = "water_source_municipal",
  WINDOWS = "windows",
  WINDOW_TRIM = "window_trim",
  WIRING = "wiring",
  WOOD = "wood",
}

export const SUBCATEGORIES_ORDER = getOrderedMap(Object.values(SubCategory));

const _SUBCATEGORY_TAXONOMY = {
  [GuideType.MECHANICAL]: [
    SubCategory.SPLIT_SYSTEM,
    SubCategory.PACKAGED_SYSTEM,
    SubCategory.MINISPLIT_SYSTEM,
    SubCategory.FURNACE,
    SubCategory.BOILER,
  ],
  [GuideType.ELECTRICAL]: [
    SubCategory.ELECTRICAL_SOURCE,
    SubCategory.ELECTRICAL_PANEL,
    SubCategory.WIRING,
    SubCategory.LIGHTING_FIXTURE,
    SubCategory.LIGHTING_CONTROL,
    SubCategory.CEILING_FAN,
    SubCategory.CEILING_FAN_CONTROL,
    SubCategory.OUTLET,
    SubCategory.SMOKE_ALARM,
  ],
  [GuideType.PLUMBING]: [
    SubCategory.BATHROOM_SPECIFIC,
    SubCategory.SEWER_SYSTEM,
    SubCategory.WATER_SOURCE_MUNICIPAL,
    SubCategory.WATER_SOURCE_INDEPDENDENT_WELL,
    SubCategory.EXTERIOR_SEWER_LINE,
    SubCategory.INTERIOR_SEWER_LINE,
    SubCategory.EXTERIOR_WATER_LINE,
    SubCategory.INTERIOR_WATER_LINE,
    SubCategory.WATER_HEATER,
    SubCategory.WATER_SHUT_OFF_VALVE,
    SubCategory.GAS_SOURCE,
    SubCategory.GAS_LINE,
    SubCategory.SUMP_PUMP,
  ],
  [GuideType.KITCHEN_SPECIFIC]: [
    SubCategory.KITCHEN_CABINET,
    SubCategory.KITCHEN_CABINET_DOOR,
    SubCategory.KITCHEN_APPLIANCE,
    SubCategory.KITCHEN_FIXTURE,
    SubCategory.KITCHEN_COUNTERTOP_AND_BACKSPLASH,
  ],
  [GuideType.WALL_AND_CEILING_MATERIAL]: [
    SubCategory.DRYWALL,
    SubCategory.TILE,
    SubCategory.STONE,
    SubCategory.WOOD,
    SubCategory.MIRROR,
    SubCategory.BRICK,
    SubCategory.CONCRETE,
    SubCategory.LATHE_AND_PLASTER,
    SubCategory.INTERIOR_WALL_MATERIAL,
    SubCategory.INTERIOR_CEILING_MATERIAL,
    SubCategory.EXTERIOR_WALL_MATERIAL,
    SubCategory.EXTERIOR_CEILING_MATERIAL,
  ],
  [GuideType.FLOOR_MATERIAL]: [
    SubCategory.CARPET,
    SubCategory.WOOD,
    SubCategory.TILE,
    SubCategory.STONE,
    SubCategory.BRICK,
    SubCategory.CONCRETE,
    SubCategory.EXTERIOR_FLOOR_MATERIAL,
    SubCategory.INTERIOR_FLOOR_MATERIAL,
  ],
  [GuideType.MILLWORK_AND_TRIM]: [
    SubCategory.BASEBOARD,
    SubCategory.WINDOW_TRIM,
    SubCategory.DOOR_TRIM,
    SubCategory.CROWN_MOULDING,
    SubCategory.WALL_PANELING,
    SubCategory.CEILING_PANELING,
    SubCategory.WAINSCOTING,
    SubCategory.CHAIR_RAIL,
    SubCategory.WALL_FRAME_MOULDING,
    SubCategory.CEILING_FRAME_MOULDING,
    SubCategory.FIREPLACE_MANTLE,
    SubCategory.SHELVING,
  ],
  [GuideType.PAINT_AND_FINISHES]: [
    SubCategory.STAIN,
    SubCategory.SEALER,
    SubCategory.PROTECTIVE_FINISH,
    SubCategory.PAINT,
    SubCategory.PLASTER,
  ],
  [GuideType.ROOF]: [
    SubCategory.DECKING,
    SubCategory.UNDERLAYMENT,
    SubCategory.ICE_WATER_SHIELD,
    SubCategory.FLASHING,
    SubCategory.RIDGE_CAP,
    SubCategory.DRIP_EDGE,
    SubCategory.ROOF_COVERING,
    SubCategory.VALLEY,
    SubCategory.DORMER,
    SubCategory.GUTTER,
    SubCategory.DRAIN,
  ],
  [GuideType.HOME_IMPROVEMENT]: [
    SubCategory.FINANCES,
    SubCategory.RESEARCH_AND_PLANNING,
    SubCategory.HOME_IMPROVEMENT_PROFESSIONALS,
    SubCategory.THE_RENOVATION_PROCESS,
    SubCategory.HOMEOWNER_INSPECTION_CHECKLISTS,
  ],
} as const;

export const SUBCATEGORY_TAXONOMY = getOrderedMapFrom(
  _SUBCATEGORY_TAXONOMY as Partial<Record<GuideType, readonly SubCategory[]>>,
  Object.values(GuideType)
);

export const SUBCATEGORY_TAXONOMY_ORDER = getOrderedMap(
  Object.values(SUBCATEGORY_TAXONOMY).flat()
);

export const SubCategoryGuideItemTypeCategoriesMap: Record<
  SubCategory,
  GuideItemTypeCategory
> = {
  //TODO: https://tree.taiga.io/project/homebase-engineering/us/1300?kanban-status=2730842&kanban-swimlane=5473
  [SubCategory.BASEBOARD]: undefined,
  [SubCategory.BATHROOM_SPECIFIC]: undefined,
  [SubCategory.BOILER]: GuideItemTypeCategory.BOILER,
  [SubCategory.BRICK]: undefined,
  [SubCategory.CARPET]: undefined,
  [SubCategory.CEILING_FAN]: GuideItemTypeCategory.CEILING_FAN,
  [SubCategory.CEILING_FAN_CONTROL]: GuideItemTypeCategory.CEILING_FAN_CONTROL,
  [SubCategory.CEILING_FRAME_MOULDING]: undefined,
  [SubCategory.CEILING_PANELING]: undefined,
  [SubCategory.CHAIR_RAIL]: undefined,
  [SubCategory.CONCRETE]: undefined,
  [SubCategory.CROWN_MOULDING]: undefined,
  [SubCategory.DECKING]: undefined,
  [SubCategory.DEMOLITION]: undefined,
  [SubCategory.DOOR_TRIM]: GuideItemTypeCategory.DOOR_CASING_TRIM,
  [SubCategory.DORMER]: undefined,
  [SubCategory.DRAIN]: undefined,
  [SubCategory.DRIP_EDGE]: undefined,
  [SubCategory.DRYWALL]: GuideItemTypeCategory.WALL_MATERIAL,
  [SubCategory.ELECTRICAL_PANEL]: GuideItemTypeCategory.ELECTRICAL_PANEL,
  [SubCategory.ELECTRICAL_SOURCE]: undefined,
  [SubCategory.EXTERIOR_CEILING_MATERIAL]: undefined,
  [SubCategory.EXTERIOR_DOOR]: GuideItemTypeCategory.DOOR,
  [SubCategory.EXTERIOR_FLOOR_MATERIAL]: undefined,
  [SubCategory.EXTERIOR_SEWER_LINE]: undefined,
  [SubCategory.EXTERIOR_WALL_MATERIAL]: GuideItemTypeCategory.WALL_MATERIAL,
  [SubCategory.EXTERIOR_WATER_LINE]: undefined,
  [SubCategory.FINANCES]: undefined,
  [SubCategory.FIREPLACE_MANTLE]: undefined,
  [SubCategory.FLASHING]: undefined,
  [SubCategory.FURNACE]: GuideItemTypeCategory.FURNACE,
  [SubCategory.GAS_LINE]: undefined,
  [SubCategory.GAS_SOURCE]: undefined,
  [SubCategory.GUTTER]: undefined,
  [SubCategory.HOMEOWNER_INSPECTION_CHECKLISTS]: undefined,
  [SubCategory.HOME_IMPROVEMENT_PROFESSIONALS]: undefined,
  [SubCategory.ICE_WATER_SHIELD]: undefined,
  [SubCategory.INTERIOR_CEILING_MATERIAL]: undefined,
  [SubCategory.INTERIOR_DOOR]: GuideItemTypeCategory.DOOR,
  [SubCategory.INTERIOR_FLOOR_MATERIAL]: undefined,
  [SubCategory.INTERIOR_SEWER_LINE]: undefined,
  [SubCategory.INTERIOR_WALL_MATERIAL]: GuideItemTypeCategory.WALL_MATERIAL,
  [SubCategory.INTERIOR_WATER_LINE]: undefined,
  [SubCategory.KITCHEN_APPLIANCE]: undefined,
  [SubCategory.KITCHEN_CABINET]: undefined,
  [SubCategory.KITCHEN_CABINET_DOOR]: undefined,
  [SubCategory.KITCHEN_COUNTERTOP_AND_BACKSPLASH]: undefined,
  [SubCategory.KITCHEN_FIXTURE]: undefined,
  [SubCategory.LATHE_AND_PLASTER]: undefined,
  [SubCategory.LIGHTING_CONTROL]: GuideItemTypeCategory.LIGHTING_CONTROL,
  [SubCategory.LIGHTING_FIXTURE]: GuideItemTypeCategory.LIGHTING_FIXTURE,
  [SubCategory.MINISPLIT_SYSTEM]: GuideItemTypeCategory.MINISPLIT_SYSTEM,
  [SubCategory.MIRROR]: undefined,
  [SubCategory.OUTLET]: GuideItemTypeCategory.OUTLET,
  [SubCategory.PACKAGED_SYSTEM]: GuideItemTypeCategory.PACKAGED_SYSTEM,
  [SubCategory.PAINT]: undefined,
  [SubCategory.PLASTER]: undefined,
  [SubCategory.PROTECTIVE_FINISH]: undefined,
  [SubCategory.RESEARCH_AND_PLANNING]: undefined,
  [SubCategory.RIDGE_CAP]: undefined,
  [SubCategory.ROOF_COVERING]: undefined,
  [SubCategory.SEALER]: undefined,
  [SubCategory.SEWER_SYSTEM]: undefined,
  [SubCategory.SHELVING]: undefined,
  [SubCategory.SMOKE_ALARM]: GuideItemTypeCategory.SMOKE_ALARM,
  [SubCategory.SPLIT_SYSTEM]: GuideItemTypeCategory.SPLIT_SYSTEM,
  [SubCategory.STAIN]: undefined,
  [SubCategory.STONE]: undefined,
  [SubCategory.SUMP_PUMP]: undefined,
  [SubCategory.THE_RENOVATION_PROCESS]: undefined,
  [SubCategory.TILE]: undefined,
  [SubCategory.UNDERLAYMENT]: undefined,
  [SubCategory.VALLEY]: undefined,
  [SubCategory.WAINSCOTING]: undefined,
  [SubCategory.WALL_FRAME_MOULDING]: undefined,
  [SubCategory.WALL_PANELING]: undefined,
  [SubCategory.WATER_HEATER]: undefined,
  [SubCategory.WATER_SHUT_OFF_VALVE]: undefined,
  [SubCategory.WATER_SOURCE_INDEPDENDENT_WELL]: undefined,
  [SubCategory.WATER_SOURCE_MUNICIPAL]: undefined,
  [SubCategory.WINDOWS]: GuideItemTypeCategory.WINDOWS,
  [SubCategory.WINDOW_TRIM]: undefined,
  [SubCategory.WIRING]: undefined,
  [SubCategory.WOOD]: undefined,
};

export const SubCategoryToTitleKeysMap = (
  t: TFunction
): Record<SubCategory, string> => ({
  [SubCategory.BASEBOARD]: t("Baseboard"),
  [SubCategory.BATHROOM_SPECIFIC]: t("Bathroom Specific"),
  [SubCategory.BOILER]: t("Boiler"),
  [SubCategory.BRICK]: t("Brick"),
  [SubCategory.CARPET]: t("Carpet"),
  [SubCategory.CEILING_FAN]: t("Ceiling Fan"),
  [SubCategory.CEILING_FAN_CONTROL]: t("Ceiling Fan Control"),
  [SubCategory.CEILING_FRAME_MOULDING]: t("Ceiling Frame Moulding"),
  [SubCategory.CEILING_PANELING]: t("Ceiling Paneling"),
  [SubCategory.CHAIR_RAIL]: t("Chair Rail"),
  [SubCategory.CONCRETE]: t("Concrete"),
  [SubCategory.CROWN_MOULDING]: t("Crown Moulding"),
  [SubCategory.DECKING]: t("Decking"),
  [SubCategory.DEMOLITION]: t("Demolition"),
  [SubCategory.DOOR_TRIM]: t("Door Trim"),
  [SubCategory.DORMER]: t("Dormer"),
  [SubCategory.DRAIN]: t("Drain"),
  [SubCategory.DRIP_EDGE]: t("Drip Edge"),
  [SubCategory.DRYWALL]: t("Drywall"),
  [SubCategory.ELECTRICAL_PANEL]: t("Electrical Panel"),
  [SubCategory.ELECTRICAL_SOURCE]: t("Electrical Source"),
  [SubCategory.EXTERIOR_CEILING_MATERIAL]: t("Exterior Ceiling Material"),
  [SubCategory.EXTERIOR_DOOR]: t("Exterior Door"),
  [SubCategory.EXTERIOR_FLOOR_MATERIAL]: t("Exterior Floor Material"),
  [SubCategory.EXTERIOR_SEWER_LINE]: t("Exterior Sewer Line"),
  [SubCategory.EXTERIOR_WALL_MATERIAL]: t("Exterior Wall Material"),
  [SubCategory.EXTERIOR_WATER_LINE]: t("Exterior Water Line"),
  [SubCategory.FINANCES]: t("Finances"),
  [SubCategory.FIREPLACE_MANTLE]: t("Fireplace Mantle"),
  [SubCategory.FLASHING]: t("Flashing"),
  [SubCategory.FURNACE]: t("Furnace"),
  [SubCategory.GAS_LINE]: t("Gas Line"),
  [SubCategory.GAS_SOURCE]: t("Gas Source"),
  [SubCategory.GUTTER]: t("Gutters"),
  [SubCategory.HOMEOWNER_INSPECTION_CHECKLISTS]: t(
    "Homeowner Inspection Checklists"
  ),
  [SubCategory.HOME_IMPROVEMENT_PROFESSIONALS]: t(
    "Home Improvement Professionals"
  ),
  [SubCategory.ICE_WATER_SHIELD]: t("Ice-Water Shield"),
  [SubCategory.INTERIOR_CEILING_MATERIAL]: t("Interior Ceiling Material"),
  [SubCategory.INTERIOR_DOOR]: t("Interior Door"),
  [SubCategory.INTERIOR_FLOOR_MATERIAL]: t("Interior Floor Material"),
  [SubCategory.INTERIOR_SEWER_LINE]: t("Interior Sewer Line"),
  [SubCategory.INTERIOR_WALL_MATERIAL]: t("Interior Wall Material"),
  [SubCategory.INTERIOR_WATER_LINE]: t("Interior Water Line"),
  [SubCategory.KITCHEN_APPLIANCE]: t("Kitchen Appliance"),
  [SubCategory.KITCHEN_CABINET]: t("Kitchen Cabinet"),
  [SubCategory.KITCHEN_CABINET_DOOR]: t("Kitchen Cabinet Door"),
  [SubCategory.KITCHEN_COUNTERTOP_AND_BACKSPLASH]: t("Kitchen Countertops"),
  [SubCategory.KITCHEN_FIXTURE]: t("Kitchen Fixture"),
  [SubCategory.LATHE_AND_PLASTER]: t("Lathe & Plaster"),
  [SubCategory.LIGHTING_CONTROL]: t("Lighting Control"),
  [SubCategory.LIGHTING_FIXTURE]: t("Lighting Fixture"),
  [SubCategory.MINISPLIT_SYSTEM]: t("Mini-Split System"),
  [SubCategory.MIRROR]: t("Mirror"),
  [SubCategory.OUTLET]: t("Outlet"),
  [SubCategory.PACKAGED_SYSTEM]: t("Packaged System"),
  [SubCategory.PAINT]: t("Paint"),
  [SubCategory.PLASTER]: t("Plaster"),
  [SubCategory.PROTECTIVE_FINISH]: t("Protective Finish"),
  [SubCategory.RESEARCH_AND_PLANNING]: t("Research & Planning"),
  [SubCategory.RIDGE_CAP]: t("Ridge Cap"),
  [SubCategory.ROOF_COVERING]: t("Roof Covering"),
  [SubCategory.SEALER]: t("Sealer"),
  [SubCategory.SEWER_SYSTEM]: t("Sewer System"),
  [SubCategory.SHELVING]: t("Shelving"),
  [SubCategory.SMOKE_ALARM]: t("Smoke Alarm"),
  [SubCategory.SPLIT_SYSTEM]: t("Split System"),
  [SubCategory.STAIN]: t("Stain"),
  [SubCategory.STONE]: t("Stone"),
  [SubCategory.SUMP_PUMP]: t("Sump Pump"),
  [SubCategory.THE_RENOVATION_PROCESS]: t("The Renovation Process"),
  [SubCategory.TILE]: t("Tile"),
  [SubCategory.UNDERLAYMENT]: t("Underlayment"),
  [SubCategory.VALLEY]: t("Valley"),
  [SubCategory.WAINSCOTING]: t("Wainscoting"),
  [SubCategory.WALL_FRAME_MOULDING]: t("Wall Frame Moulding"),
  [SubCategory.WALL_PANELING]: t("Wall Paneling"),
  [SubCategory.WATER_HEATER]: t("Water Heater"),
  [SubCategory.WATER_SHUT_OFF_VALVE]: t("Water Shut-Off Valve"),
  [SubCategory.WATER_SOURCE_INDEPDENDENT_WELL]: t(
    "Independent Well Water Source"
  ),
  [SubCategory.WATER_SOURCE_MUNICIPAL]: t("Municipal Water Source"),
  [SubCategory.WINDOWS]: t("Windows"),
  [SubCategory.WINDOW_TRIM]: t("Window Trim"),
  [SubCategory.WIRING]: t("Wiring"),
  [SubCategory.WOOD]: t("Wood"),
});
