export const useIOS = (): boolean => {
  if (typeof window !== "undefined") {
    try {
      const userAgent = window.navigator.userAgent || "";
      return userAgent.includes("like Mac OS X");
    } catch (e) {
      return false;
    }
  }
  return false;
};
