import { CoverPhotos } from "api/models/CoverPhotos";
import { UnlinkedUploads } from "api/models/UnlinkedUploads";
import { Upload } from "api/models/Upload";
import { isInViewport } from "common/utils/scrollUtils";
import { GuideType } from "constants/guides/GuideType";
import { last } from "lodash-es";
import { Accept } from "react-dropzone";
import { TFunction } from "react-i18next";
import { findBy } from "shared/util/findBy";

import {
  BUILD_CATEGORY_TYPES_ORDERS,
  BUILD_STATUS_TAGS,
  COMMON_TAG_CATEGORIES,
  MEDIA_SCROLL_CONTAINER_ID,
  MEDIA_SCROLL_CONTAINER_ITEM_ID_BASE,
  SAVE_SHARE_IMAGE_ALLOWED_TAGS_CATEGORIES,
} from "./constants";
import {
  EXCLUDED_COMMON_TAGS_FROM_SHOWING,
  HIDDEN_FROM_ALL_PHOTOS_TAG,
  HIDDEN_TAG_CATEGORIES,
} from "./machine/constants";
import { Image, Tag, Tags } from "./types";

const SPLIT_VIEW_COUNT = 2;

// NOTE(clemens): whenever a new type is added to this list make sure to add
//  a test image to the `backend/uploads/fixtures` folder
const ALLOWED_FILE_TYPES = new Set([
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/heic",
  "image/heif",
  "application/pdf",
]);

export const getUnsupportedFilesMessage = (t: TFunction) => {
  const extensions = Array.from(ALLOWED_FILE_TYPES).map((it) =>
    last(it.split("/")).toUpperCase()
  );
  const firstExtensions = extensions.slice(0, extensions.length - 1).join(", ");
  const lastExtension = extensions[extensions.length - 1];
  return {
    title: t("Incorrect format"),
    info: t("Please upload {{firstExtensions}}, or {{lastExtension}} files", {
      firstExtensions,
      lastExtension,
    }),
  };
};

export const accept: Accept = Object.fromEntries(
  Array.from(ALLOWED_FILE_TYPES).map((f) => [f, []])
);

export const maxFiles = 30;

export const FILE_TYPES = Array.from(ALLOWED_FILE_TYPES).join(",");

export const isExtensionValid = (file): boolean =>
  ALLOWED_FILE_TYPES.has(file.type);

export const isUploadsCompareView = (uploads) => {
  return (
    Number(!!uploads.before.count) +
      Number(!!uploads.during.count) +
      Number(!!uploads.after.count) >=
    SPLIT_VIEW_COUNT
  );
};

export const getTagForCoverPhotoByImageId = (
  coverPhotos: CoverPhotos,
  imageId: Image["id"],
  rank: number
): Tag => {
  const roomId = getCoverPhotoRoomIdByImageId(coverPhotos, imageId);

  if (!roomId) {
    return null;
  }

  return getRoomAndSpacesTag(roomId, rank);
};

const getCoverPhotoRoomIdByImageId = (
  coverPhotos: CoverPhotos,
  imageId: Image["id"]
) => {
  const entries = Object.entries(coverPhotos);

  for (const [roomId, image] of entries) {
    if (image.id === imageId) {
      return roomId;
    }
  }
  return null;
};

export const getUploadedDateOnly = (t: TFunction, date: string) => {
  return t("{{val, datetime}}", {
    val: new Date(date),
    formatParams: {
      val: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
    },
  });
};

export const getUploadedDateText = (
  t: TFunction,
  date: string,
  type: "taken" | "uploaded"
) => {
  if (type === "taken") {
    return t("Taken on {{date}}", {
      date: getUploadedDateOnly(t, date),
    });
  }
  return t("Uploaded on {{date}}", {
    val: new Date(date),
    date: getUploadedDateOnly(t, date),
  });
};

export const getCreatedOnDateText = (t: TFunction, date: string) => {
  return t("Created On {{date}}", {
    date: getUploadedDateOnly(t, date),
  });
};

export const getEmptyTagsCategories = (tags: Tags) => {
  return Object.entries(tags).reduce<COMMON_TAG_CATEGORIES[]>(
    (result, [category, tags]) => {
      if (Object.keys(tags).length === 0) {
        result.push(category as COMMON_TAG_CATEGORIES);
      }
      return result;
    },
    []
  );
};

export const getOrphanedImagesIds = (data: UnlinkedUploads) => {
  const inspirationMappingIds: number[] = [];
  data.unlinked_uploads.forEach((item) => {
    const isNotInLibrary = findBy(
      item.tags,
      "name",
      HIDDEN_FROM_ALL_PHOTOS_TAG
    );
    if (isNotInLibrary) {
      inspirationMappingIds.push(item.id);
    }
  });

  return inspirationMappingIds;
};

export const getFiltersList = (filters: string[]) => {
  const withoutHiddenFilters: string[] = [];
  (filters || []).forEach((filter) => {
    if (!HIDDEN_TAG_CATEGORIES.has(filter)) {
      withoutHiddenFilters.push(filter);
    }
  });

  return withoutHiddenFilters;
};

export const getAllowedDisplayTags = (tags: Tag[]): Tag[] => {
  return tags.filter(
    (tag) =>
      !HIDDEN_TAG_CATEGORIES.has(tag.category) &&
      !EXCLUDED_COMMON_TAGS_FROM_SHOWING.has(tag.category)
  );
};

export const getRoomAndSpacesTag = (roomId: string, rank: number): Tag => {
  return {
    name: roomId,
    category: COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES,
    rank,
  };
};

export const getHIPsTag = (hipId: string): Tag => {
  return {
    name: hipId,
    category: COMMON_TAG_CATEGORIES.IMPROVEMENT_PROJECTS,
  };
};

export const getPlanningCategoriesTag = (category: GuideType): Tag => {
  return {
    name: category,
    category: COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES,
    rank: BUILD_CATEGORY_TYPES_ORDERS[category],
  };
};

// eslint-disable-next-line import/no-unused-modules
export const getBuildStatusTag = (status: BUILD_STATUS_TAGS): Tag => {
  return {
    name: status,
    category: COMMON_TAG_CATEGORIES.BUILD_STATUS,
  };
};

export const scrollMediaItemIntoView = (index: number) => {
  const element = document.getElementById(
    `${MEDIA_SCROLL_CONTAINER_ITEM_ID_BASE}-${index}`
  );

  if (!element) {
    return;
  }

  if (!isInViewport(element)) {
    element?.scrollIntoView();
  }
};

export const scrollMediaContainerToTop = () => {
  const mediaScrollEl = document.getElementById(MEDIA_SCROLL_CONTAINER_ID);
  mediaScrollEl?.scrollTo({ top: 0 });
};

export const getSavedSharedImageProName = (upload: Upload) =>
  findBy(upload.tags, "category", COMMON_TAG_CATEGORIES.SHARED_WITH_ME);

export const getAllowedSharedImageSaveTags = (image: Upload) => {
  return image.tags.filter(
    (tag) =>
      !HIDDEN_TAG_CATEGORIES.has(tag.category) &&
      SAVE_SHARE_IMAGE_ALLOWED_TAGS_CATEGORIES.includes(
        tag.category as COMMON_TAG_CATEGORIES
      )
  );
};
