import STORAGE_KEYS from "constants/storageKeys.json";
import { uuid } from "shared/util/uuid";

interface Persistence {
  getStringSync(key: string): string | undefined;

  setStringSync(key: string, value: string): void;

  removeStringSync(key: string): void;

  clearSync(): void;
}

const StorageWrapper = (storage: Storage) => {
  const ns = (key: string) => `${STORAGE_KEYS["__NAMESPACE"]}/${key}`;

  const getStringSync = (key: string) => {
    try {
      return storage.getItem(ns(key));
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const setStringSync = (key: string, value: string) => {
    try {
      storage.setItem(ns(key), value);
    } catch (e) {
      console.error(e);
    }
  };

  const removeStringSync = (key: string) => {
    storage.removeItem(ns(key));
  };

  const clearSync = () => {
    const prefix = ns("");

    try {
      for (let i = storage.length - 1; i >= 0; i--) {
        const key = storage.key(i);
        if (key.startsWith(prefix)) {
          storage.removeItem(key);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getStringSync,
    setStringSync,
    removeStringSync,
    clearSync,
  };
};

const MemoryStorage = () => {
  const ns = `__${STORAGE_KEYS.__NAMESPACE}__`;

  return {
    getStringSync: (key: string) => {
      return window[ns]?.[key] as string;
    },
    setStringSync: (key: string, value: string) => {
      window[ns] = {
        ...window[ns],
        [key]: value,
      };
    },
    clearSync: () => {
      window[ns] = undefined;
    },
    removeStringSync: (key: string) => {
      window[ns] = {
        ...window[ns],
        [key]: undefined,
      };
    },
  };
};

const LOCAL_STORAGE = StorageWrapper(window.localStorage);
const SESSION_STORAGE = StorageWrapper(window.sessionStorage);
const MEMORY_STORAGE = MemoryStorage();

export const clearStorages = () => {
  LOCAL_STORAGE.clearSync();
  SESSION_STORAGE.clearSync();
  MEMORY_STORAGE.clearSync();
  return Promise.resolve();
};

export const getEphemeral = (): Persistence => {
  return SESSION_STORAGE;
};

export const getMemory = (): Persistence => {
  return MEMORY_STORAGE;
};

export const getTokenStore = () => {
  const isRememberMe = () =>
    LOCAL_STORAGE.getStringSync(STORAGE_KEYS.LOGIN__REMEMBER_ME) === "true";

  return {
    getToken(): string | undefined {
      return (
        MEMORY_STORAGE.getStringSync(STORAGE_KEYS.TOKEN) ||
        SESSION_STORAGE.getStringSync(STORAGE_KEYS.TOKEN) ||
        LOCAL_STORAGE.getStringSync(STORAGE_KEYS.TOKEN)
      );
    },
    setToken(token: string, rememberMe: boolean | undefined, userId: number) {
      if (rememberMe === undefined) {
        rememberMe = isRememberMe();
      } else if (rememberMe) {
        LOCAL_STORAGE.setStringSync(STORAGE_KEYS.LOGIN__REMEMBER_ME, "true");
      } else {
        LOCAL_STORAGE.removeStringSync(STORAGE_KEYS.LOGIN__REMEMBER_ME);
      }
      for (const kv of [
        [STORAGE_KEYS.TOKEN, token],
        [STORAGE_KEYS.USER__ID, String(userId)],
      ]) {
        const [key, value] = kv;
        MEMORY_STORAGE.setStringSync(key, value);
        SESSION_STORAGE.setStringSync(key, value);
        if (rememberMe) {
          LOCAL_STORAGE.setStringSync(key, value);
        }
      }
    },
    isRememberMe,
    isLoggedIn() {
      return (
        Boolean(MEMORY_STORAGE.getStringSync(STORAGE_KEYS.TOKEN)) ||
        Boolean(SESSION_STORAGE.getStringSync(STORAGE_KEYS.TOKEN)) ||
        Boolean(LOCAL_STORAGE.getStringSync(STORAGE_KEYS.TOKEN))
      );
    },
    getAnonId() {
      let value = LOCAL_STORAGE.getStringSync(STORAGE_KEYS.ANON__ID);
      if (!value) {
        value = uuid();
        LOCAL_STORAGE.setStringSync(STORAGE_KEYS.ANON__ID, value);
      }
      return value;
    },
    getUserId(): number | undefined {
      for (const storage of [MEMORY_STORAGE, SESSION_STORAGE, LOCAL_STORAGE]) {
        const value = storage.getStringSync(STORAGE_KEYS.USER__ID);
        const userId = Number(value);
        if (isFinite(userId)) {
          return userId;
        }
      }
      return undefined;
    },
  };
};
