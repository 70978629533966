import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HBQueryClient } from "api/HBQueryClient";
import { useResetRouterScroll } from "common/hooks/useResetRouterScroll";
import { useWindowsInnerHeight } from "common/hooks/useWindowsInnerHeight";
import { isTouchDevice } from "common/utils/isTouchDevice";
import { SentryReplayDevtools } from "components/SentryReplayController/SentryReplayControllerLazy";
import { SidebarForms } from "components/SidebarForms/SidebarForms";
import { SystemSnackbars } from "components/SystemSnackbars/SystemSnackbars";
import { IS_STAGING } from "constants/env";
import { GlobalGuards } from "core/GlobalGuards/GlobalGuards";
import { GlobalStateProvider } from "core/state/global/GlobalStateProvider";
import { TelemetryProvider, useNavigationTelemetry } from "core/telemetry";
import { GuideMetaContextProvider } from "pages/Guides/components/GuideMetaContextProvider/GuideMetaContextProvider";
import { FC, PropsWithChildren } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { HelmetProvider } from "react-helmet-async";
import { homebaseTheme } from "styles/themes/homebase_theme";

import { useIOSMeta } from "./hooks/useIOSMeta";

export const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  useWindowsInnerHeight();
  useNavigationTelemetry();
  useResetRouterScroll();
  useIOSMeta();

  const dnBackend = isTouchDevice() ? TouchBackend : HTML5Backend;

  return (
    <TelemetryProvider>
      <ThemeProvider theme={homebaseTheme}>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <QueryClientProvider client={HBQueryClient}>
              {process.env.NODE_ENV !== "production" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
              {IS_STAGING && <SentryReplayDevtools />}
              <DndProvider backend={dnBackend}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <GlobalStateProvider>
                    <GuideMetaContextProvider>
                      <GlobalGuards>{children}</GlobalGuards>
                    </GuideMetaContextProvider>
                    <SidebarForms />
                    <SystemSnackbars />
                  </GlobalStateProvider>
                </LocalizationProvider>
              </DndProvider>
            </QueryClientProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </ThemeProvider>
    </TelemetryProvider>
  );
};
