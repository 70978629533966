import { AppDataV4 } from "./models/AppDataV4";
import { AppDataV5 } from "./models/AppDataV5";

export function mapV4ToV5(data: AppDataV4): AppDataV5 {
  const {
    propertyAddition: { property, roomsAndSpaces },
    ...rest
  } = data.state.data;

  return {
    state: {
      version: 5,
      data: {
        ...rest,
        property,
        rooms: roomsAndSpaces,
      },
    },
  };
}
