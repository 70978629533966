import { useSelector } from "@xstate/react";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";

const orchestratorEphemeralSelector = (state: {
  context: OrchestratorMachineContext;
}) => {
  return state.context.ephemeral;
};

export const useOrchestratorEphemeral = () => {
  const { orchestrator } = useGlobalState();
  return useSelector(orchestrator, orchestratorEphemeralSelector);
};
