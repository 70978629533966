import { presetCategoryData } from "api/static/getPresetCategoryData";
import { transform } from "api/utils";
import { find } from "lodash-es";
import { uuid } from "shared/util/uuid";

import { AppDataV8 } from "./models/AppDataV8";
import { AppDataV9 } from "./models/AppDataV9";

type TasksType =
  AppDataV9["state"]["data"]["properties"][0]["hips"][0]["scopeOfWork"]["guides"];

type ItemType =
  AppDataV9["state"]["data"]["properties"][0]["hips"][0]["scopeOfWork"]["guides"][0]["itemType"];

export function mapV8ToV9(data: AppDataV8): AppDataV9 {
  return transform(data, (result) => {
    result.state.version = 9;

    result.state.data.properties.forEach((property) => {
      property.hips.forEach((hips) => {
        const addedTasks: TasksType = [];
        if (hips.scopeOfWork && hips.scopeOfWork.guides) {
          hips.scopeOfWork.guides
            .filter((guide) => Boolean(guide.preset))
            .forEach((guide) => {
              const preset = find(presetCategoryData, {
                id: guide.preset.presetId,
              });
              const items = find(preset.items, {
                id: guide.preset.presetItemId,
              });
              items.tasks.forEach((presetItemTask) => {
                addedTasks.push({
                  id: uuid(),
                  intents: presetItemTask.intents,
                  itemType: presetItemTask.subCategory as string as ItemType,
                  roomId: guide.roomId,
                  type: presetItemTask.category,
                  preset: guide.preset,
                });
              });
            });

          hips.scopeOfWork.guides.push(...addedTasks);
        }
      });
    });
  });
}
