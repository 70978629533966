import { transform } from "api/utils";

import { AppDataV12 } from "./models/AppDataV12";
import { AppDataV13 } from "./models/AppDataV13";

export function mapV12ToV13(data: AppDataV12): AppDataV13 {
  return transform(data, (result) => {
    result.state.version = 13;
    if (result.state.data.getEducated) {
      result.state.data.getEducated.bookmarks = [];
    }
  });
}
