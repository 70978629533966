export const scrollToSmoothly = (
  container: HTMLElement,
  newPosition: number,
  { duration = 300, isVertical = false }
) => {
  const { currentPosition, targetPosition } = getPositions(
    container,
    newPosition,
    isVertical
  );
  const start = window.performance.now();

  window.requestAnimationFrame(function step(currentTime) {
    const timeFraction = Math.max(1, (currentTime - start) / duration);
    const isPositive = currentPosition < targetPosition;
    if (isVertical) {
      scrollVertical(
        container,
        currentPosition,
        targetPosition,
        timeFraction,
        isPositive
      );
    } else {
      scrollHorizontal(
        container,
        currentPosition,
        targetPosition,
        timeFraction,
        isPositive
      );
    }

    if (timeFraction < 1) {
      requestAnimationFrame(step);
    }
  });
};

const getPositions = (
  scrollableContainer: HTMLElement,
  newPosition: number,
  isVertical: boolean
) => {
  return isVertical
    ? getVerticalPosition(scrollableContainer, newPosition)
    : getHorizontalPosition(scrollableContainer, newPosition);
};

const getHorizontalPosition = (
  scrollableContainer: HTMLElement,
  newPosition: number
) => {
  const currentPosition = scrollableContainer.scrollLeft;
  const maxPosition = Math.max(
    0,
    scrollableContainer.scrollWidth - scrollableContainer.clientWidth
  );
  const targetPosition = Math.max(0, Math.min(newPosition, maxPosition));
  return {
    currentPosition,
    targetPosition,
  };
};

const getVerticalPosition = (
  scrollableContainer: HTMLElement,
  newPosition: number
) => {
  const currentPosition = scrollableContainer.scrollTop;
  const maxPosition = Math.max(
    0,
    scrollableContainer.scrollHeight - scrollableContainer.clientHeight
  );
  const targetPosition = Math.max(0, Math.min(newPosition, maxPosition));
  return {
    currentPosition,
    targetPosition,
  };
};

const getScrollPositionPositive = (
  currentPosition: number,
  targetPosition: number,
  timeFraction: number
) => currentPosition + (targetPosition - currentPosition) * timeFraction;

const getScrollPositionNegative = (
  currentPosition: number,
  targetPosition: number,
  timeFraction: number
) => currentPosition - (currentPosition - targetPosition) * timeFraction;

const scrollVertical = (
  scrollableContainer: HTMLElement,
  currentPosition: number,
  targetPosition: number,
  timeFraction: number,
  isScrollUp: boolean
) => {
  scrollableContainer.scrollTo(
    0,
    isScrollUp
      ? getScrollPositionPositive(currentPosition, targetPosition, timeFraction)
      : getScrollPositionNegative(currentPosition, targetPosition, timeFraction)
  );
};

const scrollHorizontal = (
  scrollableContainer: HTMLElement,
  currentPosition: number,
  targetPosition: number,
  timeFraction: number,
  isScrollLeft: boolean
) => {
  scrollableContainer.scrollTo(
    isScrollLeft
      ? getScrollPositionPositive(currentPosition, targetPosition, timeFraction)
      : getScrollPositionNegative(
          currentPosition,
          targetPosition,
          timeFraction
        ),
    0
  );
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "instant" });
};

export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
