import { isE2E } from "common/utils/testUtils";
import { EARLY_ACCESS_ENABLED, IS_DEV_ENV } from "constants/env";
import { flatten } from "lodash-es";
import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Navigate } from "router/Navigate";

import { AppProviders } from "./AppProviders";
import { IsSmallScreenGuard } from "./guards/IsSmallScreenGuard";
import { Preloader } from "./Preloader";
import { HOME, NOT_FOUND, ROUTES } from "./routes";

const flattenRoutes = flatten(ROUTES);
export const HomebaseRouter: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <AppProviders>
          <RoutesComponent />
        </AppProviders>
      </Suspense>
    </BrowserRouter>
  );
};

const RoutesComponent = () => {
  const { pathname, search } = useLocation();
  const removeTrailingSlash = pathname !== "/" && pathname.endsWith("/");

  if (removeTrailingSlash) {
    const path = `${pathname.slice(0, -1)}${search}`;
    return <Navigate to={path} replace />;
  }

  return (
    <Routes>
      {flattenRoutes
        .filter((route) => (IS_DEV_ENV || isE2E() ? true : route.live))
        .map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <IsSmallScreenGuard disabled={route.mobile}>
                <Preloader route={route} />
              </IsSmallScreenGuard>
            }
          />
        ))}
      <Route
        path="*"
        element={
          <Navigate to={EARLY_ACCESS_ENABLED ? HOME() : NOT_FOUND()} replace />
        }
      />
    </Routes>
  );
};
