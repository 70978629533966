import { useQuery } from "@tanstack/react-query";
import { ACCOUNT_DETAILS_QUERY_KEY } from "api/constants";
import { getUserDetails } from "api/homebase.api";
import { useIdentifyUser } from "core/telemetry";

export const useAccountDetails = (isEnabled: boolean = true) => {
  const identifyUser = useIdentifyUser();

  return useQuery({
    queryKey: [ACCOUNT_DETAILS_QUERY_KEY],
    queryFn: async () => {
      const data = await getUserDetails();
      identifyUser(data.id, { is_professional: data.is_professional });
      return data;
    },
    staleTime: Infinity,
    enabled: isEnabled,
  });
};
