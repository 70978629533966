import { ContactUs } from "api/models/ContactUs";
import { TFunction } from "react-i18next";
import * as yup from "yup";

const MAX_SYMBOLS = 256;

const getMessageRequiredMessage = (
  t: TFunction
): Record<ContactUs["type"], string> => {
  return {
    help: t("Message is required"),
    bug: t("Bug description is required"),
    suggestion: t("Suggestion is required"),
  };
};

export const getContactUsValidationSchema = (
  t: TFunction,
  type: ContactUs["type"]
) => {
  const contactUsValidation = yup.object().shape({
    name: yup
      .string()
      .max(
        MAX_SYMBOLS,
        t("Please enter at most {{chars}} characters", {
          chars: MAX_SYMBOLS,
        })
      )
      .required(t("Name is required")),
    email: yup
      .string()
      .max(
        MAX_SYMBOLS,
        t("Please enter at most {{chars}} characters", {
          chars: MAX_SYMBOLS,
        })
      )
      .email(t("Please enter a valid email address"))
      .required(t("Email is required")),
    message: yup.string().required(getMessageRequiredMessage(t)[type]),
  });

  return contactUsValidation;
};
