import { createTheme } from "@mui/material";
import { rem } from "common/utils/cssUtils";
import colors from "styles/_jscolors.module.scss";

import {
  getBreakpoint,
  getMdScreenMediaStyle,
  getSmScreenMediaStyle,
  getXsScreenMediaStyle,
} from "./utils";

export const homebaseTheme = createTheme({
  breakpoints: {
    values: {
      xs: getBreakpoint("xs"),
      sm: getBreakpoint("sm"),
      md: getBreakpoint("md"),
      lg: getBreakpoint("lg"),
      xl: getBreakpoint("xl"),
    },
  },
  palette: {
    primary: {
      main: colors.mainBlue,
    },
  },
  typography: {
    h1: {
      fontSize: "44px",
      fontWeight: "550",
      lineHeight: "normal",
      letterSpacing: 0,
      color: colors.mainBlack,
      fontFamily: "Urbanist, Helvetica, Arial, sans-serif",
      "@supports (font-variation-settings: normal)": {
        fontFamily: "UrbanistVariable, Helvetica, Arial, sans-serif",
        fontFeatureSettings: "'clig' off, 'liga' off",
      },
      ...getXsScreenMediaStyle({ fontSize: "40px" }),
      ...getSmScreenMediaStyle({ fontSize: "38px" }),
      ...getMdScreenMediaStyle({ fontSize: "44px" }),
    },
    h2: {
      fontSize: "38px",
      fontWeight: "550",
      lineHeight: "normal",
      letterSpacing: 0,
      color: colors.mainBlack,
      fontFamily: "Urbanist, Helvetica, Arial, sans-serif",
      "@supports (font-variation-settings: normal)": {
        fontFamily: "UrbanistVariable, Helvetica, Arial, sans-serif",
        fontFeatureSettings: "'clig' off, 'liga' off",
      },
      ...getXsScreenMediaStyle({ fontSize: "34px" }),
      ...getSmScreenMediaStyle({ fontSize: "32px" }),
      ...getMdScreenMediaStyle({ fontSize: "38px" }),
    },
    h3: {
      fontSize: "32px",
      fontWeight: "550",
      lineHeight: "normal",
      letterSpacing: 0,
      color: colors.mainBlack,
      fontFamily: "Urbanist, Helvetica, Arial, sans-serif",
      "@supports (font-variation-settings: normal)": {
        fontFamily: "UrbanistVariable, Helvetica, Arial, sans-serif",
      },
      ...getXsScreenMediaStyle({ fontSize: "28px" }),
      ...getSmScreenMediaStyle({ fontSize: "28px" }),
      ...getMdScreenMediaStyle({ fontSize: "32px" }),
    },
    h4: {
      fontSize: "28px",
      fontWeight: "550",
      lineHeight: "normal",
      letterSpacing: 0,
      color: colors.mainBlack,
      fontFamily: "Urbanist, Helvetica, Arial, sans-serif",
      "@supports (font-variation-settings: normal)": {
        fontFamily: "UrbanistVariable, Helvetica, Arial, sans-serif",
      },
      ...getXsScreenMediaStyle({ fontSize: "24px" }),
      ...getSmScreenMediaStyle({ fontSize: "24px" }),
      ...getMdScreenMediaStyle({ fontSize: "28px" }),
    },
    h5: {
      fontSize: "24px",
      fontWeight: "550",
      lineHeight: "normal",
      letterSpacing: 0,
      color: colors.mainBlack,
      fontFamily: "Urbanist, Helvetica, Arial, sans-serif",
      "@supports (font-variation-settings: normal)": {
        fontFamily: "UrbanistVariable, Helvetica, Arial, sans-serif",
      },
      ...getXsScreenMediaStyle({ fontSize: "18px" }),
      ...getSmScreenMediaStyle({ fontSize: "20px" }),
      ...getMdScreenMediaStyle({ fontSize: "24px" }),
    },
    body1: {
      fontSize: rem("18px"),
      fontWeight: "400",
      lineHeight: rem("24px"),
      letterSpacing: 0,
      color: colors.mainBlack,
    },
    body2: {
      fontSize: rem("16px"),
      fontWeight: "500",
      lineHeight: rem("15px"),
      letterSpacing: 0,
      color: colors.mainBlack,
    },
    caption: {
      fontSize: rem("14px"),
      fontWeight: "400",
      lineHeight: rem("18px"),
      color: colors.mainBlue,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: rem("13.12px"),
      fontWeight: "500",
      lineHeight: rem("15.04px"),
      letterSpacing: 0,
      color: colors.mainBlack,
    },
    //@ts-ignore
    p: {
      fontSize: rem("18px"),
      fontWeight: "400",
      lineHeight: rem("24px"),
      letterSpacing: 0,
      color: colors.mainBlack,

      "@media screen and (max-width: 480px)": {
        fontSize: rem("16px"),
        lineHeight: rem("20px"),
      },
    },
    p2: {
      fontSize: rem("16px"),
      fontWeight: "500",
      lineHeight: rem("19.2px"),
      letterSpacing: 0,
      color: colors.mainBlack,

      "@media screen and (max-width: 480px)": {
        fontSize: rem("16px"),
        fontWeight: "400",
        lineHeight: rem("20px"),
      },
    },
    "p.caption": {
      fontSize: rem("14px"),
      fontWeight: "400",
      letterSpacing: 0,
      color: colors.mainBlack,
      lineHeight: rem("18px"),
    },
    "p.caption2": {
      fontSize: rem("13.12px"),
      fontWeight: "500",
      letterSpacing: 0,
      color: colors.mainBlack,
      lineHeight: rem("15.04px"),
    },
    p3: {
      fontSize: rem("16px"),
      lineHeight: rem("19px"),
      letterSpacing: 0,
      fontWeight: "400",
      color: colors.mainBlack,
    },
    p4_g13: {
      fontSize: rem("14px"),
      lineHeight: rem("16px"),
      letterSpacing: 0,
      fontWeight: "400",
      color: colors.colorGrey13,

      "@media screen and (max-width: 480px)": {
        fontSize: rem("12px"),
        lineHeight: rem("22px"),
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          letterSpacing: 0,
        },
        contained: {
          backgroundColor: colors.mainBlue,
          textTransform: "capitalize",
          color: colors.buttonTextColor,
          borderRadius: rem("4px"),
          padding: `${rem("12px")} ${rem("20px")}`,
          fontSize: rem("18px"),
          fontWeight: "500",
          lineHeight: rem("22px"),
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
          transition: "unset",
          "&:hover": {
            background: colors.colorButtonPrimaryHover,
          },
          "@media screen and (max-width: 480px)": {
            fontSize: rem("16px"),
            lineHeight: rem("17px"),
            paddingTop: rem("16px"),
            paddingBottom: rem("16px"),
            minWidth: "100%",
          },
        },
        text: {
          textTransform: "none",
          color: colors.colorGrey13,
          fontWeight: 500,
          fontSize: rem("12px"),
          lineHeight: rem("14px"),
          padding: "0 8px 0 0",
          minWidth: "unset",
          "&:hover": {
            backgroundColor: "unset",
          },
        },
        outlined: {
          border: "1px solid",
          borderColor: colors.colorGrey7,
          borderRadius: rem("5px"),
          padding: `${rem("9px")} ${rem("14px")}`,
          fontSize: rem("14px"),
          lineHeight: rem("18px"),
          color: colors.mainBlack,
          fontWeight: "600",
          textTransform: "unset",
          "&:hover": {
            border: "1px solid",
            borderColor: colors.colorButtonPrimaryOutlinedBorder,
            backgroundColor: colors.colorButtonPrimaryHoverBackground,
          },
          "@media screen and (max-width: 900px)": {
            fontSize: rem("14px"),
            lineHeight: rem("16px"),
          },
          "@media screen and (max-width: 480px)": {
            fontSize: rem("12px"),
            lineHeight: rem("13px"),
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
          backgroundColor: colors.colorGrey4,
          borderRadius: rem("5px"),
          "& .Mui-focused": {
            backgroundColor: "#ffffff",
          },
          "&:hover": {
            backgroundColor: colors.colorGrey12,
          },
          "& .MuiOutlinedInput-root": {
            padding: "5px 6px",
            fontSize: rem("16px"),
            fontWeight: "400",
            lineHeight: rem("19.2px"),
            letterSpacing: 0,

            "@media screen and (max-width: 480px)": {
              height: rem("56px"),
              fontSize: `${rem("16px")} !important`,
            },
          },

          "& .Mui-focused fieldset": {
            borderWidth: `${rem("1px")} !important`,
            borderRadius: rem("4px"),
          },

          "&.focus-blue-border": {
            "& fieldset": {
              borderColor: `${colors.mainBlue} !important`,
            },
          },

          "& label": {
            fontSize: rem("14px"),
            fontWeight: 400,
            letterSpacing: rem("0.15px"),
            paddingTop: rem("6px"),
            paddingLeft: rem("2px"),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
          marginBottom: rem("10px"),
          "& .MuiOutlinedInput-root": {
            padding: "5px 12px",
            fontSize: rem("16px"),
            fontWeight: "400",
            lineHeight: rem("19.2px"),
            letterSpacing: 0,

            "@media screen and (max-width: 480px)": {
              height: rem("56px"),
            },
          },
          "& .MuiOutlinedInput-input": {
            paddingRight: "6px !important",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.colorGrey6}`,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: rem("1px"),
            borderRadius: rem("4px"),
            borderColor: `${colors.colorGrey6}`,
          },
        },
        paper: {
          boxShadow: "unset",
          "& ul": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          marginTop: `${rem("21px")}`,
          border: "1px solid",
          borderColor: `${colors.colorGrey6}`,
          borderRadius: rem("4px"),
          "@media screen and (max-width: 1024px)": {
            marginTop: "6px",
          },
          "@media screen and (max-width: 480px)": {
            marginTop: "10px",
          },
        },
        listbox: {
          padding: 0,
          "@media screen and (max-width: 480px)": {
            maxHeight: "100%",
          },

          "& .MuiAutocomplete-option": {
            padding: `${rem("10px")} ${rem("18px")}`,
            "@media screen and (max-width: 1024px)": {
              paddingTop: `${rem("9px")}`,
              paddingBottom: `${rem("9px")}`,
              paddingLeft: `${rem("10px")}`,
              minHeight: "unset !important",
            },
            "@media screen and (max-width: 480px)": {
              paddingTop: `${rem("11px")}`,
              paddingBottom: `${rem("11px")}`,
              paddingLeft: `${rem("11px")}`,
            },
          },
        },
        option: {
          fontSize: rem("16px"),
          fontWeight: "400",
          lineHeight: rem("19.2px"),
          letterSpacing: 0,
          color: colors.mainBlack,
          "@media screen and (max-width: 1024px)": {
            fontSize: rem("15px"),
          },
          "@media screen and (max-width: 480px)": {
            fontSize: rem("16px"),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textAlign: "center",
          maxWidth: rem("317px"),
          fontSize: rem("14px"),
          lineHeight: rem("16px"),
          color: "white",
          padding: `${rem("6px")} ${rem("7px")}`,
          fontWeight: 400,
          background: colors.colorGrey800,
          boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);",
        },
        arrow: {
          color: colors.colorGrey800,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: rem("42px"),
          letterSpacing: 0,
          fontSize: rem("16px"),
          lineHeight: rem("22px"),
          fontWeight: "400",
          color: colors.mainBlack,
          backgroundColor: colors.colorGrey4,
          "&.Mui-focused": {
            backgroundColor: "#ffffff",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.mainBlue}`,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1.6px !important",
            borderColor: `${colors.colorErrorMain} !important`,
          },
          "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.colorGrey6}`,
          },
        },
        notchedOutline: {
          borderColor: `${colors.colorGrey6}`,
          borderWidth: "1px !important",
          borderRadius: rem("4px"),
        },
        input: {
          padding: rem("10px"),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
          minWidth: `${rem("100px")} !important`,
          paddingTop: 0,
          paddingBottom: 0,
          boxShadow:
            "0 1px 14px 0 #0000001f, 0 5px 8px 0 #00000024, 0 3px 5px -1px #0003",
        },
        message: {
          height: "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        action: {
          marginRight: "0",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0 8px 0 0",
          transform: "scale(0.8)",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "-3px",
          fontSize: rem("16px"),
          fontWeight: "500",
          lineHeight: rem("15px"),
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "none",
          backgroundColor: colors.colorModalBackdrop,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: rem("16px"),
          textTransform: "capitalize",
          top: "-5px",
          color: `${colors.colorGrey500}`,
          fontWeight: "400",

          "&.Mui-focused": {
            top: "0",
            color: `${colors.mainBlue}`,
            backgroundColor: "#ffffff",
          },
          "&.MuiFormLabel-filled": {
            top: "0",
            backgroundColor: "#ffffff",
          },
          "&.Mui-error": {
            color: `${colors.colorErrorMain}`,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.colorGrey300}`,
          minHeight: rem("74px"),
          padding: `0 ${rem("25px")}`,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.colorGrey300}`,
          padding: rem("15px"),
        },
      },
    },
  },
});
