import { transform } from "api/utils";

import { AppDataV11 } from "./models/AppDataV11";
import { AppDataV12 } from "./models/AppDataV12";

export function mapV11ToV12(data: AppDataV11): AppDataV12 {
  return transform(data, (result) => {
    result.state.version = 12;

    result.state.data.hasSeenHipTour =
      window.localStorage.getItem("homebase/hips__tour_shown") === "true";
  });
}
