import { wait } from "common/utils/promiseUtils";
import { API_BASE } from "constants/env";
import { SECONDS } from "constants/time";
import { getTokenStore } from "core/persistence";
import produce, { Draft } from "immer";
import { parseRetryAfter } from "shared/util/retryAfter";

import { SET_ERROR_IF_RETRY_DELAY_IS_TOO_LONG } from "./constants";
import { addToast, systemSnackbarMachineActor } from "./SystemSnackbarMachine";

export interface ResponseWrapper<T> {
  status: number;
  content?: T;
  headers: Headers;
  errors?: string[];
}

export const fetchRequest = (input: RequestInfo | URL, init?: RequestInit) => {
  // eslint-disable-next-line no-restricted-globals
  return fetch(input, init).then((res) => {
    // Note(Andrei): For now, we just need to check for too many requests errors
    // but in the future we may want to adjust it to observe whatever we need.
    if (
      res.status === 429 &&
      parseRetryAfter(res) > SET_ERROR_IF_RETRY_DELAY_IS_TOO_LONG
    ) {
      systemSnackbarMachineActor.send(
        addToast({
          variant: "tooManyRequests",
        })
      );
    }
    return res;
  });
};

export const fetchWithAuth = async <T>({
  path,
  init = {},
  defaultValue = undefined,
}: {
  path: string;
  init?: RequestInit;
  defaultValue?: ResponseWrapper<T>;
}): Promise<ResponseWrapper<T>> => {
  const tokenStore = getTokenStore();
  const token = tokenStore.getToken();

  if (!token && defaultValue) {
    return defaultValue;
  }

  const response = await fetchRequest(API_BASE + path, {
    ...init,
    headers: {
      ...init.headers,
      Authorization: `token ${token}`,
    },
  });

  const retVal: ResponseWrapper<T> = {
    status: response.status,
    content: undefined,
    headers: response.headers,
  };

  if (response.status === 200 || response.status === 201) {
    retVal.content = await response.json();
  }

  if (response.status >= 200 && response.status < 300) {
    return retVal;
  }

  if (response.status >= 400) {
    retVal.errors = await response.json();
  }

  throw retVal;
};

interface PollConfig {
  path?: string;
  maxAttempts: number;
  pollIntervalMs: number;
  pollStatusCodes: number[];
  successStatusCodes: number[];
}

const DEFAULT_POLL_CONFIG: PollConfig = {
  maxAttempts: 60,
  pollIntervalMs: 0.5 * SECONDS,
  pollStatusCodes: [202, 429],
  successStatusCodes: [200, 201],
};

export const executePoll = async <T>(
  _response: ResponseWrapper<void>,
  _config: Partial<PollConfig> = {},
  _init?: Partial<RequestInit>
): Promise<ResponseWrapper<T>> => {
  const config = { ...DEFAULT_POLL_CONFIG, ..._config };

  let response: ResponseWrapper<T | void> = _response;

  for (let i = 0, path = undefined as string; i <= config.maxAttempts; i++) {
    const pollIntervalMs = parseRetryAfter(response) ?? config.pollIntervalMs;
    await wait(pollIntervalMs);

    path ??= config.path || response.headers.get("location");

    try {
      response = await fetchWithAuth<T>({ path, init: _init });
    } catch (e) {
      response = e;
    }

    if (config.successStatusCodes.includes(response.status)) {
      return response as ResponseWrapper<T>;
    }

    if (!config.pollStatusCodes.includes(response.status)) {
      break;
    }
  }

  throw response;
};

export const transform = <TIn, TOut>(
  data: TIn,
  recipe: (result: Draft<TOut>) => void
): TOut => {
  // NOTE(clemens): there's an issue in the immer typings which makes it
  //  impossible to type a produce call which transforms type A to type B
  //  without unsafely casting the input
  //  see https://stackoverflow.com/q/73554880
  const base = data as never;
  return produce<TOut>(base, recipe);
};
