import { NonEmptyArray } from "common/utils/typeUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { AbstractGuideItem } from "constants/guides/GuideItems.types";
import { Connection, ConnectionType } from "pages/Guides/types";

interface AddConnectionParams extends Connection {
  directionMatters?: boolean;
}

interface ConnectToListItem {
  id: string;
  type: ConnectionType;
}

interface IsConnectedParams {
  fromId: Connection["fromId"];
  toId: Connection["toId"];
  directionMatters?: boolean;
}

const isItemsConnected = (
  connection: Connection,
  params: IsConnectedParams
): boolean => {
  const { fromId, toId, directionMatters } = params;

  if (directionMatters) {
    return connection.fromId === fromId && connection.toId === toId;
  } else {
    return (
      (connection.fromId === fromId && connection.toId === toId) ||
      (connection.fromId === toId && connection.toId === fromId)
    );
  }
};

export const isConnected = (
  connections: Connection[],
  params: IsConnectedParams
) => {
  return connections.some((connection) => isItemsConnected(connection, params));
};

export const isItemConnected = (
  connection: Connection,
  id: AbstractGuideItem["id"]
) => connection.fromId === id || connection.toId === id;

export const isConnectedToAnything = (
  connections: Connection[],
  id: string
): boolean => {
  return connections.some((connection) => isItemConnected(connection, id));
};

export const isConnectedToSomethingInTheList = (
  connections: Connection[],
  id: string,
  list: AbstractGuideItem[]
): boolean => {
  return list.some((item) =>
    connections.some((connection) =>
      isItemsConnected(connection, { fromId: id, toId: item.id })
    )
  );
};

export const addConnections = (
  connections: Connection[],
  paramsList: AddConnectionParams[]
): Connection[] => {
  const newConnections = [...connections];
  for (const params of paramsList) {
    const { directionMatters, ...connection } = params;

    if (!isConnected(newConnections, params)) {
      newConnections.push(connection);
    }
  }

  return newConnections;
};

export const connectToList = (
  connections: Connection[],
  item: ConnectToListItem,
  connectToList: ConnectToListItem[]
): Connection[] => {
  const newConnections: Connection[] = [];

  for (const listItem of connectToList) {
    if (!isConnected(connections, { fromId: item.id, toId: listItem.id })) {
      newConnections.push({
        fromId: item.id,
        toId: listItem.id,
        fromType: item.type,
        toType: listItem.type,
      });
    }
  }

  return [...connections, ...newConnections];
};

export const removeConnections = (
  connections: Connection[],
  paramsList: IsConnectedParams[]
): Connection[] => {
  let newConnections = connections;

  for (const params of paramsList) {
    newConnections = newConnections.filter(
      (connection) => !isItemsConnected(connection, params)
    );
  }

  return newConnections;
};

export const disconnectFromAll = (connections: Connection[], id: string) => {
  const disconnected: Connection[] = [];
  const remaining: Connection[] = [];

  for (const connection of connections) {
    if (connection.fromId !== id && connection.toId !== id) {
      remaining.push(connection);
    } else {
      disconnected.push(connection);
    }
  }

  return {
    remaining,
    disconnected,
  };
};

const isConnectionBetweenCategories = (
  connection: Connection,
  type1: GuideItemTypeCategory,
  type2: GuideItemTypeCategory
) => {
  return (
    (connection.fromType === type1 && connection.toType === type2) ||
    (connection.toType === type1 && connection.fromType === type2)
  );
};

interface RemoveEverythingConnectedToParams<T extends AbstractGuideItem> {
  connectedTo: AbstractGuideItem;
  items: T[];
  connections: Connection[];
  categories?: GuideItemTypeCategory | NonEmptyArray<GuideItemTypeCategory>;
}

export const removeEverythingConnectedTo = <T extends AbstractGuideItem>(
  params: RemoveEverythingConnectedToParams<T>
) => {
  const { connectedTo, items, connections, categories } = params;
  const categoriesList = Array.isArray(categories)
    ? categories
    : [categories].filter(Boolean);

  const { disconnected } = disconnectFromAll(connections, connectedTo.id);
  const connectionsToRemove = categories
    ? disconnected.filter((connection) => {
        return categoriesList.some((category) =>
          isConnectionBetweenCategories(
            connection,
            connectedTo.category,
            category
          )
        );
      })
    : disconnected;

  const itemsToRemove = connectionsToRemove
    .map((connection) => {
      const targetId =
        connection.fromId === connectedTo.id
          ? connection.toId
          : connection.fromId;

      return items.find((item) => item.id === targetId);
    })
    .filter(Boolean);

  return {
    itemsToRemove,
    connectionsToRemove,
  };
};

export const isRoomConnection = (connection: Connection) =>
  connection.fromType === "room" || connection.toType === "room";

type Circuit = {
  controls: { category: ConnectionType; id: string }[];
  fixtures: { category: ConnectionType; id: string }[];
};

const groupItemsByFrom = (connections: Connection[]) => {
  const fromToMap: {
    [key: string]: Circuit;
  } = {};

  const length = connections.length;
  for (let i = 0; i < length; i++) {
    const connection = connections[i];
    const fromId = connection.fromId;

    const items = fromToMap[fromId];
    if (items === undefined) {
      fromToMap[fromId] = {
        controls: [{ category: connection.fromType, id: connection.fromId }],
        fixtures: [{ category: connection.toType, id: connection.toId }],
      };
    } else {
      fromToMap[fromId].fixtures.push({
        category: connection.toType,
        id: connection.toId,
      });
    }
  }
  return Object.values(fromToMap);
};

export const buildCircuits = (connections: Connection[]) => {
  const circuitsList = new Array<Circuit>();
  let itemGroups = groupItemsByFrom(connections);

  while (itemGroups.length > 0) {
    const group = itemGroups.shift();
    const groupFixtures = group.fixtures;
    const intersections = [];

    for (let i = 0; i < itemGroups.length; i++) {
      const otherFixtures = itemGroups[i].fixtures;
      const hasIntersection = otherFixtures.some((otherFixture) =>
        groupFixtures.some(
          (groupFixture) => otherFixture.id === groupFixture.id
        )
      );
      if (hasIntersection) {
        const newFixtures = otherFixtures.filter((otherFixture) =>
          groupFixtures.every(
            (groupFixture) => otherFixture.id !== groupFixture.id
          )
        );
        group.controls.push(itemGroups[i].controls[0]);
        group.fixtures.push(...newFixtures);
        intersections.push(i);
      }
    }
    //Note(Adam): If we find another group with the same fixtures, we merge them and put them back in the list to re compare with every other group.
    //If we don't find another group with intersecting fixtures, we can safely remove from list and continue
    if (intersections.length > 0) {
      itemGroups = itemGroups.filter(
        (_, index) => !intersections.includes(index)
      );
      itemGroups.unshift(group);
    } else {
      circuitsList.push(group);
    }
  }
  return circuitsList;
};

export const findCircuitByFixture = (
  fixtureId: string,
  circuits: Circuit[]
) => {
  const circuitsLength = circuits.length;
  for (let i = 0; i < circuitsLength; i++) {
    const circuit = circuits[i];
    const fixtures = circuit.fixtures;
    if (fixtures.some((fixture) => fixture.id === fixtureId)) {
      return circuit;
    }
  }

  return undefined;
};

export const findCircuitByControl = (
  controlId: string,
  circuits: Circuit[]
) => {
  const circuitsLength = circuits.length;
  for (let i = 0; i < circuitsLength; i++) {
    const circuit = circuits[i];
    const controls = circuit.controls;
    if (controls.some((control) => control.id === controlId)) {
      return circuit;
    }
  }

  return undefined;
};
