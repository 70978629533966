import { SidebarInput } from "components/shared/SidebarForm/SidebarInput/SidebarInput";
import { SidebarActions } from "components/SidebarForms/components/SidebarBaseForm/SidebarActions/SidebarActions";
import { useFormik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SidebarContactUsInputs.module.scss";

interface Props {
  values: Record<string, string>;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
  onChange: ReturnType<typeof useFormik>["handleChange"];
  onBlur: ReturnType<typeof useFormik>["handleBlur"];
  messagePlaceholder: string;
  messageLabel: string;
  onSubmit: () => void;
  onCancel: () => void;
}
const DTI = "SidebarContactUsInputs";

export const SidebarContactUsInputs: FC<Props> = ({
  errors,
  touched,
  values,
  onBlur,
  onChange,
  messagePlaceholder,
  messageLabel,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <SidebarInput
        name="name"
        label={t("Name")}
        errorText={errors.name}
        value={values.name}
        touched={touched.name}
        fullWidth
        placeholder={t("Name")}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={`${DTI}__NameInput`}
      />
      <SidebarInput
        name="email"
        label={t("Your Email")}
        errorText={errors.email}
        value={values.email}
        touched={touched.email}
        inputMode="email"
        fullWidth
        placeholder={t("Your Email")}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={`${DTI}__EmailInput`}
      />
      <SidebarInput
        name="message"
        label={values.message && messageLabel}
        errorText={errors.message}
        value={values.message}
        touched={touched.message}
        fullWidth
        placeholder={messagePlaceholder}
        onChange={onChange}
        onBlur={onBlur}
        className={styles.textareaWrapper}
        multiline
        isTextarea
        data-testid={`${DTI}__MessageInput`}
      />

      <SidebarActions
        data-testid={DTI}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonText={t("Send")}
      />
    </>
  );
};
