import { uuid } from "shared/util/uuid";

import { AppDataV1 } from "./models/AppDataV1";
import { AppDataV2 } from "./models/AppDataV2";

export function mapV1ToV2(data: AppDataV1): AppDataV2 {
  return {
    state: {
      version: 2,
      data: {
        ...data.state.data,
        hips: data.state.data.hips
          ? [
              {
                id: uuid(),
                ...data.state.data.hips,
              },
            ]
          : [],
      },
    },
  };
}
