import { Drawer, DrawerProps } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/icons/buttons/close.svg";
import clsx from "clsx";
import { HBIconButton } from "components/HBIconButton/HBIconButton";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "styles/themes/useScreenSize";

import styles from "./SidebarForm.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children: ReactNode;
  className?: string;
  hideHeader?: boolean;
}

const slotProps: DrawerProps["slotProps"] = {
  backdrop: { className: styles.backdrop },
};
const DTI = "SidebarForm";

const classes: DrawerProps["classes"] = { paper: styles.paper };

export const SidebarForm: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  children,
  className,
  hideHeader,
}) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      classes={classes}
      slotProps={slotProps}
      anchor={screenSize === "xs" ? "bottom" : "left"}
    >
      {!hideHeader && (
        <div className={styles.header}>
          <HBIconButton
            title={t("Close")}
            onClick={onClose}
            disableRipple
            className={styles.close}
            data-testid={`${DTI}__CloseButton`}
          >
            <CloseIcon />
          </HBIconButton>
        </div>
      )}
      <form onSubmit={onSubmit} className={clsx(styles.content, className)}>
        {children}
      </form>
    </Drawer>
  );
};
