import { Typography } from "@mui/material";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as ContactIcon } from "assets/icons/contact_support.svg";
import { SidebarLoading } from "components/shared/SidebarForm/SidebarLoading/SidebarLoading";
import { SidebarSuccess } from "components/shared/SidebarForm/SidebarSuccess/SidebarSuccess";
import { SidebarBaseForm } from "components/SidebarForms/components/SidebarBaseForm/SidebarBaseForm";
import { SidebarContactUsInputs } from "components/SidebarForms/components/SidebarContactUsInputs/SidebarContactUsInputs";
import { useContactUs } from "components/SidebarForms/hooks/useContactUs";
import { useContactUsForm } from "components/SidebarForms/hooks/useContactUsForm";
import { CONTACT_US_EMAIL } from "constants/config";
import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./SidebarHelp.module.scss";
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const translationValues = { email: CONTACT_US_EMAIL };

export const SidebarHelp: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { mutate, isPending, isSuccess, reset } = useContactUs("help");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useContactUsForm("help", mutate);
  useEffect(() => {
    if (isOpen) {
      resetForm();
      reset();
    }
  }, [isOpen, reset, resetForm]);
  return (
    <SidebarBaseForm
      Icon={ContactIcon}
      title={t("How can we help?")}
      description={
        <div
          className={styles.description}
          data-testid="SidebarBaseForm__Description"
        >
          <Trans
            defaults="<text>Feel free to email us instead at</text><tlink>{{email}}</tlink>"
            values={translationValues}
            components={{
              text: <Typography variant="p" component="p" />,
              tlink: (
                <Typography
                  component="a"
                  variant="p"
                  href={`mailto:${CONTACT_US_EMAIL}`}
                />
              ),
            }}
          />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      inputs={
        <SidebarContactUsInputs
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onBlur={handleBlur}
          onCancel={onClose}
          onSubmit={handleSubmit}
          messageLabel={t("Message")}
          messagePlaceholder={t("Message")}
        />
      }
    >
      {isPending && <SidebarLoading />}
      {isSuccess && (
        <SidebarSuccess
          title={t("Message Received!")}
          description={t(
            "A member of the Nook team will \n respond as soon as possible. "
          )}
          onClose={onClose}
          Icon={CheckIcon}
        />
      )}
    </SidebarBaseForm>
  );
};
