import { TFunction } from "react-i18next";
import { getUserValidationSchema } from "shared/util/getUserValidationSchema";
import * as yup from "yup";

const MAX_SYMBOLS = 128;
const MAX_EMAIL = 254;

const userValidationErrorMessage = (t: TFunction) => ({
  email: {
    max: t("Please enter at most {{chars}} characters", { chars: MAX_EMAIL }),
    email: t("Please enter a valid email address"),
    required: t("Please enter a valid email address"),
  },
  fullName: {
    max: t("Please enter at most {{chars}} characters", { chars: MAX_SYMBOLS }),
    min: t("Please enter at least 1 character"),
    number: t("Numbers not allowed in full name"),
    required: t("Please enter your full name"),
  },
});

export const getEarlyAccessValidationSchema = (t: TFunction) => {
  const userValidationSchema = getUserValidationSchema(
    userValidationErrorMessage(t)
  );

  return yup.object().shape({
    email: userValidationSchema.email,
    name: userValidationSchema.fullName,
    checked: yup
      .boolean()
      .required(
        t("You must agree to our Privacy Policy and Terms of Conditions")
      )
      .oneOf(
        [true],
        t("You must agree to our Privacy Policy and Terms of Conditions")
      ),
  });
};
