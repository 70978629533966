import {
  FormHelperTextProps,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import clsx from "clsx";
import { FC, useMemo } from "react";

import styles from "./SidebarInput.module.scss";

interface Props {
  name: string;
  label: string;
  value: string;
  errorText: string;
  touched: boolean;
  helperText?: string;
  "data-testid"?: string;
  isTextarea?: boolean;
}

const inputProps: Partial<OutlinedInputProps> = {
  className: styles.input,
};

const textareaProps: Partial<OutlinedInputProps> = {
  className: styles.textarea,
};

export const SidebarInput: FC<Props & TextFieldProps> = ({
  name,
  errorText,
  touched,
  value,
  label,
  className,
  helperText,
  isTextarea,
  ...rest
}) => {
  const showError = touched && Boolean(errorText);

  const helperTextProps: Partial<
    FormHelperTextProps & { "data-testid"?: string }
  > = useMemo(
    () => ({
      className: clsx(styles.inputHelper, showError && styles.error),
      "data-testid": "HelperText",
    }),
    [showError]
  );

  return (
    <TextField
      name={name}
      value={value}
      label={value && label}
      variant="outlined"
      className={clsx(
        isTextarea ? styles.textareaWrapper : styles.inputWrapper,
        className
      )}
      FormHelperTextProps={helperTextProps}
      error={showError}
      fullWidth
      helperText={showError ? errorText : helperText}
      InputProps={isTextarea ? textareaProps : inputProps}
      {...rest}
    />
  );
};
