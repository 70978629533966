import { ButtonBase, Typography } from "@mui/material";
import { hardReloadPage } from "common/utils/url.utils";
import { HBSnackbar } from "components/Snackbars/HBSnackbar/HBSnackbar";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import styles from "./NewCodeAvailableNotification.module.scss";

const components = {
  newline: <br />,
};

export const NewCodeAvailableNotification: FC = () => {
  const { t } = useTranslation();

  const newCodeMessage = (
    <Trans
      defaults="We’ve made some updates!<newline />Reload the page to receive them."
      components={components}
    />
  );

  return (
    <HBSnackbar
      open
      rootClassName={styles.snackbarWrapper}
      autoHideDuration={null}
      showIcon={false}
      containerClassName={styles.messageContainer}
      variant="infoAlert"
      action={
        <ButtonBase
          disableRipple
          disableTouchRipple
          onClick={hardReloadPage}
          className={styles.actionButton}
        >
          {t("Reload Page")}
        </ButtonBase>
      }
    >
      <Typography variant="p" component="p" className={styles.messageText}>
        {newCodeMessage}
      </Typography>
    </HBSnackbar>
  );
};
