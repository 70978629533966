import { ROOMS_PIECES_ICONS } from "constants/rooms/roomIcons";
import { isEmpty } from "lodash-es";
import { ALL_TAG_NAMES_FROM_ALL_CATEGORIES } from "pages/Media/constants";
import { ProFilterIcon } from "pages/Media/filters/components/ProFilterIcon";

/**
 * @param {{ order: number; id: string }} a
 * @param {{ order: number; id: string }} b
 * @returns {number}
 */
const tagOrderComparator = (a, b) => {
  const isSimilarOrder = a.order === b.order;
  if (isSimilarOrder) {
    const { removedSuffix: aSuffix } = cleanseTag(a.id);
    const { removedSuffix: bSuffix } = cleanseTag(b.id);
    return Number(aSuffix) - Number(bSuffix);
  }
  return a.order - b.order;
};

/**
 * @param {string} tag
 * @returns {React.FunctionComponent}
 */
const getRoomsAndSpacesIcon = (tag) => {
  const { cleansedTag } = cleanseTag(tag);

  return ROOMS_PIECES_ICONS[cleansedTag];
};

/**
 * @param {import("react-i18next").TFunction} t
 * @param {string} tag
 * @returns {string}
 */
export const getTagNameFromAllCategories = (t, tag) => {
  const tagName = ALL_TAG_NAMES_FROM_ALL_CATEGORIES(t)[tag];

  return tagName || tag;
};

/**
 * Removes the number suffix from the provided tag and returns the cleansed tag
 * and the removed number suffix.
 * e.g. bedroom_1 => {cleansedTag: "bedroom", popped: "1"}.
 *
 * @param {string} tag
 * @returns {{cleansedTag: string, removedSuffix: string}}
 */
export const cleanseTag = (tag) => {
  let cleansedTag = tag;
  let removedSuffix;

  const splitTag = tag.split("_");
  const lastSplitTag = splitTag[splitTag.length - 1];

  if (!isNaN(parseInt(lastSplitTag))) {
    removedSuffix = lastSplitTag;
    splitTag.pop();
    cleansedTag = splitTag.join("_");
  }

  return { cleansedTag, removedSuffix };
};

/**
 * @param {import('react-i18next').TFunction} t
 * @param {{[key: string]: {[key: string]: any}}} tags
 * @param {string} tagCategory
 * @param {{[key: string]: string}} names
 * @param {{[key: string]:  React.FunctionComponent<any>}} icons
 * @param {{[key: string]: number}} orders
 * @param {{[key: string]: Object}} coverPhotos
 * @param {Set<string>=} excludedTagCategories
 * @param {any=} labels
 * @param {boolean=} isPro
 * @param {boolean=} shouldTextFallBack
 * @returns {Array<{id: string, category: string, icon:  JSX.Element, text: string, quantity: number, order: number, thumbnailUrl: string, thumbnailUrlFallback: string, label: string, room: any}>}
 */
export const getFiltersView = (
  t,
  tags,
  tagCategory,
  names,
  icons,
  orders,
  coverPhotos = {},
  excludedTagCategories = new Set(),
  labels,
  isPro,
  shouldTextFallBack
) => {
  if (excludedTagCategories.has(tagCategory)) {
    return [];
  }

  const unordered = Object.keys(tags[tagCategory])
    .map((key) => {
      const Icon =
        icons[key] ||
        getRoomsAndSpacesIcon(key) ||
        icons.default ||
        (() => null);
      const { cleansedTag } = cleanseTag(key);
      const room = tags[tagCategory][key];

      return {
        id: key,
        room,
        category: tags[tagCategory][key]?.category,
        icon: isPro ? <ProFilterIcon filterKey={key} /> : <Icon />,
        text: names[key] || (shouldTextFallBack ? key : undefined),
        quantity: tags[tagCategory][key],
        order: orders[cleansedTag],
        thumbnailUrl: coverPhotos[room.id]?.thumbnail_url,
        thumbnailUrlFallback: coverPhotos[room.id]?.thumbnail_url_fallback,
        thumbnailImageId: coverPhotos[room.id]?.id,
        label: labels ? labels[tags[tagCategory][key].id] : undefined,
      };
    })
    .filter((it) => Boolean(it.text));

  return unordered.sort(tagOrderComparator);
};

/**
 * @param {import("pages/Media/types").Tags} tags
 * @param {string} tagCategory
 * @param {Set<string>} excludedTagCategories
 * @returns {boolean}
 */
export const isTagCategoryValid = (
  tags,
  tagCategory,
  excludedTagCategories
) => {
  return (
    !excludedTagCategories.has(tagCategory) &&
    tags[tagCategory] &&
    !isEmpty(Object.keys(tags[tagCategory]))
  );
};

export const mapEditedCoverPhotoToCoverPhotoItem = (editedRoom) => {
  return editedRoom.coverPhoto
    ? {
        id: editedRoom.coverPhoto.id,
        thumbnail_url: editedRoom.coverPhoto.thumbnail_url,
        thumbnail_url_fallback: editedRoom.coverPhoto.thumbnail_url_fallback,
      }
    : undefined;
};
