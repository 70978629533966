import { useCallback, useEffect, useRef } from "react";
import {
  NavigateFunction,
  To,
  useNavigate, // eslint-disable-line no-restricted-imports
} from "react-router-dom";
import { RouteState } from "router/hooks/useRouteState";

/**
 * Navigate function changes with URL changes.
 * Let's make a stable wrapper around it.
 * https://github.com/remix-run/react-router/issues/7634.
 */
export const useStableNavigate = () => {
  const navigate = useNavigate();
  const ref = useRef<NavigateFunction>(navigate);
  const goBackRef = useRef<string>(undefined);

  useEffect(() => {
    ref.current = navigate;
  }, [navigate]);

  return useCallback(
    (
      to: To | number,
      options?: {
        replace?: boolean;
        state?: RouteState;
      }
    ) => {
      if (typeof to === "number") {
        ref.current(to);
      } else {
        if (options?.state?.goBackPage) {
          goBackRef.current = options?.state?.goBackPage;
        }
        ref.current(to, {
          ...options,
          state: {
            ...options?.state,
            goBackPage: goBackRef.current,
            previous: window.location.pathname,
          },
        });
      }
    },
    []
  );
};
