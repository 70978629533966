import { Property } from "api/models/Property";
import { mergeByKey } from "common/utils/listUtils";
import { EditedRooms } from "pages/EditRoomsAndSpaces/machine/editRoomsAndSpaces.types";
import { GuideResult } from "pages/Guides/types";
import { isConnected } from "pages/Guides/utils/utils.connections";
import { RoomsAndSpaces } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";

import { ORCHESTRATOR_MACHINE_ID } from "./constants";
import { createHIPsContext } from "./hips/HIPsContext";
import {
  OrchestratorHIPSContext,
  OrchestratorMachineContext,
  OrchestratorMediaContext,
  OrchestratorPropertyContext,
  OrchestratorStateContext,
} from "./OrchestratorMachine.types";

export const getOrchestratorMachineContext =
  (): OrchestratorMachineContext => ({
    properties: [],
    state: {
      activePropertyId: null,
      getEducated: {
        progressData: {},
        bookmarks: [],
      },
      hasSeenHipTour: false,
      hasSeenMediaHelperText: false,
      hasSeenGuideCTA: false,
      hasMyAlbumsSidebarOpened: false,
      hasFinishedOnboarding: false,
      hasSeenConfirmationWarning: {},
      isProOnboarding: false,
    },
    ephemeral: {
      sidebar: null,
      isAnyModalOpen: false,
    },
  });

export const getActivePropertyContext = (ctx: OrchestratorMachineContext) =>
  ctx.properties.find(
    (it) =>
      it.property?.id != null && it.property.id === ctx.state.activePropertyId
  ) ??
  ctx.properties[0] ??
  createPropertyContext();

export const setActivePropertyId = (
  ctx: OrchestratorMachineContext,
  propertyId: Property["id"]
) => {
  const activeProperty = ctx?.properties.find(
    (it) => it.property?.id === propertyId
  );
  if (activeProperty) {
    ctx.state = { ...ctx.state, activePropertyId: activeProperty.property.id };
  }
};

export const createPropertyContext = (): OrchestratorPropertyContext => ({
  prices: {
    labor: {
      categories: {},
    },
    items: {
      categories: {},
      types: {},
    },
  },
  property: null,
  rooms: [],
  hips: [],
  media: {
    propertyCardImage: null,
    coverPhotos: {},
    pinnedPhotos: {},
    propertyCardImagesMappingPro: {},
  },
  activeGuides: [],
  inspiration: {
    savedInspiration: [],
  },
});

export const mergeGuideResults = (
  existing: GuideResult,
  newResult: GuideResult,
  roomId: string
) => {
  const existingItems = existing?.items || [];
  const existingConnections = existing?.connections || [];

  const items = mergeByKey(
    existingItems,
    newResult.items.filter((item) => item.roomId === roomId)
  );
  const connections = [
    ...existingConnections,
    ...newResult.connections.filter(
      (connection) => !isConnected(existingConnections || [], connection)
    ),
  ];

  return {
    items,
    connections,
  };
};

export const updateRoomsWithEditedChanges = (
  rooms: RoomsAndSpaces,
  editedRooms: EditedRooms
) => {
  rooms.forEach((room) => {
    const editedRoom = editedRooms.find((eRoom) => eRoom.room.id === room.id);

    if (editedRoom) {
      room.name = editedRoom.room.name;
      room.type = editedRoom.room.type;
      room.category = editedRoom.room.category;
    }
  });
};

export const updateMediaCoverPhotos = (
  editedRooms: EditedRooms,
  media: OrchestratorMediaContext
) => {
  const imageIdsCoverPhotosMap = Object.entries(media.coverPhotos).reduce(
    (result, [roomId, coverPhoto]) => {
      result[coverPhoto.id] = roomId;
      return result;
    },
    {}
  );
  editedRooms.forEach((eRoom) => {
    const { coverPhoto, room } = eRoom;
    if (coverPhoto) {
      const prevRoomId = imageIdsCoverPhotosMap[coverPhoto.id];
      if (prevRoomId) {
        delete media.coverPhotos[prevRoomId];
      }

      media.coverPhotos[room.id] = {
        id: coverPhoto.id,
        thumbnail_url: coverPhoto.thumbnail_url,
        thumbnail_url_fallback: coverPhoto.thumbnail_url_fallback,
      };
    }
    if (eRoom.editType === "removed") {
      delete media.coverPhotos[eRoom.room.id];
    }
  });
};

/**
 * Generates the target node ID for the root machine.
 *
 * @param {string} id - The ID of the root machine target node.
 * @param {string | string[]} stateNode - The state node(s) to get the machine target node from.
 */
export const getRootMachineTargetNode = (
  id: string,
  stateNode: string | string[]
) => {
  return `#${id}${getCurrentMachineTargetNode(stateNode)}`;
};

/**
 * Returns a string representation of the nested machine target node.
 *
 * @param {string[]} stateNode - An array of strings representing the nested machine target node.
 */
const getNestedMachineTargetNode = (stateNode: string[]) => {
  return stateNode.join(".");
};

/**
 * Creates a global path according to OrchestratorMachine.
 *
 * @param stateNode Target state node
 */
export const getOrchestratorMachineTargetNode = (
  stateNode: string | string[]
) => {
  return getRootMachineTargetNode(ORCHESTRATOR_MACHINE_ID, stateNode);
};

/**
 * Creates a local path according to the current Machine scope.
 *
 * @param stateNode Target state node
 */
export const getCurrentMachineTargetNode = (stateNode: string | string[]) => {
  if (Array.isArray(stateNode)) {
    return `.${getNestedMachineTargetNode(stateNode)}`;
  }
  return `.${stateNode}`;
};

export const createRestoreOrchestratorMachineContext = (
  data: OrchestratorMachineContext,
  _stateContext?: OrchestratorStateContext
): OrchestratorMachineContext => {
  const stateContext: OrchestratorStateContext = {
    activePropertyId:
      data?.state?.activePropertyId ?? data?.properties[0]?.property?.id,
    hasSeenHipTour:
      data?.state?.hasSeenHipTour || _stateContext?.hasSeenHipTour,
    hasSeenMediaHelperText:
      data?.state?.hasSeenMediaHelperText ||
      _stateContext?.hasSeenMediaHelperText,
    hasMyAlbumsSidebarOpened:
      data?.state?.hasMyAlbumsSidebarOpened ||
      _stateContext?.hasMyAlbumsSidebarOpened,
    hasSeenGuideCTA:
      data?.state?.hasSeenGuideCTA || _stateContext?.hasSeenGuideCTA,
    hasFinishedOnboarding:
      data?.state?.hasFinishedOnboarding ||
      _stateContext?.hasFinishedOnboarding,
    hasSeenConfirmationWarning:
      data?.state?.hasSeenConfirmationWarning ||
      _stateContext?.hasSeenConfirmationWarning,
    getEducated: data?.state?.getEducated || _stateContext?.getEducated,
    isProOnboarding:
      data?.state?.isProOnboarding || _stateContext?.isProOnboarding,
  };

  const machineContext: OrchestratorMachineContext = {
    properties: data?.properties?.map((it) => {
      const propertyContext = createPropertyContext();
      propertyContext.property = {
        ...propertyContext.property,
        ...it.property,
      };

      if (it.prices) {
        propertyContext.prices = it.prices;
      }

      propertyContext.rooms = Array.isArray(it.rooms) ? it.rooms : [];

      propertyContext.hips =
        it.hips?.map((item) => {
          const newHips = createHIPsContext();
          const hipsContext: OrchestratorHIPSContext = {
            ...newHips,
            ...item,
            dashboard: {
              ...newHips.dashboard,
              ...item.dashboard,
            },
            onboarding: {
              ...newHips.onboarding,
              ...item.onboarding,
            },
            planningAreas: {
              ...newHips.planningAreas,
              ...item.planningAreas,
            },
            scopeOfWork: {
              ...newHips.scopeOfWork,
              ...item.scopeOfWork,
            },
          };
          return hipsContext;
        }) || propertyContext.hips;

      propertyContext.media = {
        ...propertyContext.media,
        ...it.media,
      };

      propertyContext.activeGuides = Array.isArray(it.activeGuides)
        ? it.activeGuides
        : [];

      propertyContext.inspiration = {
        ...propertyContext.inspiration,
        ...it.inspiration,
      };

      return propertyContext;
    }),
    state: stateContext,
  };

  return machineContext;
};
