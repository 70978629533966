import { transform } from "api/utils";
import { getKeys } from "shared/util/getKeys";

import { AppDataV16 } from "./models/AppDataV16";
import { AppDataV17 } from "./models/AppDataV17";

export function mapV16ToV17(data: AppDataV16): AppDataV17 {
  return transform(data, (result) => {
    result.state.version = 17;
    if (result.state.data.hasFinishedOnboarding === undefined) {
      result.state.data.hasFinishedOnboarding =
        getKeys(result.properties).length >= 1;
    }
  });
}
