import { presetCategoryData } from "api/static/getPresetCategoryData";
import { transform } from "api/utils";
import { find } from "lodash-es";

import { AppDataV9 } from "./models/AppDataV9";
import { AppDataV10 } from "./models/AppDataV10";

type TasksType =
  AppDataV10["state"]["data"]["properties"][0]["hips"][0]["scopeOfWork"]["guides"];

export function mapV9ToV10(data: AppDataV9): AppDataV10 {
  return transform(data, (result) => {
    result.state.version = 10;

    result.state.data.properties.forEach((property) => {
      property.hips.forEach((hips) => {
        const addedTasks: TasksType = [];
        if (hips.scopeOfWork && hips.scopeOfWork.guides) {
          hips.scopeOfWork.guides
            .filter((guide) => Boolean(guide.preset))
            .forEach((guide) => {
              const preset = find(presetCategoryData, {
                id: guide.preset.presetId,
              });
              const items = find(preset.items, {
                id: guide.preset.presetItemId,
              });
              items.tasks.forEach((presetItemTask) => {
                addedTasks.push({
                  subCategory: presetItemTask.subCategory,
                  ...guide,
                });
              });
            });
          hips.scopeOfWork.guides.push(...addedTasks);
        }
      });
    });
  });
}
