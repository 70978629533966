import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";

import { GuideMetaContext } from "./GuideMetaContext";
import {
  GuideMetaContextData,
  GuideMetaContextValue,
} from "./GuideMetaContext.types";

const initialData: GuideMetaContextData = {
  pageQuestion: "",
  pageQuestionTitle: "",
  pageProgress: 0,
  itemCategories: [],
};

export const GuideMetaContextProvider: FC<PropsWithChildren> = (props) => {
  const [data, setData] = useState<GuideMetaContextData>(initialData);

  const updateData = useCallback((newData: Partial<GuideMetaContextData>) => {
    setData((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const contextValue: GuideMetaContextValue = useMemo(
    () => [data, updateData],
    [data, updateData]
  );

  return (
    <GuideMetaContext.Provider value={contextValue}>
      {props.children}
    </GuideMetaContext.Provider>
  );
};
