export interface SomeEvent {
  stopPropagation: () => void;
  preventDefault: () => void;
}

export const cancelAllEvents = (e: SomeEvent) => {
  stopPropagation(e);
  preventDefault(e);
};

export const preventDefault = (e: SomeEvent) => {
  e?.preventDefault?.();
};

export const stopPropagation = (e: SomeEvent) => {
  e?.stopPropagation?.();
};
