import { cleanseTag } from "pages/Media/components/ImageFilters/util";

export const getTagNameFromRoomName = (roomName): string => {
  const tagName = roomName
    ?.toLowerCase()
    .replace(/[/-]/g, "")
    .replace(/\s/g, "_");
  const { cleansedTag, removedSuffix } = cleanseTag(tagName);

  return removedSuffix ? tagName : `${cleansedTag}_1`;
};
