import { transform } from "api/utils";
import { uuid } from "shared/util/uuid";

import { AppDataV3 } from "./models/AppDataV3";
import { AppDataV4 } from "./models/AppDataV4";

export function mapV3ToV4(data: AppDataV3): AppDataV4 {
  return transform(data, (result) => {
    result.state.version = 4;
    result.state.data.hips = result.state.data.hips.map((hips) => {
      if (hips.scopeOfWork) {
        hips.scopeOfWork.guides = hips.scopeOfWork.guides.map((guide) => ({
          id: uuid(),
          ...guide,
        }));
      }
      return hips;
    });
  });
}
