import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideSubType } from "constants/guides/GuideSubType.types";
import { GuideType } from "constants/guides/GuideType";
import {
  SubCategory,
  SUBCATEGORY_TAXONOMY_ORDER,
} from "constants/subcategories/subcategory.types";
import { GuideSOWRoomEventData } from "core/state/global/OrchestratorMachine/hips/Models";
import {
  OrchestratorEvent,
  OrchestratorHIPSContext,
  OrchestratorPropertyContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GUIDE_TYPES_ORDER } from "pages/Guides/consts";
import { GuideIntents } from "pages/Guides/types";
import { findBy } from "shared/util/findBy";
import { removeFromArray } from "shared/util/removeFromArray";
import { uuid } from "shared/util/uuid";

const _findGuide = (
  hips: OrchestratorHIPSContext,
  category: GuideType,
  roomId: string,
  subCategory: GuideItemTypeCategory,
  guideSubType: GuideSubType
) => {
  return hips.scopeOfWork[guideSubType].find((guide) => {
    return (
      guide.type === category &&
      guide.roomId === roomId &&
      guide.itemType === subCategory
    );
  });
};

const _addGuide = (
  ctx: OrchestratorPropertyContext,
  event: OrchestratorEvent<GuideSOWRoomEventData>,
  guideSubType: GuideSubType
) => {
  const { category, subCategory, roomId, intent, hipsId } = event.data;
  const hips = findBy(ctx.hips, "id", hipsId);
  const guide = _findGuide(hips, category, roomId, subCategory, guideSubType);

  if (guide?.preset) {
    return;
  }

  if (guide) {
    if (!guide.intents.includes(intent)) {
      guide.intents.push(intent);
    }
  } else {
    hips.scopeOfWork[guideSubType].push({
      id: uuid(),
      itemType: subCategory,
      subCategory: subCategory as unknown as SubCategory,
      type: category,
      roomId,
      intents: [intent],
    });
  }

  sortScopeOfWorkGuides(hips.scopeOfWork);
};

const sortScopeOfWorkGuides = (sow: OrchestratorHIPSContext["scopeOfWork"]) => {
  sow.guides = sow.guides.sort((a, b) => {
    const typeComparison =
      GUIDE_TYPES_ORDER[a.type] - GUIDE_TYPES_ORDER[b.type];
    if (typeComparison !== 0) {
      return typeComparison;
    }

    return (
      SUBCATEGORY_TAXONOMY_ORDER[a.subCategory] -
      SUBCATEGORY_TAXONOMY_ORDER[b.subCategory]
    );
  });
};

const findSOWGuide = (
  hips: OrchestratorHIPSContext,
  category: GuideType,
  roomId: string,
  subCategory: GuideItemTypeCategory
) => {
  return _findGuide(hips, category, roomId, subCategory, "guides");
};

export const addFakeGuide = (
  ctx: OrchestratorPropertyContext,
  event: OrchestratorEvent<GuideSOWRoomEventData>
) => {
  _addGuide(ctx, event, "fakeGuides");
};

export const addSOWGuide = (
  ctx: OrchestratorPropertyContext,
  event: OrchestratorEvent<GuideSOWRoomEventData>
) => {
  _addGuide(ctx, event, "guides");
};

export const removeSOWGuide = (
  ctx: OrchestratorPropertyContext,
  event: OrchestratorEvent<GuideSOWRoomEventData>
) => {
  const { category, subCategory, roomId, intent, hipsId } = event.data;
  const hips = findBy(ctx.hips, "id", hipsId);
  const guide = findSOWGuide(hips, category, roomId, subCategory);
  if (guide.preset) {
    return;
  }
  const intents = removeFromArray(guide.intents, intent);
  if (intents.length > 0) {
    guide.intents = intents as GuideIntents;
  } else {
    hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter(
      (item) => item.id !== guide.id
    );
  }
};
