import { isMobile } from "common/utils/isMobile";
import { wait } from "common/utils/promiseUtils";
import { isE2E } from "common/utils/testUtils";
import { IS_DEV_ENV } from "constants/env";
import { createElement, FC, useEffect } from "react";

import { Route, ROUTES } from "./routes";

interface Props {
  route: Route;
}

function findRoute(path: string) {
  for (let i = 0; i < ROUTES.length; i++) {
    for (let j = 0; j < ROUTES[i].length; j++) {
      if (ROUTES[i][j].path === path) {
        return { groupIndex: i, routeIndex: j };
      }
    }
  }
  throw new Error(`Route ${path} not found`);
}

async function preloadCurrentRouteGroup(
  groupIndex: number,
  routeIndex: number
) {
  for (let j = routeIndex + 1; j < ROUTES[groupIndex].length; j++) {
    const didLoad = await ROUTES[groupIndex][j].component.preload();
    if (didLoad) {
      await wait(150);
    }
  }
}

async function preloadNextRouteGroups(groupIndex: number) {
  for (let i = groupIndex + 1; i < ROUTES.length; i++) {
    let didLoadGroup = false;
    for (let j = 0; j < ROUTES[i].length; j++) {
      const didLoad = await ROUTES[i][j].component.preload();
      if (didLoad) {
        didLoadGroup = true;
        await wait(250);
      }
    }
    if (didLoadGroup) {
      await wait(500);
    }
  }
}

export const Preloader: FC<Props> = ({ route }) => {
  useEffect(() => {
    (async function () {
      if (
        IS_DEV_ENV ||
        isMobile ||
        isE2E() ||
        !route.live ||
        route.skipPreload
      ) {
        return;
      }
      const { groupIndex, routeIndex } = findRoute(route.path);
      await preloadCurrentRouteGroup(groupIndex, routeIndex);
      await preloadNextRouteGroups(groupIndex);
    })();
  }, [route.path, route.live, route.skipPreload]);

  return createElement(route.component);
};
