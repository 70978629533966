import type {
  TelemetryEventData,
  TelemetryEventName,
} from "api/models/Telemetry";
import { isE2E } from "common/utils/testUtils";
import {
  IS_DEV_ENV,
  IS_STAGING,
  POSTHOG_ENDPOINT,
  POSTHOG_KEY,
} from "constants/env";
import { PostHogConfig, Properties } from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const posthogConfig: Partial<PostHogConfig> = {
  api_host: POSTHOG_ENDPOINT,
  ui_host: "https://us.posthog.com",
  person_profiles: "identified_only",
  capture_pageview: false,
  capture_pageleave: true,
  respect_dnt: true,
  debug: IS_DEV_ENV,
  cross_subdomain_cookie: false,
  disable_surveys: true,
  disable_session_recording: true,
  enable_heatmaps: false,
  enable_recording_console_log: false,
  autocapture: true,
  advanced_disable_decide: false,
  advanced_disable_feature_flags: true,
  advanced_disable_feature_flags_on_first_load: true,
  advanced_disable_toolbar_metrics: true,
  secure_cookie: !IS_DEV_ENV,
};

export const TelemetryProvider: FC<PropsWithChildren> = ({ children }) => {
  if (IS_DEV_ENV || !POSTHOG_KEY || isE2E()) {
    return <>{children}</>;
  }

  return (
    <PostHogProvider apiKey={POSTHOG_KEY} options={posthogConfig}>
      {children}
    </PostHogProvider>
  );
};

export const useTrackEvent = (config?: { once: boolean }) => {
  const { once } = config || {};
  const posthog = usePostHog();
  const didTrackRef = useRef(false);

  const trackEvent = useCallback(
    (event: TelemetryEventName, extraData: TelemetryEventData = undefined) => {
      if (once && didTrackRef.current) {
        return;
      }
      if (IS_DEV_ENV || IS_STAGING) {
        // eslint-disable-next-line no-console
        console.info(`trackEvent ${event} ${JSON.stringify(extraData)}`);
      }
      posthog?.capture(event, extraData);
      didTrackRef.current = true;
    },
    [posthog, once]
  );

  return trackEvent;
};

export const useIdentifyUser = () => {
  const posthog = usePostHog();
  const didIdentifyRef = useRef(false);

  const identifyUser = useCallback(
    (userId: number, userData: Properties = undefined) => {
      if (didIdentifyRef.current) {
        return;
      }
      if (IS_DEV_ENV || IS_STAGING) {
        // eslint-disable-next-line no-console
        console.info(`identify ${userId} ${JSON.stringify(userData)}`);
      }
      posthog?.identify(userId.toString(), userData);
      didIdentifyRef.current = true;
    },
    [posthog]
  );

  return identifyUser;
};

export const useNavigationTelemetry = () => {
  const posthog = usePostHog();

  const location = useLocation();

  useEffect(() => {
    if (location && posthog) {
      const currentUrl = window.origin + location.pathname + location.search;
      posthog.capture("$pageview", {
        $current_url: currentUrl,
      });
    }
  }, [posthog, location]);
};

export const useTrackPageLoad = (
  event: TelemetryEventName,
  extraData?: TelemetryEventData
) => {
  const trackEvent = useTrackEvent({ once: true });
  useEffect(() => {
    trackEvent(event, extraData);
  }, [trackEvent, event, extraData]);
};
