import { Button, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { SvgIconComponent } from "shared/types/SvgIcon";

import styles from "./SidebarSuccess.module.scss";

interface Props {
  Icon: SvgIconComponent;
  title: string;
  description: ReactNode;
  onClose: () => void;
}

const DTI = "SidebarSuccess";

export const SidebarSuccess: FC<Props> = ({
  description,
  Icon,
  onClose,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.overlay}>
      <div className={styles.placeholder} />
      <div className={styles.content}>
        <div className={styles.iconWrapper}>
          <Icon className={styles.icon} />
        </div>
        <Typography
          variant="h3"
          component="h3"
          className={styles.title}
          data-testid={`${DTI}__Title`}
        >
          {title}
        </Typography>
        <Typography
          variant="p"
          component="p"
          className={styles.description}
          data-testid={`${DTI}__Description`}
        >
          {description}
        </Typography>
        <Button
          variant="outlined"
          disableRipple
          className={styles.button}
          onClick={onClose}
          data-testid={`${DTI}__CloseButton`}
        >
          <Typography variant="p" component="p">
            {t("Close")}
          </Typography>
        </Button>
      </div>
    </div>
  );
};
