import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import {
  SharedCollectionByMeValue,
  SharedCollectionInactiveValue,
  SharedCollectionWithMeValue,
} from "pages/Media/constants";
import type { Address } from "pages/PropertyAddition/types";
import { RouteState } from "router/hooks/useRouteState";
import {
  DashboardRouteState,
  GetEducatedGuideParams,
  GetEducatedGuidePostParams,
  GetEducatedGuideRouteState,
  GetEducatedQueryParams,
  HIPsRouteParams,
  MediaAlbumsParams,
  MediaInspirationCategoryParams,
  MediaInspirationCategoryResultsParams,
  PropertyClaimRejectedRouteParams,
} from "router/models";
import {
  ACCOUNT_SETTINGS,
  BASE_ROUTE,
  BUSINESS_DASHBOARD,
  BUSINESS_SIGN_UP,
  DASHBOARD,
  DASHBOARD_NO_PROPERTY,
  GET_EDUCATED,
  GET_EDUCATED_GUIDE,
  GET_EDUCATED_GUIDE_POST,
  GET_EDUCATED_MY_STUFF,
  GET_EDUCATED_POST,
  GET_INSPIRED,
  GET_INSPIRED_CATEGORY,
  GET_INSPIRED_RESULTS,
  HELP_CENTER,
  HIPS_DASHBOARD,
  HIPS_PROJECT_BUDGET,
  HOME,
  LOGIN,
  MEDIA,
  MEDIA_ALBUMS,
  MEDIA_SHARED_ALBUMS,
  NOT_AVAILABLE_MOBILE,
  PRO_MARKETING,
  PROPERTY_ADDITION,
  PROPERTY_ADDITION_ADDRESS,
  PROPERTY_ADDITION_ADDRESS_DETAILS,
  PROPERTY_ADDITION_CONFIRMATION,
  PROPERTY_ADDITION_REJECTED,
  PROPERTY_ADDITION_ROOM_IMAGES,
  PROPERTY_ADDITION_ROOMS_AND_SPACES,
  RESET_PASSWORD,
  SIGN_UP,
  VERIFY_EMAIL_ERROR,
} from "router/routes";

import { getActivePropertyContext } from "./actions.utils";
import type {
  OrchestratorEvent,
  OrchestratorMachineContext,
} from "./OrchestratorMachine.types";

const navigateAndReload = (url: string) => {
  // NOTE(clemens): forcing a full refresh ensures that any data we have in
  //  memory is cleared out and won't persist to the next user's login
  location.href = url;
};

export const getOrchestratorGoto = ({
  navigate,
}: {
  navigate: ReturnType<typeof useStableNavigate>;
}) => {
  const goto = {
    goBack: () => {
      navigate(-1);
    },
    about: () => {
      navigate(NOT_AVAILABLE_MOBILE());
    },
    home: () => {
      navigate(HOME());
    },
    proMarketing: () => {
      navigate(PRO_MARKETING());
    },
    businessDashboard: () => {
      navigate(BUSINESS_DASHBOARD());
    },
    businessSignUp: () => {
      navigate(BUSINESS_SIGN_UP());
    },
    dashboard: (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<{ state: DashboardRouteState }>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const propertyId = ctx.property?.id;
      const dashboardState: RouteState = event.data?.state
        ? { dashboard: event.data.state }
        : undefined;

      if (!propertyId) {
        navigate(DASHBOARD_NO_PROPERTY());
        return;
      }
      navigate(DASHBOARD({ propertyId }), { state: dashboardState });
    },
    propertyAddition: () => {
      navigate(PROPERTY_ADDITION());
    },
    propertyAdditionAddProperty: () => {
      navigate(PROPERTY_ADDITION());
    },
    propertyAdditionAddressSelect: (
      _ctx,
      event: OrchestratorEvent<Address>
    ) => {
      navigate(PROPERTY_ADDITION_ADDRESS(event.data));
    },
    propertyAdditionAddressDetails: (
      _ctx,
      event: OrchestratorEvent<Address>
    ) => {
      navigate(PROPERTY_ADDITION_ADDRESS_DETAILS(event.data));
    },
    propertyAdditionAddressConfirmed: () => {
      navigate(PROPERTY_ADDITION_CONFIRMATION());
    },
    propertyAdditionAddressRejected: (
      _ctx,
      event: OrchestratorEvent<PropertyClaimRejectedRouteParams>
    ) => {
      navigate(PROPERTY_ADDITION_REJECTED(event.data));
    },
    propertyAdditionRoomsAndSpaces: () => {
      navigate(PROPERTY_ADDITION_ROOMS_AND_SPACES());
    },
    propertyAdditionRoomImages: () => {
      navigate(PROPERTY_ADDITION_ROOM_IMAGES());
    },
    root: () => {
      navigate(BASE_ROUTE());
    },
    rootAfterLogout: () => {
      navigateAndReload(BASE_ROUTE());
    },
    login: () => {
      navigate(LOGIN());
    },
    loginAfterLogout: () => {
      navigateAndReload(LOGIN());
    },
    loginAfterPasswordReset: () => {
      navigate(LOGIN({ passwordReset: true }));
    },
    loginAfterPasswordResetError: () => {
      navigate(LOGIN({ passwordResetError: true }));
    },
    verifyError: () => {
      navigate(VERIFY_EMAIL_ERROR());
    },
    accountSettings: () => {
      navigate(ACCOUNT_SETTINGS());
    },
    helpCenter: () => {
      navigate(HELP_CENTER());
    },
    signUp: () => {
      navigate(SIGN_UP());
    },
    resetPassword: () => {
      navigate(RESET_PASSWORD());
    },
    getInspired: () => {
      navigate(GET_INSPIRED());
    },
    getInspiredCategory: (
      _,
      event: OrchestratorEvent<MediaInspirationCategoryParams>
    ) => {
      navigate(GET_INSPIRED_CATEGORY(event.data));
    },
    getInspiredCategoryResults: (
      _,
      event: OrchestratorEvent<MediaInspirationCategoryResultsParams>
    ) => {
      navigate(GET_INSPIRED_RESULTS(event.data));
    },
    getEducatedProjectGuideInspiration: (
      _,
      event: OrchestratorEvent<MediaInspirationCategoryResultsParams>
    ) => {
      navigate(GET_INSPIRED_RESULTS(event.data));
    },
    getEducated: (
      _,
      event: OrchestratorEvent<GetEducatedQueryParams | undefined>
    ) => {
      navigate(GET_EDUCATED(event.data));
    },
    getEducatedProjectGuide: (
      _,
      event: OrchestratorEvent<
        GetEducatedGuideParams & GetEducatedGuideRouteState
      >
    ) => {
      navigate(GET_EDUCATED_GUIDE(event.data), {
        state: {
          scrollTop: event.data.scrollTop,
          getEducated: {
            scrollToBlogSection: event.data.scrollToBlogSection,
            scrollToBlogPost: event.data.scrollToBlogPost,
            scrollToBlogChapter: event.data.scrollToBlogChapter,
            scrollSmooth: event.data.scrollSmooth,
          },
        },
      });
    },
    getEducatedProjectGuidePost: (
      _,
      event: OrchestratorEvent<
        GetEducatedGuidePostParams & GetEducatedGuideRouteState
      >
    ) => {
      if (!event.data.guideId) {
        navigate(GET_EDUCATED_POST(event.data));
      } else {
        navigate(GET_EDUCATED_GUIDE_POST(event.data), {
          state: {
            scrollTop: event.data.scrollTop,
            getEducated: {
              scrollToBlogSection: event.data.scrollToBlogSection,
              scrollToBlogPost: event.data.scrollToBlogPost,
              scrollToBlogChapter: event.data.scrollToBlogChapter,
              scrollSmooth: event.data.scrollSmooth,
            },
          },
        });
      }
    },
    getEducatedMyStuff: () => {
      navigate(GET_EDUCATED_MY_STUFF());
    },
    media: (_ctx: OrchestratorMachineContext) => {
      const ctx = getActivePropertyContext(_ctx);
      const propertyId = ctx.property.id;
      navigate(MEDIA({ propertyId }));
    },
    mediaAlbums: (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<MediaAlbumsParams>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const propertyId = ctx.property.id;
      navigate(MEDIA_ALBUMS({ propertyId, albumId: event?.data?.albumId }));
    },
    mediaSharedWithMe: (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<MediaAlbumsParams>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const propertyId = ctx.property.id;
      navigate(
        MEDIA_SHARED_ALBUMS({
          propertyId,
          shared: SharedCollectionWithMeValue,
          albumId: event?.data?.albumId,
        })
      );
    },
    mediaSharedByMe: (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<MediaAlbumsParams>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const propertyId = ctx.property.id;
      navigate(
        MEDIA_SHARED_ALBUMS({
          propertyId,
          shared: SharedCollectionByMeValue,
          albumId: event?.data?.albumId,
        })
      );
    },
    mediaSharedInactive: (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<MediaAlbumsParams>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const propertyId = ctx.property.id;
      navigate(
        MEDIA_SHARED_ALBUMS({
          propertyId,
          shared: SharedCollectionInactiveValue,
          albumId: event?.data?.albumId,
        })
      );
    },
    hipDashboard: (_, event: OrchestratorEvent<HIPsRouteParams>) => {
      navigate(HIPS_DASHBOARD(event.data));
    },
    hipBudget: (_, event: OrchestratorEvent<HIPsRouteParams>) => {
      navigate(HIPS_PROJECT_BUDGET(event.data));
    },
  };

  return { goto };
};
