import { getOrderedMap } from "common/utils/listUtils";
import { ArrayElement } from "common/utils/typeUtils";
import { GuideTFunction } from "pages/Guides/types";
import { TFunction } from "react-i18next";

export const ROOMS_PIECES_LIVING = {
  BEDROOM: "bedroom",
  BATHROOM: "bathroom",
  HALF_BATH: "half_bath",
  LIVING_ROOM: "living_room",
  DINING_ROOM: "dining_room",
  KITCHEN: "kitchen",
  FAMILY_ROOM: "family_room",
  DEN: "den",
  NURSERY: "nursery",
  LOFT: "loft",
} as const;

export const ROOMS_PIECES_WORK = {
  OFFICE: "office",
  LIBRARY: "library",
  PLAY_ROOM: "play_room",
  GAME_ROOM: "game_room",
  HOME_THEATER: "home_theater",
  GYM: "gym",
  SUN_ROOM: "sun_room",
  MUSIC_ROOM: "music_room",
} as const;

export const ROOMS_PIECES_UTILITY = {
  WALK_IN_CLOSET: "walk_in_closet",
  LAUNDRY: "laundry",
  HVAC_CLOSET: "hvac_closet",
  WALKIN_PANTRY: "walkin_pantry",
  WINE_CELLAR: "wine_cellar",
  ATTIC: "attic",
  BASEMENT: "basement",
  STORAGE_ROOM: "storage_room",
  UTILITY_ROOM: "utility_room",
} as const;

export const ROOMS_PIECES_TRANSITIONAL = {
  ENTRANCE: "entrance",
  FOYER: "foyer",
  HALLWAY: "hallway",
  STAIRCASE: "staircase",
  GARAGE: "garage",
} as const;

export const ROOMS_PIECES_EXTERIOR = {
  FRONT_PORCH: "front_porch",
  CRAWL_SPACE: "crawl_space",
  DRIVEWAY: "driveway",
  WALKWAY: "walkway",
  SCREENED_PORCH: "screened_porch",
  DECK: "deck",
  FRONT_GARDEN: "front_garden",
  SIDE_GARDEN: "side_garden",
  REAR_GARDEN: "rear_garden",
  OUTDOOR_KITCHEN: "outdoor_kitchen",
  POOL: "pool",
  ROOF: "roof",
} as const;

export const ROOMS_PIECES = {
  ...ROOMS_PIECES_LIVING,
  ...ROOMS_PIECES_WORK,
  ...ROOMS_PIECES_UTILITY,
  ...ROOMS_PIECES_TRANSITIONAL,
  ...ROOMS_PIECES_EXTERIOR,
} as const;

export const ROOMS_PIECES_NAMES = (
  t: TFunction | GuideTFunction
): Record<RoomType, string> => ({
  [ROOMS_PIECES.BEDROOM]: t("Bedroom"),
  [ROOMS_PIECES.BATHROOM]: t("Bathroom"),
  [ROOMS_PIECES.HALF_BATH]: t("Half Bath"),
  [ROOMS_PIECES.LIVING_ROOM]: t("Living Room"),
  [ROOMS_PIECES.DINING_ROOM]: t("Dining Room"),
  [ROOMS_PIECES.KITCHEN]: t("Kitchen"),
  [ROOMS_PIECES.FAMILY_ROOM]: t("Family Room"),
  [ROOMS_PIECES.DEN]: t("Den"),
  [ROOMS_PIECES.NURSERY]: t("Nursery"),
  [ROOMS_PIECES.LOFT]: t("Loft"),
  [ROOMS_PIECES.OFFICE]: t("Office"),
  [ROOMS_PIECES.LIBRARY]: t("Library"),
  [ROOMS_PIECES.PLAY_ROOM]: t("Play Room"),
  [ROOMS_PIECES.GAME_ROOM]: t("Game Room"),
  [ROOMS_PIECES.HOME_THEATER]: t("Home Theater"),
  [ROOMS_PIECES.GYM]: t("Gym"),
  [ROOMS_PIECES.SUN_ROOM]: t("Sun Room"),
  [ROOMS_PIECES.MUSIC_ROOM]: t("Music Room"),
  [ROOMS_PIECES.WALK_IN_CLOSET]: t("Walk in Closet"),
  [ROOMS_PIECES.LAUNDRY]: t("Laundry"),
  [ROOMS_PIECES.HVAC_CLOSET]: t("HVAC Closet"),
  [ROOMS_PIECES.WALKIN_PANTRY]: t("Walk-in Pantry"),
  [ROOMS_PIECES.WINE_CELLAR]: t("Wine Cellar"),
  [ROOMS_PIECES.ATTIC]: t("Attic"),
  [ROOMS_PIECES.BASEMENT]: t("Basement"),
  [ROOMS_PIECES.STORAGE_ROOM]: t("Storage Room"),
  [ROOMS_PIECES.UTILITY_ROOM]: t("Utility Room"),
  [ROOMS_PIECES.FRONT_PORCH]: t("Front Porch"),
  [ROOMS_PIECES.ENTRANCE]: t("Entrance"),
  [ROOMS_PIECES.FOYER]: t("Foyer"),
  [ROOMS_PIECES.HALLWAY]: t("Hallway"),
  [ROOMS_PIECES.STAIRCASE]: t("Staircase"),
  [ROOMS_PIECES.GARAGE]: t("Garage"),
  [ROOMS_PIECES.DRIVEWAY]: t("Driveway"),
  [ROOMS_PIECES.WALKWAY]: t("Walkway"),
  [ROOMS_PIECES.SCREENED_PORCH]: t("Screened Porch"),
  [ROOMS_PIECES.DECK]: t("Deck"),
  [ROOMS_PIECES.FRONT_GARDEN]: t("Front Garden"),
  [ROOMS_PIECES.SIDE_GARDEN]: t("Side Garden"),
  [ROOMS_PIECES.REAR_GARDEN]: t("Rear Garden"),
  [ROOMS_PIECES.OUTDOOR_KITCHEN]: t("Outdoor Kitchen"),
  [ROOMS_PIECES.POOL]: t("Pool"),
  [ROOMS_PIECES.ROOF]: t("Roof"),
  [ROOMS_PIECES.CRAWL_SPACE]: t("Crawl Space"),
});

export const ROOMS_PIECES_VALUES = Object.values(ROOMS_PIECES);

export type RoomType = ArrayElement<typeof ROOMS_PIECES_VALUES>;

export const ROOMS_PIECES_ORDERS = getOrderedMap(Object.values(ROOMS_PIECES));
