import { TypographyStyleOptions } from "@mui/material/styles/createTypography";
import breakpoints from "styles/_jsbreakpoints.module.scss";

export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl";

export const getBreakpoint = (size: ScreenSize) => {
  return parseInt(breakpoints[`${size}Width`]);
};

const getMediaQueryString = (
  from: ScreenSize,
  to: Exclude<ScreenSize, "xs">
) => {
  return `@media only screen and (min-width: ${getBreakpoint(
    from
  )}px) and (max-width: ${getBreakpoint(to) - 1}px)`;
};

const media =
  (from: ScreenSize, to: Exclude<ScreenSize, "xs">) =>
  (styles: TypographyStyleOptions) => {
    return {
      [getMediaQueryString(from, to)]: styles,
    };
  };

export const getXsScreenMediaStyle = media("xs", "sm");
export const getSmScreenMediaStyle = media("sm", "md");
export const getMdScreenMediaStyle = media("md", "lg");
