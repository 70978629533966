import { ReactComponent as BugIcon } from "assets/icons/bug.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { SidebarLoading } from "components/shared/SidebarForm/SidebarLoading/SidebarLoading";
import { SidebarSuccess } from "components/shared/SidebarForm/SidebarSuccess/SidebarSuccess";
import { SidebarBaseForm } from "components/SidebarForms/components/SidebarBaseForm/SidebarBaseForm";
import { SidebarContactUsInputs } from "components/SidebarForms/components/SidebarContactUsInputs/SidebarContactUsInputs";
import { useContactUs } from "components/SidebarForms/hooks/useContactUs";
import { useContactUsForm } from "components/SidebarForms/hooks/useContactUsForm";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SidebarBugReport: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { mutate, isPending, isSuccess, reset } = useContactUs("bug");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useContactUsForm("bug", mutate);
  useEffect(() => {
    if (isOpen) {
      resetForm();
      reset();
    }
  }, [isOpen, reset, resetForm]);

  return (
    <SidebarBaseForm
      Icon={BugIcon}
      title={t("Found a Bug?")}
      description={t(
        "Your report will help us keep \n our code as clean as a whistle."
      )}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      inputs={
        <SidebarContactUsInputs
          values={values}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onBlur={handleBlur}
          onCancel={onClose}
          onSubmit={handleSubmit}
          messageLabel={t("Bug Description")}
          messagePlaceholder={t("Please describe the issue")}
        />
      }
    >
      {isPending && <SidebarLoading />}
      {isSuccess && (
        <SidebarSuccess
          title={t("Bug Reported!")}
          description={t(
            "Thanks for helping us improve this platform – our team is on the case."
          )}
          onClose={onClose}
          Icon={CheckIcon}
        />
      )}
    </SidebarBaseForm>
  );
};
