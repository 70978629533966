import { smartTimeout } from "common/utils/testUtils";
import {
  HBSnackbar,
  SNACKBAR_TRANSITION_DURATION,
} from "components/Snackbars/HBSnackbar/HBSnackbar";
import { FC, useCallback } from "react";

import styles from "./SystemSnackbar.module.scss";

export type SystemSnackbarProps = {
  id: string;
  onClose?: (id: string) => void;
};

type Props = {
  title: string;
} & SystemSnackbarProps;

export const SystemSnackbar: FC<Props> = ({ title, id, onClose }) => {
  const handleClose = useCallback(() => {
    smartTimeout(() => {
      onClose(id);
    }, SNACKBAR_TRANSITION_DURATION);
  }, [onClose, id]);
  return (
    <HBSnackbar
      open
      message={title}
      showIcon={false}
      rootClassName={styles.snackbarWrapper}
      messageClassName={styles.titleText}
      containerClassName={styles.messageContainer}
      autoHideDuration={3000}
      onClose={onClose ? handleClose : undefined}
    />
  );
};
