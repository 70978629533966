import { ERROR_CODES } from "constants/validation";
import * as Yup from "yup";

interface ErrorMessage {
  max?: string;
  min?: string;
  number?: string;
  required?: string;
  symbol?: string;
  email?: string;
  upperCase?: string;
}

interface ErrorMessages {
  fullName?: ErrorMessage;
  displayName?: ErrorMessage;
  email?: ErrorMessage;
  password?: ErrorMessage;
}

export const getUserValidationSchema = (errorMessages?: ErrorMessages) => {
  return {
    fullName: Yup.string()
      .max(300, errorMessages?.fullName?.max ?? ERROR_CODES.MAX)
      .min(1, errorMessages?.fullName?.min ?? ERROR_CODES.MIN)
      .matches(
        /^([^0-9]*)$/i,
        errorMessages?.fullName?.number ?? ERROR_CODES.FULLNAME.NUMBER
      )
      .required(errorMessages?.fullName?.required ?? ERROR_CODES.REQUIRED),
    displayName: Yup.string()
      .max(300, errorMessages?.displayName?.max ?? ERROR_CODES.MAX)
      .required(errorMessages?.displayName?.required ?? ERROR_CODES.REQUIRED),
    email: Yup.string()
      .max(254, errorMessages?.email?.max ?? ERROR_CODES.MAX)
      .email(errorMessages?.email?.email ?? ERROR_CODES.EMAIL)
      .required(errorMessages?.email?.required ?? ERROR_CODES.REQUIRED),
    password: Yup.string()
      .min(8, errorMessages?.password?.min ?? ERROR_CODES.MIN)
      .max(128, errorMessages?.password?.max ?? ERROR_CODES.MAX)
      .matches(
        /^(?=.*[!@#$%^&*])/,
        errorMessages?.password?.symbol ?? ERROR_CODES.SYMBOL
      )
      .matches(
        /^(?=.*[A-Z])/,
        errorMessages?.password?.upperCase ?? ERROR_CODES.UPPERCASE
      )
      .required(errorMessages?.password?.required ?? ERROR_CODES.REQUIRED),
  };
};
