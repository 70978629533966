import { useAccountDetails } from "api/hooks/useAccountDetails";
import { ContactUs } from "api/models/ContactUs";
import { isLoggedIn } from "common/utils/isLoggedIn";
import { getContactUsValidationSchema } from "components/SidebarForms/validations/ContactUsValidation";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

export const useContactUsForm = (
  type: ContactUs["type"],
  onSubmit: (data: Omit<ContactUs, "type">) => void
) => {
  const { t } = useTranslation();
  const validationSchema = getContactUsValidationSchema(t, type);
  const { data: user } = useAccountDetails(isLoggedIn());
  return useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.display_name || "",
      email: user?.email || "",
      message: "",
    },
    onSubmit,
    validationSchema,
  });
};
