import { DAYS } from "constants/time";
import {
  GuideItemPrice,
  GuideLaborPrice,
} from "pages/Guides/budget/budget.types";

const STALE_AFTER = 7 * DAYS;

export const isPriceStale = (price?: GuideItemPrice | GuideLaborPrice) => {
  if (!price) {
    return true;
  }

  if (!price.updatedAt) {
    return true;
  }

  const now = new Date().getTime();
  const then = new Date(price.updatedAt).getTime();

  return now - then >= STALE_AFTER;
};
