import { transform } from "api/utils";

import { AppDataV14 } from "./models/AppDataV14";
import { AppDataV15 } from "./models/AppDataV15";

export function mapV14ToV15(data: AppDataV14): AppDataV15 {
  return transform(data, (result) => {
    result.state.version = 15;
    result.state.data = data.state.data.state;
    result.properties = Object.fromEntries(
      data.state.data.properties.map((it) => {
        const key = it.property.id;
        const value = { version: 1, data: it };
        return [key, value];
      })
    );
  });
}
